
import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col} from 'antd';

import border from '../../images/border.png';

import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';

import { Link } from 'react-router-dom';
import Liens from './Liens';

export default class Niveaux extends Component {

    constructor() {
        super();
    
    }

    render() {
        return <div>
            <Helmet>
                <title>Tests de niveaux anglais gratuits en ligne</title>
                <meta name="description" content="Des tests et des exercices en ligne pour déterminer son niveau d'anglais (A1, A2, B1...) complétement gratuits et sans inscription." />
            </Helmet>

            <h1 className="titre centre">Tests de niveaux d'anglais gratuits</h1>

            <p>Les niveaux d'anglais sont généralement classés selon le Cadre européen commun de référence pour les langues (CECRL), un système utilisé pour évaluer et comparer les compétences linguistiques dans différentes langues. Les niveaux d'anglais selon le CECRL vont de A1 à C2, représentant différentes étapes de maîtrise de la langue. Voici une brève explication de chaque niveau :</p>
            <ol>
                <li><b>Niveau A1 (Débutant)</b>: À ce niveau, l'apprenant est capable de comprendre et d'utiliser des expressions familières et simples dans des situations de base, comme se présenter, saluer, poser des questions simples et répondre à des questions sur des sujets personnels.</li>
                <li><b>Niveau A2 (Élémentaire)</b>: À ce stade, l'apprenant peut comprendre et communiquer dans des situations courantes et familières. Ils peuvent échanger des informations sur des sujets familiers tels que la famille, les loisirs, le travail, etc. Cependant, la communication reste limitée et nécessite un vocabulaire et une grammaire de base.</li>
                <li><b>Niveau B1 (Pré-intermédiaire)</b>: À ce niveau, l'apprenant peut comprendre et s'exprimer dans des situations courantes, même si cela nécessite encore un effort. Ils peuvent participer à des conversations simples, exprimer des opinions, parler du passé et formuler des projets futurs. La compréhension écrite et orale s'améliore, mais avec des sujets concrets et familiers.</li>
                <li><b>Niveau B2 (Intermédiaire):</b> À ce stade, l'apprenant est capable de comprendre et de communiquer de manière plus autonome. Ils peuvent participer à des discussions plus complexes, comprendre des textes plus longs et variés, ainsi que s'exprimer avec un vocabulaire plus large. Ils sont également capables de faire des présentations et d'écrire des textes plus détaillés.</li>
                <li><b>Niveau C1 (Avancé):</b> À ce niveau, l'apprenant possède une maîtrise avancée de la langue. Ils peuvent comprendre des textes complexes, participer activement à des débats, exprimer des opinions argumentées et utiliser la langue avec précision et fluidité. La communication est presque naturelle, bien que certaines erreurs puissent encore se produire.</li>
                <li><b>Niveau C2 (Maîtrise)</b>: C'est le niveau le plus élevé de compétence linguistique. L'apprenant est capable de comprendre et de s'exprimer de manière fluide et précise dans toutes les situations, tant à l'oral qu'à l'écrit. Ils peuvent lire et comprendre des textes difficiles, utiliser un large éventail de vocabulaire et s'adapter à différents styles et registres de la langue.</li>
            </ol>
         
            <h2>Niveaux d'anglais et scolarité</h2>
            <p>Voici une correspondance approximative entre les niveaux du CECRL et les classes scolaires en France :</p>
            <ul>
                <li>Niveau A1 : Correspond généralement aux premières années d'apprentissage de l'anglais au collège (6e et 5e).</li>
                <li>Niveau A2 : Correspond généralement aux années intermédiaires du collège (4e et 3e).</li>
                <li>Niveau B1 : Correspond généralement à la première année du lycée (seconde).</li>
                <li>Niveau B2 : Correspond généralement aux deuxième et troisième années du lycée (première et terminale).</li>
                <li>Niveau C1 et C2 : Correspondent généralement à des niveaux plus avancés que ceux enseignés dans les programmes scolaires. Ils sont souvent atteints par des étudiants qui poursuivent des études supérieures en langues étrangères ou qui ont une exposition prolongée à l'anglais.</li>
            </ul>
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <h2>Nos test de niveaux d'anglais</h2>
            <p>Les tests proviennent de ChatGPT et on a été revu un par un à la main pour corriger les erreurs. Ce ne sont pas des tests offficiels, le résultat du test ne peut être qu'une indication du niveau d'anglais. Ce sont surtout des tests destinés à se divertir de façon intelligente en révisant les bases de l'anglais.</p>
           <h3>Les tests de niveau A1</h3>
            <Row>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/1'><div className="choixNiveau">
                <div className="titreNiveau">A1</div>
                <p className="moyenneFont">QCM</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/3'><div className="choixNiveau">
                <div className="titreNiveau">A1</div>
                <p className="moyenneFont">Culture générale</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/4'><div className="choixNiveau">
                <div className="titreNiveau">A1</div>
                <p className="moyenneFont">Compréhension écrite</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/5'><div className="choixNiveau">
                <div className="titreNiveau">A1</div>
                <p className="moyenneFont">Compréhension orale</p>

                </div></Link>
                </Col>
            </Row>
            <h3>Les tests de niveau A2</h3>
            <Row>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/2'><div className="choixNiveau">
                <div className="titreNiveau">A2</div>
                <p className="moyenneFont">QCM</p>
                </div></Link>
                </Col><Col md={8} xs={24}>
                <Link to='/test-niveau/9'><div className="choixNiveau">
                <div className="titreNiveau">A2</div>
                <p className="moyenneFont">Culture générale</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/7'><div className="choixNiveau">
                <div className="titreNiveau">A2</div>
                <p className="moyenneFont">Compréhension écrite</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/8'><div className="choixNiveau">
                <div className="titreNiveau">A2</div>
                <p className="moyenneFont">Compréhension orale</p>

                </div></Link>
                </Col>
            </Row>
            <h3>Les tests de niveau B1</h3>
            <Row>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/11'><div className="choixNiveau">
                <div className="titreNiveau">B1</div>
                <p className="moyenneFont">QCM</p>
                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/15'><div className="choixNiveau">
                <div className="titreNiveau">B1</div>
                <p className="moyenneFont">QCM Vocabulaire</p>
                </div></Link>
                </Col>

                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/12'><div className="choixNiveau">
                <div className="titreNiveau">B1</div>
                <p className="moyenneFont">Compréhension écrite</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/13'><div className="choixNiveau">
                <div className="titreNiveau">B1</div>
                <p className="moyenneFont">Compréhension orale</p>

                </div></Link>
                </Col>
            </Row>
            <h3>Les tests de niveau B2</h3>
            <Row>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/14'><div className="choixNiveau">
                <div className="titreNiveau">B2</div>
                <p className="moyenneFont">QCM</p>
                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau/16'><div className="choixNiveau">
                <div className="titreNiveau">B2</div>
                <p className="moyenneFont">QCM Vocabulaire</p>
                </div></Link>
                </Col>

                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/17'><div className="choixNiveau">
                <div className="titreNiveau">B2</div>
                <p className="moyenneFont">Compréhension écrite</p>

                </div></Link>
                </Col>
                <Col md={8} xs={24}>
                <Link to='/test-niveau-texte/18'><div className="choixNiveau">
                <div className="titreNiveau">B2</div>
                <p className="moyenneFont">Compréhension orale</p>

                </div></Link>
                </Col>
            </Row>

          
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
        </div>
    }
}