import React, { Component } from 'react';

import border from '../../images/border.png';
import fond1 from '../../images/fond1.png';
import fond2 from '../../images/fond2.png';
import fond3 from '../../images/fond3.png';
import fond4 from '../../images/fond4.png';
import twitter from '../../images/twitter.png';
import student from '../../images/englishStudent.webp';
import {Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import {Button} from 'antd';
import Ad from '../commun/AdSense.js';



import Liens from './Liens.js';



 class Evaluation extends Component {

  constructor()
  {
    super();
   

  }


  reset = () => {
    localStorage.clear();
    document.location.reload();
}
  render() {
    return <div className="margeHaut">
        <Helmet>
          <title>Tests de niveaux comme evalang gratuits et en ligne</title>
          <meta name="description" content="Envie de connaître votre niveau d'anglais ? Nos tests s'inspirant d'Evalang, le test officiel de l'Éducation nationale, vous permettent d'évaluer rapidement vos compétences en anglais gratuitement !"/>
                 </Helmet>
              
      <h1 className="titre centre">Évaluations d'anglais Éducation nationale</h1>
      <div className='centre'><small >evalquiz.com : numéro 1 du divertissement intelligent</small></div>
     <div className="centre"><img src={border} alt="bordure"></img></div>
   <div className="texteBord">
<p>Evalang est un test de positionnement en anglais conçu par l'Éducation nationale, destiné à évaluer rapidement et efficacement le niveau de compétence linguistique des élèves. Adapté aux niveaux du CECRL (Cadre européen commun de référence pour les langues), ce test numérique et interactif permet d'évaluer plusieurs compétences : compréhension orale, compréhension écrite, et grammaire.</p>
 <p>Les élèves passent le test evalang en 3e au collège, généralement à partir de mars. Le résultat du test ne compte ni pour le brevet, ni pour le bulletin ou l'orientation. L'attribution du diplôme national du brevet nécessite normalement l'acquisition du niveau A2.  </p>

 <p>Les questions présentées dans notre qcm ne sont pas celles du test mais elles sont tirées d'un document sur le net présentant quelques exemples de questions du test. Le test est beaucoup plus long, il dure 30 minutes. Tout le monde peut passer le test, même les adultes, il faut payer environ 15 € pour le passer dans un centre de formation.</p>
 <p>Nos tests n'ont aucune valeur, ils sont conçus pour divertir et s'entrainer à pratiquer l'anglais.</p>
   </div>   <div className='plateauCategorie'>
      <Link to='/test-evaluation/0'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Évaluation anglais A1</div>
    <div className='imageCategorie'><img src={fond1} alt="évaluation anglais A1"></img></div>
        </div>
        </Link>


        <Link to='/test-evaluation/1'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Évaluation anglais A2</div>
    <div className='imageCategorie'><img src={fond2} alt="évaluation anglais A2"></img></div>
        </div>
        </Link>
        
        <Link to='/test-evaluation/2'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Évaluation anglais B1</div>
    <div className='imageCategorie'><img src={fond3} alt="évaluation anglais B1"></img></div>
        </div>
        </Link>
        <Link to='/niveaux'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Autres tests de niveaux</div>
    <div className='imageCategorie'><img src={fond4} alt="tests niveaux anglais"></img></div>
        </div>
        </Link>

</div>

<div className="centre"><img src={border} alt="bordure"></img></div>
<div className="centre"><img className="img-responsive" src={student} alt="student" width={1000} height={402}></img></div>
<Liens></Liens>
<div className="texteBord">


<p>Vous avez des exercices à proposer ou des idées d'amélioration de notre site ? Vous pouvez directement prendre contact avec nous sur <a href="https://twitter.com/evalquiz"><img className="margeGauche" src={twitter} alt="twitter logo"></img> </a>. Nous sommes toujours heureux d'avoir des retours.</p>
<p>Vous pouvez aussi nous contacter si vous avez des remarques ou des propositions en consultant la page des <a href="https://evalquiz.com/home/faq">mentions légales.</a></p>

<Ad></Ad>
<div className="centre"><img src={border} alt="bordure"></img></div>
</div>
    </div> 
  }
}

export default withRouter(Evaluation);