let dataMemoire =
 [
        // {
        //     id: 1,
        //     titre: "Phrasal verbs",
        //     niveau: 5,
        //     temps: 100,
        //     info: {
        //         mots: [
        //             {
        //                 couleur: 'vert',
        //                 texte: 'to deal with : traiter',
        //                 groupe : true
        //             },
        //             {
        //                 couleur: 'vert',
        //                 texte: "He doesn't know how to deal with the situation.",
        //                 groupe : true
        //             },{
        //                 couleur: 'bleu',
        //                 texte: 'to cope with : faire face à',
        //                 groupe : false
        //             },
        //             {
        //                 couleur: 'bleu',
        //                 texte: "How nations cope with crisis?",
        //                 groupe : true
        //             },
        //             {
        //                 couleur: 'orange',
        //                 texte: 'to manage : réussir',
        //                 groupe : false
        //             },
        //             {
        //                 couleur: 'orange',
        //                 texte: "The pilot managed to land the plane. ",
        //                 groupe : true
        //             }

        //         ],
        //         questions: [
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'Translate : to deal with',
        //                 reponse: ['traiter']
        //             },
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'What is the last word of the sentence with the verb "to deal with"?',
        //                 reponse: ['situation']
        //             },
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'Translate :  to manage',
        //                 reponse: ['réussir']
        //             },
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'Who managed to land the plane? (write the complete sentence)',
        //                 reponse: ['the pilot managed to land the plane.']
        //             },
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'Translate : faire face à',
        //                 reponse: ['to cope with']
        //             },
        //             {
        //                 typeQuestion: "ouverte",
        //                 question: 'How many words are in the sentence with "cope with"?',
        //                 reponse: ['5']
        //             }
        //         ]
        //     },
            {
                id: 1,
                titre: "Compréhension texte avec comparaisons",
                niveau: 3,
                info: {
                    texte: "Alice Jones works in St. Louis, Missouri, as a sales representative for a big publishing company. She has a job offer from a smaller publishing company in New York City to become the marketing director for college textbooks. She can't decide if she should take the job or not.<br> In a smaller company, there may be more chances for advancement and promotion because more people get to know you. Her company now is so big that she doesn't know anyone outside her department. But the company in New York doesn't pay as well as the company in St. Louis.<br>  The move from St. Louis to New York is also a big change. New York is an exciting place to live in, but it is expensive and dangerous, especially for a single woman. (By Judy DeFilippo (Northeastern University) and Daphne Macke)" , 
                    questions: [
                        {
                            typeQuestion: "ferme",
                            question: 'The company in New York is bigger than the company in St. Louis.',
                            reponse: false
                        },
                        {
                            typeQuestion: "ferme",
                            question: 'The job in New York pays less than the job in St. Louis.',
                            reponse: true
                        },
                        {
                            typeQuestion: "ferme",
                            question: 'New York is a safe city.',
                            reponse: false
                        }
                    ]
                }
        },
        {
            id: 2,
            titre: "Compréhension des prépositions (on, into, below...)",
            niveau: 3,
            info: {
                image: "imageMaison.jpg",
                questions: [
                    {
                        typeQuestion: "ferme",
                        question: "The bike is on the floor in the basement.",
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The stairs are beside the bathroom on the second floor.",
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The woman is putting something into the fridge.",
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The kitchen is on the first floor in the house.",
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The computer is in the room below the attic.",
                        reponse: true
                    }
                ]
            }
    },
    {
        id: 3,
        titre: "Compréhension d'un texte à l'oral",
        niveau: 3,
        info: {
           son: "memoire1.mp3",
           questions: [
            {
                typeQuestion: "ferme",
                question: "Peach is the name of Jill’s friend.",
                reponse: false
            },
            {
                typeQuestion: "ferme",
                question: "Jill was 50 last Thursday.",
                reponse: true
            },
            {
                typeQuestion: "ferme",
                question: "Jill called a taxi at half past three.",
                reponse: true
            },
            {
                typeQuestion: "ferme",
                question: "Jill and her friend talked about their days at work together.",
                reponse: false
            }
        ]
    }
},


    ]


export default dataMemoire;