import { readGames, readListErrors } from '../components/commun/localStorage';
import dataVocabulaire from './dataVocabulaire'
import dataConjugaison from './dataConjugaison';
import dataEcoute from './dataEcoute';
import dataListeMots from './dataListeMots';
import dataMemoire from './dataMemoire';
import dataMotParle from './dataMotParle';
import dataOral from './dataOral';
import dataPhraseTrou from './DataPhraseTrou';
import dataPhrase from './dataPhrase';
import dataRapide from './dataRapide';
import dataQcm from './dataQcm';
import dataJeuxConcours from './dataJeuxConcours';
import dataConcours from './dataConcours'

export default class DataAccess {

    constructor(type, idTest) {

        this.id = idTest;
        this.concours = false;
        if (idTest != null && idTest > 10000) {
            type = 'concoursAnglais';
            this.concours = true;
        }
        this.data = DataAccess.donneesSuivantType(type);
        if (idTest=== 9999) //liste erreurs
        {
            this.jeu = readListErrors(type);
        }
        else
        {   
             this.jeu = this.data.find(x => x.id === this.id);
        }
       
    }


    static donneesSuivantType(type) {
        let donnees = null;
        switch (type) {
            case 'test-conjugaison-anglais':
                donnees = dataConjugaison;
                break;
            case 'test-oral-anglais':
                donnees = dataEcoute;
                break;
            case 'test-mots-anglais':
                donnees = dataListeMots;
                break;
            case 'test-memoire-anglais':
                donnees = dataMemoire;
                ;
                break;
            case 'test-mot-parle-anglais':
                donnees = dataMotParle;
                break;
            case 'test-discussion-anglais':
                donnees = dataOral;
                break;
            case 'test-qcm-anglais':
                donnees = dataQcm;
                    break;
            case 'vocabulaire-francais':
            case 'test-vocabulaire-anglais/vocabulaire/francais':
                
                donnees = DataAccess.modifierTitreVocabulaire("francais");
                break;
            case 'vocabulaire-anglais':
            case 'test-vocabulaire-anglais/vocabulaire/anglais':
                    donnees = DataAccess.modifierTitreVocabulaire("anglais");
                    break;
       
            case 'test-vocabulaire-anglais/phrase/francais':
                donnees = DataAccess.modifierTitrePhrase("francais");
                break;
            case 'test-vocabulaire-anglais/phrase/anglais':
                donnees = DataAccess.modifierTitrePhrase("anglais");
                break;

            case 'test-rapide-anglais':
                donnees = dataRapide;
                break;
                case 'phrase-trou':
                    donnees = dataPhraseTrou;
                    break;
            case 'concoursAnglais':
                donnees = dataJeuxConcours;
                break;
            case 'defi-anglais':
                    donnees = dataConcours;
                    break;
            default:
                break;

        }
        return donnees;
    }

    static modifierTitreVocabulaire(langue)
    {
        return dataVocabulaire.map(exo => {return  {...exo, titre : langue === "francais" ? exo.titreF: exo.titreA} });
    }

    static modifierTitrePhrase(langue)
    {
        return dataPhrase.map(exo => {return  {...exo, titre : langue === "francais" ? exo.titreF: exo.titreA} });
    }


    static transformeNiveau(niveau) {
        switch (niveau) {
            case 1: return 'très facile';
            case 2: return 'facile';
            case 3: return 'moyen';
            case 4: return 'difficile';
            case 5: return 'très difficile';
            default: return 'facile';
        }
    }

    obtenirDonneesJeu() {
        return this.jeu
    }

    obtenirDonneesJeuMelange() {

        if (this.jeu == null) return;
        let nouvelOrdre = { ...this.jeu };
        const nbElement = this.jeu.info.length;
        for (let index = 0; index < nbElement; index++) {

            let nbHasard = Math.floor(Math.random() * (this.jeu.info.length));
            nouvelOrdre.info.push(this.jeu.info[nbHasard]);
            this.jeu.info.splice(nbHasard, 1);
        }
        return nouvelOrdre;
    }



    obtenirProchainJeu(nomJeu) {
        const dejaFaitMemoire = readGames(nomJeu);
        let pos = this.data.findIndex(x => x.id === this.id);
        for (let index = pos+1; index < this.data.length; index++) {
            const element = this.data[index];
            let jeu = dejaFaitMemoire.find(x => x.no === element.id);
            if (jeu == null) {
                return element;
            }

        }
        return null;
    }


}