import React, { Component } from 'react';
import { Menu} from 'antd';
import { HomeOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default class MenuHaut extends Component {
    render() {
        return <div>

            <Menu
                mode="horizontal"
                theme='light'
                style={{ backgroundColor: "#afe4e2" }}
            >
                <Menu.Item key="home">
                    <a href="/"><HomeOutlined style={{ marginLeft: '7px', fontSize: '25px' }}/></a>
                </Menu.Item>
              <Menu.SubMenu title="L'anglais" key="l'anglais">
              <Menu.Item key="niveaux">
          <a href="/niveaux">
           Tests niveaux
          </a>
          </Menu.Item>
                {/* <Menu.Item key="jours">
          <a href="/exercice">
           Révision
          </a>
          </Menu.Item> */}
          <Menu.Item key="verbes-irreguliers">
          <a href="/verbes-irreguliers">Verbes irréguliers</a>
         
          </Menu.Item>
          <Menu.Item key="alphabet">
          <a href="/alphabet">Lettres et nombres</a>
         
          </Menu.Item>
          
           <Menu.Item key="test-vocabulaire">
          <a href="/test-vocabulaire">Vocabulaire</a>
         
          </Menu.Item> <Menu.Item key="test-grammaire">
          <a href="/test-grammaire">Grammaire</a>
    
          </Menu.Item> <Menu.Item key="test-ecoute">
          <a href="/test-ecoute">Écouter</a>
          
          </Menu.Item> <Menu.Item key="test-parler">
            <a href="/test-parler">Parler</a>
          </Menu.Item>
          <Menu.Item key="lesson">
          <a href="/blog/fauxamis">
           Les leçons
          </a>
 
        </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item>
          <a href="https://evalquiz.com">
          evalquiz
          </a>
            </Menu.Item>
    
         <Menu.SubMenu title="Autres domaines" key="autreTests">
                <Menu.Item key="orthographe">
        <a href="https://orthographe.evalquiz.com/">
          Orthographe
          </a>
        </Menu.Item>
        <Menu.Item key="culture">
          <a href="https://evalquiz.com/tests/culture">
           Culture
          </a>
        </Menu.Item>
        <Menu.Item key="challenge">
          <a href="https://concours.evalquiz.com">
           Challenge
          </a>
        </Menu.Item>
        <Menu.Item key="psycho">
          <a href="https://evalquiz.com/tests/psycho">
           Psycho
          </a>
        </Menu.Item>
        <Menu.Item key="quizRoyal">
          <a href="https://quiz-royal.evalquiz.com">
           QuizRoyal
          </a>
        </Menu.Item>
        <Menu.Item key="math">
          <a href="https://evalquiz.com/tests/math">
           Calcul
          </a>
        </Menu.Item>
        <Menu.Item key="logique">
          <a href="https://evalquiz.com/tests/logique">
           QI
          </a>
        </Menu.Item>      
        <Menu.Item key="cerebral">
          <a href="https://cerebral.evalquiz.com">
           Entrainement cérébral
          </a>
        </Menu.Item> 
        </Menu.SubMenu>
            </Menu>
            {this.props.children}
        </div>
    }
}

