import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Card, Row, Col, Progress } from 'antd';
import { readVocaValide, readGames } from '../../components/commun/localStorage';
import DataAccess from '../../data/DataAccess';

import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
  } from '@ant-design/icons';

const NBNIVEAUX= 5
export default class SousMenuVoca extends Component {

    constructor(props) {
        super();  
        this.nom = props.nom;
        let donnees = DataAccess.donneesSuivantType(this.nom);
        this.listeValide = []; 
       console.log(this.nom);
        if (props.type === 'phrase' || props.type =='vocabulaire')
        {
          
          this.listeValide = readVocaValide(props.type, props.langue);
        }
        else
        {
          this.listeValide  = readGames(this.nom);   
        }
        this.mapValide = new Map();
        for (let index = 0; index < donnees.length; index++) {
          this.mapValide.set(donnees[index].id,this.listeValide.find(x => x.id === donnees[index].id));
          
        }
        this.state = { donnees : donnees.sort((a, b) => a.niveau - b.niveau),
          listeNiveaux : []

         }

    }

    componentDidMount()
    {
      let listeNiveaux = [];
      for (let index = 1; index <= NBNIVEAUX; index++) {
        
        let lv = this.state.donnees.filter(x => x.niveau === index); 
        let resultats = {total : 0, reussi : 0, pourcent : 0}
        if (lv != null)
        {
          for (let index = 0; index < lv.length; index++) {
            let jeu = this.mapValide.get(lv[index].id);
            if (jeu != null && jeu.etat === 'valide')
            {
              resultats.reussi++;
            }
            resultats.total++;
          }
        }
        resultats.pourcent = Math.ceil((resultats.reussi * 100)/resultats.total);
        listeNiveaux.push(resultats);
      }
      this.setState({listeNiveaux})
  
    }

    ajouterTag(id) {
     
     
            let jeu = this.mapValide.get(id);
            let msg = '';
            let color = '';
            if (jeu !== undefined) {
                if (jeu.etat === 'valide') {
                 
                    return <Tag icon={<CheckCircleOutlined />} color="success">
                    Validé
                  </Tag>
                }
                else {
                   return <Tag icon={<CloseCircleOutlined />} color="error">
        À refaire
      </Tag>
                }
            }
            else {
             return   <Tag icon={<ClockCircleOutlined />} color="default">
                En attente
              </Tag>
            }
           
        
    }



render() {

    return (<div> <Card className={this.props.className} title={<span style={{ whiteSpace: 'normal' }}>{this.props.titre}</span>}>

        {this.state.donnees.map((v, i) => <Row gutter={{ md: 16, xs:8 }} key={i}><Col md={6} xs={12}>{this.props.supprimer !== v.id ?  <Link to={'/' + this.nom + '/' + v.id}>{v.titre}</Link>:
        <b><Link to={'/' + this.nom + '/' + v.id}>{v.titre}</Link></b>}</Col>
            {v.hasOwnProperty('niveau') && v.niveau !== undefined && <Col md={3} xs={4} className="petiteLettre">{DataAccess.transformeNiveau(v.niveau)}</Col>}
            <Col md={3} xs={2} className="margeGauche">{this.ajouterTag(v.id)}</Col></Row>)}

    </Card>
    <h2 className="centre">Ton tableau de bord</h2>
    <p className="centre">Pourcentage de tests réussis pour chaque catégorie</p>
    <div className="plateauCategorie">{this.state.listeNiveaux.map((n, i) => n.total !== 0 && <div key={i+10000} className='espaceDroiteGauche'> <Progress
                    type="circle"
                    percent={n.pourcent}
                    trailColor="rgba(0, 0, 0, 0.06)"
                    strokeColor="#ff8d25"
                    strokeWidth={20}
                />
                <div className="centre">{DataAccess.transformeNiveau(i+1)}</div>
                {n.pourcent === 100 &&<div className="centre">Super ! Terminé 👍</div>}
                </div>)}
    </div>
    </div>)

}
}

SousMenuVoca.defaultProps = {
    supprimer: 'rien',
};



