
import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col} from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';

import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import Liens from './Liens';



export default class ParlerTest extends Component {

  constructor()
  {
    super();
   
  }

    render() {
        return <div>
          <Helmet>
            <title>Tests pour parler et s'exprimer en anglais</title>
          <meta name="description" content="Des tests permettant de s'exprimer directement en anglais avec un micro. Des petits jeux faciles et très enrichissants pour améliorer sa prononciation en anglais."/>
       </Helmet>

<div className="centre"><img src={border} alt="bordure"></img></div>
<h1 className="titre centre">Parler l'anglais</h1>
<Row  gutter={{md:16}} className="margeHaut">

<Col md={12}>
  <h2>Parler en anglais</h2>
  <p>Ce test vous entraîne à la prononciation en anglais. Vous devez répéter une phrase en anglais que vous venez d'entendre.
    Pour vous aider vous pouvez afficher la transcription du texte audio. Ce test utilise la reconnaissance vocale du service "cognitive speech" de Microsoft.
    Ce service reconnait automatiquement la ponctuation. Malheureusement la transcription est un service payant et il n'existe pas de service gratuit chez Amazon ou Google. Il n'y a donc pas beaucoup d'exemples.  
  </p>

</Col>
<Col md={12}>
  <SousMenu nom={env.nomOral}  titre="Parler en anglais"></SousMenu>
</Col>
</Row>

<Row  gutter={{md:16}} className="margeHaut">

<Col md={12}>
  <h2>Tester sa prononciation</h2>
  <p>Dans ce test vous devez prononcer des mots en anglais. </p>

</Col>
<Col md={12}>
  <SousMenu nom={env.nomMotParle} titre="Prononcer des mots en anglais"></SousMenu>
</Col>
</Row>
<div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
        </div>
    }
}