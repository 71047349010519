let donneesJeuxConcours =
    [
      
        {
            id: 10300,
            titre: "Les sentiments",
            niveau: 4,
            idConcours: 300,
            qualifFaute: 2,
            info: [
                {
                    a: "infatuated",
                    f: "épris",
                      indicF: "sous le charme"
                },
                {
                    a: "bliss",
                    f: "félicité",
                    indicF: "bonheur"
                },
                {
                    a: "sorrow",
                    f: "chagrin"
                },
                {
                    a: "pleasant",
                    f: "agréable"
                },
                {
                    a: "to enjoy",
                    f: "apprécier",
                },
                {
                    a: "eager",
                    f: "impatient"
                },
                {
                    a: "to please",
                    f: "plaire"
                },
                {
                    a: "delighted",
                    f: "ravi"
                },
                {
                    a: "affair",
                    f: "liaison",
                    indicF: "amoureuse"
                },
                {
                    a: "to cheer up",
                    f: "réconforter"
                }
            ]
        },
        {
            id: 10301,
            titre: "La douloureuse relation amoureuse",
            niveau : 3,
            idConcours: 300,
            qualifFaute: 2,
            info: [
                {
                    a: "she broke my heart.",
                    f: "elle m'a brisé le coeur."
                },
                {
                    a: "she left me for another girl.",
                    f: "elle m'a quitté pour une autre fille."
                },
                {
                    a: "I found out she cheated on me.",
                    f: "j'ai découvert qu'elle m'avait trompé."
                },
                {
                    a: "she broke up with me by text.",
                    f: "elle a rompu avec moi par texto."
                },
                {
                    a: "why am I so afraid to date again?",
                    f: "pourquoi ai-je si peur de sortir à nouveau avec quelqu'un ?"
                }
            ]
        },
        {
            id: 10302,
            titre: "Le mariage et les sentiments",
            niveau: 2,
            temps: 150,
            type: "mot",
            idConcours: 300,
            qualifFaute: 0,
            info: [
                {
                    audio: "rapide150.mp3",
                    a: "bride",
                },
                {
                    audio: "rapide151.mp3",
                    a: "groom",
                },
                {
                    audio: "rapide152.mp3",
                    a: "delight",
                },
                {
                    audio: "rapide153.mp3",
                    a: "sorrow",
                },
                {
                    audio: "rapide154.mp3",
                    a: "gloomy",
                }
            ]
        },
        {
            id: 10303,
            titre: "I made my excuses and left",
            niveau: 3,
            idConcours: 300,
            qualifFaute: 1,
            info: [
                {
                    audio: "oral40.mp3",
                    texte: "I walked into the room.",
                    
                },
                {
                    audio: "oral41.mp3",
                    texte: "Imagine my surprise.",
                    
                },
                {
                    audio: "oral42.mp3",
                    texte: "You were sitting close to him.",
                },
                {
                    audio: "oral43.mp3",
                    texte: "I made my excuses and left.",   
                }
            ]
        },


        {
            id: 10290,
            titre: "Les sciences",
            idConcours: 290,
            qualifFaute: 2,
            niveau: 3,
            info: [
                {
                    a: "to find out",
                    f: "découvrir" 
                },
                {
                    a: "a patent",
                    f: "un brevet"
                },
                {
                    a: "innovative",
                    f: "innovant",
                },
                {
                    a: "efficient",
                    f: "efficace"
                },
                {
                    a: "hazardous",
                    f: "dangereux"
                },
                {
                    a: "to carry out",
                    f: "réaliser",
                    indicF: "effectuer"
                },
                {
                    a: "electrical outlet",
                    f: "prise électrique"
                },
                {
                    a: "to switch on",
                    f: "allumer"
                },
                {
                    a: "to update",
                    f: "mettre à jour"
                },
                {
                    a: "an engineer",
                    f: "un ingénieur"
                }
            ]
        },
        {

            id: 10291,
            titre: "Expressions courantes",
            niveau: 2,
            idConcours: 290,
            qualifFaute: 2,
            info: [
                {
                    a: "he's very annoying.",
                    f: "il est très agaçant."
                },
                {
                    a: "I have a headache.",
                    f: "j'ai mal à la tête."
                },
                {
                    a: "that sounds good to me.",
                    f: "cela me semble bien.",
                    indicF: "When someone says something and you think you can agree to that"
                },
                {
                    a: "see you later",
                    f: "à bientôt"
                },
                {
                    a: "it doesn't matter.",
                    f: "ça n'a pas d’importance."
                },
                {
                    a: "of course not",
                    f: "bien sûr que non"
                },
                {
                    a: "not bad",
                    f: "pas mal",

                },
                {
                    a: "sorry to keep you waiting.",
                    f: "désolé de vous avoir fait attendre.",

                },


            ]
        },
        {
            id: 10292,
            titre : "Pottery is For Girls",
            idConcours: 290,
            qualifFaute: 1,
            texte :   "Sharon Carter wanted to take a pottery class. She didn't want to go @alone . She invited her husband to go with her. 'I don't want to do that!' said Mr. Carter. 'Pottery is for girls!' This made Sharon very @angry . She could not believe he said that. 'That's a sexist thing to say!' @yelled Sharon. She @argued with him for hours. Mr. Carter was tired of fighting. He agreed to go just to shut Sharon up. Their first class went very well. Mr. Carter was really good at making @pots . The instructors loved him. 'You are very talented!' they all said. Mr. Carter went to pottery class every week after that. He @loved it!",
            audio: "anglaisTrou7.mp3"   
        },
        {

            id: 10293,
            titre: "Expressions courantes",
            niveau: 2,
            idConcours: 290,
            qualifFaute: 2,
            info: [
                {
                    a: "I hope to see you soon",
                    f: "j’espère te voir bientôt"
                },
                {
                    a: "I wish you all the best",
                    f: "je vous souhaite pleins de bonnes choses"
                },
                {
                    a: "I apologise for not replying any sooner.",
                    f: "je m'excuse de ne pas avoir répondu plus tôt.",
                    indicF: "British English spelling"
                },
                {
                    a: "I beg your pardon",
                    f: "je vous prie de m'excuser"
                },
                {
                    a: "what a pity!",
                    f: "quel dommage !"
                },
                {
                    a: "it's up to you",
                    f: "c'est à vous de voir"
                },
                {
                    a: "make yourself at home",
                    f: "fais comme chez toi",

                },
                {
                    a: "never mind",
                    f: "peu importe",

                },


            ]
        },




        {
            id: 10280,
            titre: "Dans l'entreprise",
            niveau: 3,
            idConcours: 280,
            qualifFaute: 2,
            info: [
                {
                    a: "tremendous",
                    f: "énorme",
                    indicF: "formidable"

                },
                {
                    a: "achievement",
                    f: "réalisation",
                    indicF: "succès"
                },
                {
                    a: "commitment",
                    f: "engagement",
                },
                {
                    a: "shipment",
                    f: "expédition",
                    indicF: "envoi"
                },
                {
                    a: "outstanding",
                    f: "excellent"
                },
                {
                    a: "awesome",
                    f: "fantastique",
                },
                {
                    a: "ownership",
                    f: "propriété"
                },
                {
                    a: "to involve",
                    f: "impliquer",
                    indicF: "concerner"
                },
                {
                    a: "to plan",
                    f: "prévoir"
                },
                {
                    a: "to provide",
                    f: "fournir"
                }
            ]
        }, 
        {
            id: 10281,
            titre: "Test intermédiaire",
            idConcours: 280,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: " I'm not stupid! @Neither am I@.",
                    reponses: ["Neither am I", "Neither I am", "So do I"]
                },
                {
                    phrase: "I would like to have coffee. @So would I@.",
                    reponses: ["So wouldn't I", "So would I", "So I would"]
                },
                {
                    phrase: " I can see them! @So can I@.",
                    reponses: ["So cannot I", "So can I", "So I can"]
                },
                {
                    phrase: " I didn't talk to her. @Neither did I@.",
                    reponses: ["Neither did I", "Neither I did", "Neither I didn't"]
                },
                {
                    phrase: "Mary and I play tennis every day but @neither of us@ can play well.",
                    reponses: ["neither of us", "either of us", "none of us" ]
                },
                {
                    phrase: "I don´t like cats. Mary @doesn't either@.",
                    reponses: ["does neither", "doesn't either", "doesn't neither" ]
                },
               
            ]
        },
        {
            id: 10282,
            titre: "Dans l'entreprise",
            niveau: 3,
            temps: 180,
            type: "mot",
            idConcours: 280,
            qualifFaute: 1,
            info: [
                {
                    audio: "rapide71.mp3",
                    a: "tremendous",
                },
                {
                    audio: "rapide72.mp3",
                    a: "achievement",
                },
                {
                    audio: "rapide73.mp3",
                    a: "commitment",
                },
                {
                    audio: "rapide74.mp3",
                    a: "statement",
                },
                {
                    audio: "rapide75.mp3",
                    a: "shipment",
                }
            ]
        },
        {
            id: 10283,
            titre: "Leçon de vocabulaire",
            niveau: 1,
            idConcours: 280,
            qualifFaute: 3,
            info: [
                {
                    a: "I don't understand him either.",
                    f: "je ne le comprends pas non plus."
                },
                {
                    a: "I can find neither the dog nor the cat.",
                    f: "je ne peux trouver ni le chien, ni le chat."
                },
                {
                    a: "either dictionary will give you the answer.",
                    f: "l'un ou l'autre des dictionnaires vous donnera la réponse."
                },
                {
                    a: "I like neither of them.",
                    f: "je n'aime ni l'un ni l'autre."
                },
                {
                    a: "he can neither walk nor run.",
                    f: "il ne peut ni marcher ni courir."
                }
            ]
        }, 

        {

            id: 10270,
            titre: "Vocabulaire difficile",
            niveau: 4,
            idConcours: 270,
            qualifFaute: 2,
            info: [
                {
                    a: "to give in",
                    f: "céder"
                },
                {
                    a: "to count on",
                    f: "compter sur"
                },
                {
                    a: "to call off",
                    f: "annuler",
                },
                {
                    a: "to give away",
                    f: "donner",
                    indicF: "offrir"
                },
                {
                    a: "to get rid of",
                    f: "se débarrasser de"
                },
                {
                    a: "to get out",
                    f: "sortir",
                },
                {
                    a: "to watch out",
                    f: "être vigilant",
                },
                {
                    a: "to piss off",
                    f: "dégager ",
                    indicF : "énerver, faire c..."
                },
            ]
        },
        {
            id: 10271,
            titre: "Leçon de vocabulaire",
            niveau: 1,
            idConcours: 270,
            qualifFaute: 3,
            info: [
                {
                    a: "I'm counting on you to make dinner.",
                    f: "je compte sur toi pour faire le dîner."
                },
                {
                    a: "I'm not intending to give in to her.",
                    f: "je n'ai pas l'intention de lui céder.",
                    indicF: "lui est une femme"
                },
                {
                    a: "the match has been called off because of the icy weather.",
                    f: "le match a été annulé en raison du temps glacial."
                },
                {
                    a: "we have 100 books to give away to our readers.",
                    f: "nous avons 100 livres à offrir à nos lecteurs."
                },
                {
                    a: "I can't wait to get rid of that ugly couch.",
                    f: "J'ai hâte de me débarrasser de cet affreux canapé."
                }
            ]
        },
        {
            id: 10272,
            titre: "Test intermédiaire",
            idConcours: 270,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "You have to watch @out@ when driving at night.",
                    reponses: ["off", "on", "in", "out"]
                },
                {
                    phrase: "That guy really pisses me @off@!",
                    reponses: ["on", "off", "down"]
                },
                {
                    phrase: "My wife will get @out@ of jail.",
                    reponses: ["in", "out", "off"]
                },
                {
                    phrase: "How do you cope @with@ seven kids?",
                    reponses: ["with", "in", "out", "on"]
                },
                {
                    phrase: "why should she mess @up@ my life?",
                    reponses: ["in", "down", "up", "out" ]
                }             
            ]
        },
        {
            id: 10273,
            titre: "L'entretien d'embauche",
            niveau: 3,
            idConcours: 270,
            qualifFaute: 2,
            info: [
                {
                    audio: "audio61.mp3",
                    a: "why were you fired?",
                    f: "pourquoi avez-vous été licencié ?"
                },
                {
                    audio: "audio62.mp3",
                    a: "why are you leaving your current job?",
                    f: "pourquoi quittez-vous votre emploi actuel ?"
                },
                {
                    audio: "audio63.mp3",
                    a: "what are your greatest strengths?",
                    f: "quels sont vos plus grands atouts ?"
                },
                {
                    audio: "audio64.mp3",
                    a: "what do you consider to be your weaknesses?",
                    f: "que considérez-vous comme vos faiblesses ?"
                },
                {
                    audio: "audio65.mp3",
                    a: "where do you see yourself in 5 years?",
                    f: "où vous voyez-vous dans 5 ans ?"
                }
            ]
        },

        {

            id: 10260,
            titre: "Vocabulaire difficile",
            niveau: 4,
            idConcours: 260,
            qualifFaute: 2,
            info: [
                {
                    a: "to come across",
                    f: "rencontrer",
                    indicF: "tomber sur",
                },
                {
                    a: "to drop off",
                    f: "déposer"
                },
                {
                    a: "to catch up",
                    f: "rattraper",
                },
                {
                    a: "to put off",
                    f: "reporter"
                },
                {
                    a: "to calm down",
                    f: "se calmer"
                },
                {
                    a: "to blow up",
                    f: "exploser",
                },
                {
                    a: "to come up with",
                    f: "trouver",
                    indicF: "fournir"
                },
                {
                    a: "to look forward to",
                    f: "attendre avec impatience",
                    indicF: "avoir hâte de faire qqchose"
                },


            ]
        },
        {
            id: 10261,
            titre: "Leçon de vocabulaire",
            niveau: 1,
            idConcours: 260,
            qualifFaute: 3,
            info: [
                {
                    a: "the house was blown up by a bomb.",
                    f: "la maison a été détruite par une bombe."
                },
                {
                    a: "I came across an old friend during my travel.",
                    f: "je suis tombé sur un vieil ami pendant mon voyage."
                },
                {
                    a: "they came up with a great idea.",
                    f: "ils ont eu une super idée."
                },
                {
                    a: "we look forward to working with him.",
                    f: "nous sommes impatients de travailler avec lui."
                },
                {
                    a: "I ran to catch up with my wife.",
                    f: "j'ai couru pour rattraper ma femme."
                }
            ]
        },
        {
            id: 10262,
            titre: "Test intermédiaire",
            idConcours: 260,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "I can’t see, turn the lights @on@.",
                    reponses: ["off", "on", "in"]
                },
                {
                    phrase: "People usually turn the heat @down@ at night.",
                    reponses: ["up", "on", "down"]
                },
                {
                    phrase: "I prefer to work @out@ after my breakfast.",
                    reponses: ["in", "out", "off"]
                },
                {
                    phrase: "You can hold @on@ one more day.",
                    reponses: ["off", "in", "out", "on"]
                },
                {
                    phrase: "The boat will stop to drop @off@ passengers.",
                    reponses: ["in", "on", "off", "out" ]
                }
        
               
            ]
        },
        {
            id: 10263,
            titre: "Des mots à retrouver 3",
            niveau: 2,
            temps: 180,
            idConcours: 260,
            qualifFaute: 1,
            liste: [
                {
                    definition : "Red fluid inside the body",
                    mot: "blood",
                    
                },
                {
                    definition: "Containing nothing",
                    mot: "empty",
                },
                {
                    definition: "It comes out of the tap",
                    mot: "water",
                    
                },
                {
                    definition : "It is worn around the waist",
                    mot: "belt",
                    
                },
                {
                    definition: "Permitted to do",
                    mot: "allowed",
                },
                {
                    definition: "It is worn around the wrist",
                    mot: "bracelet",
                    
                }
            ]
        },

        {
            id: 10250,
            titre: "Les sentiments tristes",
            idConcours: 250,
            qualifFaute: 2,
            info: [
                {
                    a: "grief",
                    f: "chagrin",
                    indicF: "deuil"
                },
                {
                    a: "staggering",
                    f: "stupéfiant",
                },
                {
                    a: "to upset",
                    f: "bouleverser"
                },
                {
                    a: "stubborn",
                    f: "têtu",
                    indicF: "obstiné"
                },
                {
                    a: "fear",
                    f: "crainte",
                },
                {
                    a: "disappointment",
                    f: "déception"
                },
                {
                    a: "scorn",
                    f: "mépris"
                },
                {
                    a: "to hate",
                    f: "détester"
                },
                {
                    a: "dreadful",
                    f: "terrible",
                    indicF: "horrible"
                },
                {
                    a: "ashamed",
                    f: "honteux"
                }
            ]
        },
        {

            id: 10251,
            titre: "Petites expressions courantes",
            niveau: 2,
            idConcours: 250,
            qualifFaute: 2,
            info: [
                {
                    a: "what a shame",
                    f: "quel dommage"
                },
                {
                    a: "I'll give you a lift.",
                    f: "Je vais te déposer."
                },
                {
                    a: "don't worry",
                    f: "ne t'en fais pas"
                },
                {
                    a: "you must be kidding.",
                    f: "tu dois plaisanter."
                },
                {
                    a: "you're welcome",
                    f: "de rien"
                },
                {
                    a: "cheers",
                    f: "à votre santé"
                },
                {
                    a: "you deserved it",
                    f: "tu l'as mérité"

                },
                {
                    a: "from time to time",
                    f: "de temps en temps",

                },
            ]
        },
        {
            id: 10252,
            titre: "Conjugaison intermédiaire",
            idConcours: 250,
            qualifFaute: 2,
            info: [
                {
                    temps: "Pluperfect",
                    sujet: "he",
                    verbe: "to conclude",
                    forme: "affirmative",
                    reponsec: "he'd concluded",
                    reponse: "he had concluded"
                },
                {
                    temps: "Pluperfect",
                    sujet: "we",
                    verbe: "to buy",
                    forme: "negative",
                    reponse: "we hadn't bought"
                },
                {
                    temps: "Pluperfect",
                    sujet: "he",
                    verbe: "to giggle",
                    forme: "interrogative",
                    reponse: "had he giggled"
                },
                {
                    temps: "Conditional Present",
                    sujet: "he",
                    verbe: "to fight",
                    forme: "affirmative",
                    reponsec: "he'd fight",
                    reponse: "he would fight"
                },
                {
                    temps: "Conditional Present",
                    sujet: "you",
                    verbe: "to buy",
                    forme: "interrogative",
                    reponse: "would you buy"
                },
            ]
        },
        {

            id: 10253,
            titre: "Petites expressions courantes",
            niveau: 2,
            idConcours: 250,
            qualifFaute: 2,
            info: [
                {
                    a: "leave me alone",
                    f: "laisse-moi tranquille"
                },
                {
                    a: "it's bullshit.",
                    f: "c'est des conneries."
                },
                {
                    a: "I don't care.",
                    f: "je m'en moque."
                },
                {
                    a: "I don't want to bother you.",
                    f: "je ne veux pas te déranger."
                },
                {
                    a: "that's not fair",
                    f: "ce n'est pas juste"
                },
                {
                    a: "by the way",
                    f: "au fait"
                },
                {
                    a: "I'm coming to pick you up.",
                    f: "je viens te chercher."

                },
                {
                    a: "how's work going?",
                    f: "comment va le travail ?",

                },


            ]
        },

        {
            id: 10240,
            titre :"Les religions",
            idConcours: 240,
            qualifFaute: 2,
            niveau: 4,
            info: [
                {
                    a: "a Jew",
                    f: "un juif"
                },
                {
                    a: "a Christian",
                    f: "un chrétien"
                },
                {
                    a: "a Muslim",
                    f: "un musulman",
                },
                {
                    a: "a prayer",
                    f: "une prière",
                },
                {
                    a: "a council",
                    f: "un conseil",
                    indicF: "un groupe de personnes"
                },
                {
                    a: "secularism",
                    f: "laïcité",
                },
                {
                    a: "an atheist",
                    f: "un athée"
                },
                {
                    a: "a church",
                    f: "une église"
                },
                {
                    a: "the faith",
                    f: "la foi"
                },
                {
                    a: "to worship",
                    f: "adorer",
                    indicF: "vénérer"
                }
            ]
        },
        {
            id: 10241,
            idConcours: 240,
            qualifFaute: 1,
            titre: "used to",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Used_to,_Would' target=”_blank” rel=”noopener noreferrer”> used to et to be used to</a>.",
            niveau: '4',
            info: [
                {
                    phrase: "I @used to run@ but I gave it up when we moved to the London.",
                    reponses: ["used to run", "got used to running"]
                },
                {
                    phrase: "She doesn’t think Tom is strange. She @is used to@ him.",
                    reponses: ["used to", "is used to"]
                },
                {
                    phrase: "You come from a big city. You will never @get used to living@ in a small village",
                    reponses: ["get used to living", "used to live"]
                },
                {
                    phrase: "My sister has been a doctor for 7 years. She @is used to working@ at night.",
                    reponses: ["gets used to working", "is used to working"]
                }, 
                {
                    phrase: "When I was a child, I @used to play@ in the street every day.",
                    reponses: ["got used to playing", "used to play"]
                },
                {
                    phrase:"I hated this haircut at first. But I @got used to@ it.  I like it now!",
                    reponses: ["got used to", "was used to"],
                }    
            ]   
        },
        {
            id: 10242,
            titre: "Used To",
            idConcours: 240,
            qualifFaute: 2,
            niveau : 3,
            info: [
                {
                    a: "she is not used to smoking.",
                    f: "elle n'a pas l'habitude de fumer."
                },
                {
                    a: "she used to read novels.",
                    f: "elle lisait des romans."
                },
                {
                    a: "are you used to coming early?",
                    f: "avez-vous l'habitude d'arriver tôt ?"
                },
                {
                    a: "are you used to waking up early?",
                    f: "avez-vous l'habitude de vous réveiller tôt ?"
                },
                {
                    a: "they used to come here.",
                    f: "ils venaient ici."
                }
            ]
        },
        {
            id: 10243,
            titre: "Des adjectifs",
            niveau: 3,
            idConcours: 240,
            qualifFaute: 3,
            info: [
                {
                    audio: "audio11.mp3",
                    a: "it's a funny book.",
                    f: "c'est un livre drôle."
                },
                {
                    audio: "audio12.mp3",
                    a: "yesterday I read an amazing story in a magazine.",
                    f: "hier j'ai lu une histoire incroyable dans un magazine."
                },
                {
                    audio: "audio13.mp3",
                    a: "Tom has a boring job.",
                    f: "Tom a un travail ennuyeux."
                },
                {
                    audio: "audio14.mp3",
                    a: "it was cold and windy.",
                    f: "il faisait froid et il y avait du vent."
                },
                {
                    audio: "audio15.mp3",
                    a: "the scientist was aware of the dangers.",
                    f: "le scientifique était conscient des dangers."
                }
            ]
        },


        {
            id: 10230,
            titre: "Internet",
            idConcours: 230,
            qualifFaute: 2,
            niveau: 4,
            info: [
                {
                    a: "summary",
                    f: "résumé"
                },
                {
                    a: "to sign in",
                    f: "s'identifier",
                    indicF: "s'authentifier",
                },
                {
                    a: "sign up",
                    f: "s'inscrire",
                    indicF: "s'enregistrer",
                },
                {
                    a: "form",
                    f: "formulaire"
                },
                {
                    a: "to subscribe",
                    f: "s'abonner"
                },
                {
                    a: "offline",
                    f: "hors ligne",
                },
                {
                    a: "follower",
                    f: "disciple",
                    indicF :"partisan - fan"
                },
                {
                    a: "to log off",
                    f: "fermer la session",
                },
                {
                    a: "to shut down",
                    f: "éteindre"
                },
                {
                    a: "shortcut",
                    f: "raccourci"
                }
            ]
        },
        {
            id: 10231,
            titre : "FightMediocrity",
            idConcours: 230,
            qualifFaute: 1,
        texte :   " FightMediocrity is a YouTube @channel that works with some of the best authors to create short, powerful @book summaries. The fact that summaries can be accessed in @audio format means you can truly learn quickly, anywhere at anytime. Summaries often include embedded videos, @pictures , and helpful notes from the author.",
        audio: "anglaisTrou4.mp3"   
        },
        {
            id: 10232,
            titre: "some/any",
            idConcours: 230,
            qualifFaute: 2,
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/some-et-any' target=”_blank” rel=”noopener noreferrer”> some/any</a>.",
            niveau: 1,
            info: [
                {
                    phrase: "There aren't @any@ children in the school.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Can I have @some@ sugar, please?",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I don't have @any@ coffee at home.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Do you want @some@ money ?",
                    reponses: ["some", "any"]
                }, 
                {
                    phrase: "I don’t want @any@ cream with my tea.",
                    reponses: ["some", "any"]
                },
                {
                    phrase:"I need @some@ sugar for my coffee.",
                    reponses: ["some", "any"]
                }    
            ]
        },
        {
            id: 10233,
            titre: "Mots niveau intermédiaire",
            niveau: 4,
            idConcours: 230,
            qualifFaute: 1,
            info: [
                {
                    audio: "motParle23.mp3",
                    texteA: "height",
                    texteF: "hauteur"
                },
                {
                    audio: "motParle45.mp3",
                    texteA: "width",
                    texteF: "largeur"

                },
                {
                    audio: "motParle46.mp3",
                    texteA: "length",
                    texteF: "longueur"
                },
                {
                    audio: "motParle47.mp3",
                    texteA: "triangle",
                    texteF: "triangle"
                    
                },
                {
                    audio: "motParle48.mp3",
                    texteA: "square",
                    texteF: "carré"
                    
                }
            ]
        },



        {
            id: 10220,
            idConcours: 220,
            qualifFaute: 2,
            titre: "La météo",
            niveau: 3,
            info: [
                {
                    a: "fog",
                    f: "brouillard"
                },
                {
                    a: "weather forecast",
                    f: "prévision météo"
                },
                {
                    a: "wind",
                    f: "vent",
                },
                {
                    a: "hazy",
                    f: "brumeux",
                    indicF: "flou"
                },
                {
                    a: "to hail",
                    f: "grêler"
                },
                {
                    a: "a thunderstorm",
                    f: "un orage",
                },
                {
                    a: "cool",
                    f: "frais",
                    indicF: "sympa"
                },
                {
                    a: "drizzle",
                    f: "bruine"
                },
                {
                    a: "the heat",
                    f: "la chaleur"
                },
                {
                    a: "the mist",
                    f: "la brume"
                }
            ]
        },

        {
            id: 10221,
            titre: "Les adjectifs possessifs (his,her,its...)",
            aide : "Rappel de cours rapide sur l'utilisation des <a href='https://multigram.ulb.ac.be/eng/Adjectif_possessif' target=”_blank” rel=”noopener noreferrer”> adjectifs possessifs</a>.",
            niveau: '3',
            idConcours: 220,
            qualifFaute: 3,
            info: [
                {
                    phrase: "He kissed @his@ mother.",
                    reponses: ["his", "her"]
                },
                {
                    phrase: "She received @her@ diploma.",
                    reponses: ["their", "its", "her"]
                },
                {
                    phrase: "They left @their@ car in the underground car park.",
                    reponses: ["its", "her", "their"]
                },
                {
                    phrase: "Everybody brought @their@ umbrellas and raincoats.",
                    reponses: ["its", "her", "their"]
                }, 
                {
                    phrase: "Somebody forgot @their@ key here.",
                    reponses: ["their", "her", "his", "its"]
                },
                {
                    phrase:"He lost @his@ life in a plane accident.",
                    reponses: ["his", "her", "its"],
                }    
            ]  
        },
        {
            id: 10222,
            titre: "Leçon de vocabulaire",
            niveau: 1,
            idConcours: 220,
            qualifFaute: 3,
            info: [
                {
                    a: "the other day I met a former teacher of mine.",
                    f: "l'autre jour j'ai rencontré un de mes anciens professeurs."
                },
                {
                    a: "I hope I'll pass the exam.",
                    f: "j'espère que je vais réussir l'examen."
                },
                {
                    a: "he claims that she pushed him.",
                    f: "il affirme qu'elle l'a poussé."
                },
                {
                    a: "actually, I don't know her.",
                    f: "en fait, je ne la connais pas."
                },
                {
                    a: "a wine cellar is a storage room.",
                    f: "une cave à vin est une pièce de stockage."
                }
            ]
        },
        {
            id: 10223,
            titre: "Test intermédiaire",
            idConcours: 220,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "Germinal @was written@ by Zola.",
                    reponses: ["was written", "have been written", "had been written"]
                },
                {
                    phrase: "English @is spoken@ as a second language by a lot of people.",
                    reponses: ["spoke", "is spoken", "has been spoken"]
                },
                {
                    phrase: "Tomatoes @were brought@ to Europe from South America in 1500s.",
                    reponses: ["was brought", "were brought", "had been brought"]
                },
                {
                    phrase: "You will @be asked@ a lot of questions at the interview.",
                    reponses: ["be asking", "be asked"]
                },
                {
                    phrase: "Five people @were arrested@ by the police last week.",
                    reponses: ["were arrested", "has been arrested", "have been arrested"]
                },
            ]
        },



        {
            id: 10210,
            titre: "Les métiers",
            niveau: 3,
            idConcours: 210,
            qualifFaute: 2,
            info: [
                {
                    a: "nurse",
                    f: "infirmier",
                    indicF: "infirmiere"
                },
                {
                    a: "waiter",
                    f: "serveur",
                },
                {
                    a: "butcher",
                    f: "boucher"
                },
                {
                    a: "hairdresser",
                    f: "coiffeur"
                },
                {
                    a: "cook",
                    f: "cuisinier"
                },
                {
                    a: "pharmacist",
                    f: "pharmacien",
                },
                {
                    a: "a civil servant",
                    f: "un fonctionnaire"
                },
                {
                    a: "a caretaker",
                    f: "un gardien",
                    indicF: "un concierge"
                },
                {
                    a: "a mechanic",
                    f: "un mécanicien"
                },
                {
                    a: "an accountant",
                    f: "un comptable"
                }
            ]
        },
        {
            id: 10211,
            titre: "Les comparatifs",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Adjectif:_degr%C3%A9s_de_comparaison' target=”_blank” rel=”noopener noreferrer”> la comparaison</a>.",
            niveau: 2,
            idConcours: 210,
            qualifFaute: 1,
            info: [
                {
                    phrase: "Ryan Gosling  is @the most attractive@ actor in the world.",
                    reponses: ["the most attractive", "the more attractive"]
                },
                {
                    phrase: "A bicycle is @cheaper@ than a plane.",
                    reponses: ["more cheap", "cheaper"]
                },
                {
                    phrase: "Nike is @the most famous@ sport brand.",
                    reponses: ["the more famous", "the most famous"]
                },
                {
                    phrase: "Motorcycles are @more dangerous@ than bicycles.",
                    reponses: ["most dangerous", "more dangerous"]
                }, 
                {
                    phrase: "Mount Everest is @taller@ than Mont Blanc.",
                    reponses: ["taller", "more tall"]
                },
                {
                    phrase:"Alice gets to work @earlier@ than I do.",
                    reponses: ["earlier", "more early"]
                }    
            ]
        },
        {
            id: 10212,
            titre: "Les comparaisons",
            niveau: 3,
            idConcours: 210,
            qualifFaute: 2,
            info: [
                {
                    audio: "audio21.mp3",
                    a: "you're as tall as your sister.",
                    f: "vous êtes aussi grand que votre soeur."
                },
                {
                    audio: "audio22.mp3",
                    a: "I was as naughty as I am now.",
                    f: "j'étais aussi vilain que je le suis maintenant."
                },
                {
                    audio: "audio23.mp3",
                    a: "I earn twice as much as you.",
                    f: "je gagne deux fois plus que vous."
                },
                {
                    audio: "audio24.mp3",
                    a: "Paris is bigger than Liverpool.",
                    f: "Paris est plus grand que Liverpool."
                },
                {
                    audio: "audio26.mp3",
                    a: "the most attractive women are usually single.",
                    f: "les femmes les plus séduisantes sont généralement célibataires."
                }
            ]
        },
        {
            id: 10213,
            titre: "Les grands nombres",
            niveau: 4,
            temps: 120,
            type: "nombre",
            idConcours: 210,
            qualifFaute: 1,
            info: [
                {
                    audio: "rapide21.mp3",
                    a: "123",
                },
                {
                    audio: "rapide22.mp3",
                    a: "1487",
                },
                {
                    audio: "rapide23.mp3",
                    a: "3896",
                },
                {
                    audio: "rapide24.mp3",
                    a: "9856",
                },
                {
                    audio: "rapide25.mp3",
                    a: "2223",
                }
            ]
        },

        {
            id: 10200,
            titre: "Le voyage",
            niveau: 3,
            idConcours: 200,
            qualifFaute: 2,
            info: [
                {
                    a: "a tour",
                    f: "un voyage organisé"
                },
                {
                    a: "itinerary",
                    f: "itinéraire",
                },
                {
                    a: "a flashlight",
                    f: "une lampe de poche"
                },
                {
                    a: "a cruise",
                    f: "une croisière"
                },
                {
                    a: "a camera",
                    f: "un appareil photo"
                },
                {
                    a: "backpack",
                    f: "sac à dos",
                },
                {
                    a: "fare",
                    f: "tarif",
                    indicF: "prix"
                },
                {
                    a: "resort",
                    f: "hôtel",
                    indicF: "complexe touristique"
                },
                {
                    a: "ticket office",
                    f: "billetterie",
                    indicF: "guichet"
                },
                {
                    a: "accommodation",
                    f: "hébergement"
                }
            ]
        },
        {
            id: 10201,
            titre: "much/many",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/much-vs-many' target=”_blank” rel=”noopener noreferrer”> much/many</a>.",
            niveau: 2,
            idConcours: 200,
            qualifFaute: 1,
            info: [
                {
                    phrase: "You need @much@ time to achieve your project.",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "I don't earn @much@ money with this company.",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "How @many@ people are there?",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "How @much@ sugar did you buy?",
                    reponses: ["much", "many"]
                }, 
                {
                    phrase: "You didn't give me @much@ information.",
                    reponses: ["much", "many"]
                },
                {
                    phrase:"How @much@ are three cupcakes?",
                    reponses: ["much", "many"]
                }

                
            ]
        },
        {
            id: 10202,
            titre: "La possession",
            idConcours: 200,
            qualifFaute: 2,
            niveau : 3,
            info: [
                {
                    a: "whose car is it?",
                    f: "à qui appartient la voiture ?"
                },
                {
                    a: "is it yours or is it mine?",
                    f: "est-ce le vôtre ou le mien ?"
                },
                {
                    a: "is that Tom's bag?",
                    f: "c'est le sac de Tom ?"
                },
                {
                    a: "I've had one week's holiday this year.",
                    f: "j'ai eu une semaine de vacances cette année."
                },
                {
                    a: "the dog's dinner is in the fridge.",
                    f: "le dîner du chien est dans le frigo."
                }
            ]
        },
        {
            id: 10203,
            titre: "few/little",
            idConcours: 200,
            qualifFaute: 1,
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/few-little' target=”_blank” rel=”noopener noreferrer”> few/little</a>.",
            niveau: 2,
            info: [
                {
                    phrase: "Alice is very busy. She has @little@ free time.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There were @few@ people in the supermarket.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "The weather has been very dry recently. We've had @little@ rain.",
                    reponses:  ["little", "few"]
                },
                {
                    phrase: "We’ve got a @few@ books.",
                    reponses:  ["little", "few"]
                }, 
                {
                    phrase: "There's only a @little@ pasta left.",
                    reponses:  ["little", "few"]
                },
                {
                    phrase:"There are only a @few@ sausages in the fridge,",
                    reponses:  ["little", "few"]
                }    
            ]
        },





        {
            id: 10190,
            titre: "Les ustensiles de cuisine",
            niveau: 3,
            idConcours: 190,
            qualifFaute: 2,
            info: [
                {
                    a: "corkscrew",
                    f: "tire-bouchon",
                },
                {
                    a: "juicer",
                    f: "presse-fruits",
                },
                {
                    a: "ladle",
                    f: "louche"
                },
                {
                    a: "rolling pin",
                    f: "rouleau à pâtisserie"
                },
                {
                    a: "tray",
                    f: "plateau"
                },
                {
                    a: "cookbook",
                    f: "livre de recettes",
                },
                {
                    a: "grater",
                    f: "râpe"
                },
                {
                    a: "blender",
                    f: "mixeur"
                },
                {
                    a: "pan",
                    f: "poêle",
                    indicF: "pour préparer des plats"
                },
                {
                    a: "pot",
                    f: "casserole"
                }
            ]
        },

        {
            id: 10191,
            titre: "L'impératif",
            niveau: 2,
            idConcours: 190,
            qualifFaute: 1,
            info: [
                {
                    temps: "Imperative",
                    sujet: "(you)",
                    verbe: "to work",
                    forme: "affirmative",
                    reponse: "work"
                },
                {
                    temps: 'Imperative',
                    sujet: '(we)',
                    verbe: 'to fix',
                    forme: 'affirmative',
                    reponse: "let's fix"
                },
                {
                    temps: 'Imperative',
                    sujet: '(you)',
                    verbe: 'to bite',
                    forme: 'negative',
                    reponse: "don't bite"
                },
                {
                    temps: 'Imperative',
                    sujet: '(we)',
                    verbe: 'to wax',
                    forme: 'negative',
                    reponse: "let's not wax"
                }, {
                    temps: "Imperative",
                    sujet: "(you)",
                    verbe: "to buy",
                    forme: "affirmative",
                    reponse: "buy"
                }

            ]
        },
        {
            id: 10192,
            titre: "Des mots simples 1",
            niveau: 1,
            temps: 160,
            idConcours: 190,
            qualifFaute: 0,
            liste: [
                {
                    definition : "Precious metal",
                    mot: "gold",
                    
                },
                {
                    definition: "Great ocean predator",
                    mot: "shark",
                },
                {
                    definition: "This month starts right after December 31",
                    mot: "january",
                    
                },
                {
                    definition : "A speech that is open to the public",
                    mot: "lecture",
                    
                },
                {
                    definition: "labyrinth",
                    mot: "maze",
                },
                {
                    definition: "A tall plant having a main trunk and branches.",
                    mot: "tree",
                    
                }
            ]
        },
        {
            id: 10193,
            titre: "Question tags",
            niveau : 2,
            idConcours: 190,
            qualifFaute: 2,
            info: [
                {
                    a: "you're exhausted, aren't you?",
                    f: "vous êtes épuisé, n'est-ce pas ?"
                },
                {
                    a: "he looks old, doesn't he?",
                    f: "il a l'air vieux, n'est-ce pas ?"
                },
                {
                    a: "it's hot, isn't it?",
                    f: "il fait chaud, n'est-ce pas ?"
                },
                {
                    a: "he'll come tomorrow, won't he?",
                    f: "il viendra demain, n'est-ce pas ?"
                },
                {
                    a: "she didn't enjoy the show, did she?",
                    f: "elle n'a pas apprécié le spectacle, n'est-ce pas ?"
                }
            ]
        },


        {
            id: 10180,
            titre: "La musique",
            idConcours: 180,
            qualifFaute: 2,
            niveau: 3,
            info: [
                {
                    a: "a band",
                    f: "un groupe",
                    indicF : "de musique"
                },
                {
                    a: "to practice",
                    f: "pratiquer",
                    indicF : "s'entraîner"
                },
                {
                    a: "the beat",
                    f: "le rythme",
                    indicF: "le tempo"
                },
                {
                    a: "a tune",
                    f: "un air",
                    indicF: "un morceau"
                },
                {
                    a: "a keyboard",
                    f: "un clavier"
                },
                {
                    a: "soundtrack",
                    f: "bande sonore",
                },
                {
                    a: "a singer",
                    f: "un chanteur"
                },
                {
                    a: "to applaud",
                    f: "applaudir"
                },
                {
                    a: "to record",
                    f: "enregistrer"
                },
                {
                    a: "a track",
                    f: "une piste",
                    indicF: "une chanson"
                }
            ]
        },
        {
            id: 10181,
            titre: "Prépositions 2",
            niveau: 3,
            idConcours: 180,
            qualifFaute: 1,
            info: [
                {
                    phrase: "I'm not scared @of@ spiders.I just find them disgusting.",
                    reponses: ["about", "of", "from", "with"]
                },
                {
                    phrase: "I'm bad @at@ drawing pictures, but I like visiting museums.",
                    reponses: ["to", "in", "at", "for"]
                },
                {
                    phrase: "I'm not interested @in@ sport." ,
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "I'm fond @of@ music.",
                    reponses: ["in", "on", "with", "of"]
                }, 
                {
                    phrase: "I'm still angry @with@ Tom.",
                    reponses: ["to", "on", "of", "with"]
                },
                {
                    phrase:" They have English lessons @on@ Friday.",
                    reponses: ["on", "at", "for", "about"]
                }

                
            ]

        },
        {
            id: 10182,
            titre: "Futur",
            niveau: 2,
            idConcours: 180,
            qualifFaute: 2,
            info: [
                {
                    audio: "audio111.mp3",
                    a: "he won't be a football player.",
                    f: "il ne sera pas footballeur."
                },
                {
                    audio: "audio112.mp3",
                    a: "I won't exercise today.",
                    f: "je ne ferai pas d'exercice aujourd'hui."
                },
                {
                    audio: "audio113.mp3",
                    a: "he won't get angry.",
                    f: "il ne se mettra pas en colère."
                },
                {
                    audio: "audio114.mp3",
                    a: "shall we dance?",
                    f: "voulez-vous danser ?"
                },
                {
                    audio: "audio115.mp3",
                    a: "they won't yell at you.",
                    f: "ils ne vous crieront pas dessus."
                }
            ]
        },
        {
            id: 10183,
            titre: "Prépositions 3",
            niveau: 4,
            idConcours: 180,
            qualifFaute: 1,
            info: [
                {
                    phrase: "  Thank you for your help! It is very kind @of@ you!",
                    reponses: ["about", "of", "at", "with"]
                },
                {
                    phrase: " The plane is going to take @off@ in few minutes.",
                    reponses: ["up", "by", "at", "off"]
                },
                {
                    phrase: " It is not easy to cope @with@ all my problems." ,
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "She should not shout @at@ him.",
                    reponses: ["at", "on", "with", "by"]
                }, 
                {
                    phrase: " She always gets @into@ trouble.",
                    reponses: ["into", "on", "off", "with"]
                },
                {
                    phrase:" He had started ballet classes but after a few lessons he went @off@.",
                    reponses: ["at", "into", "for", "off"]
                }

                
            ]

        },




        {
            id: 10170,
            titre: "Les jeux vidéo",
            idConcours: 170,
            qualifFaute: 2,
            niveau: 3,
            info: [
                {
                    a: "casual gamer",
                    f: "joueur occasionnel"
                },
                {
                    a: "to cheat",
                    indicF: "tromper",
                    f: "tricher"
                },
                {
                    a: "freeware",
                    f: "logiciel gratuit",
                },
                {
                    a: "game over",
                    f: "partie terminée",
                },
                {
                    a: "roster",
                    f: "liste",
                    indicF: "répertoire"
                },
                {
                    a: "loot",
                    f: "butin",
                },
                {
                    a: "map",
                    f: "carte",
                    indicF : "pour se repérer"
                },
                {
                    a: "puzzle game",
                    f: "jeu de réflexion"
                },
                {
                    a: "to dash",
                    f: "partir en courant",
                    indicF: "jeter violemment qqch"
                },
                {
                    a: "screenshot",
                    f: "capture d'écran"
                }
            ]
        },
        {
            id: 10171,
            titre: "Test intermédiaire",
            idConcours: 170,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "Get back! The bomb @is going to@ explode.",
                    reponses: ["will", "is going to"]
                },
                {
                    phrase: "If you don't stop, I @will@ tell your father.",
                    reponses: ["am going to", "will"]
                },
                {
                    phrase: "Don't worry, he @won't@ tell anyone.",
                    reponses: ["won't", "isn't going to"]
                },
                {
                    phrase: "I@'m going to@ buy a new car this year.  ",
                    reponses: ["'m going to", "will"]
                },
                {
                    phrase: " I@will@ come to see you on Sunday.",
                    reponses: ["'m going to", "will"]
                },
            ]
        },
        {
            id: 10172,
            titre: "Futur/vocabulaire",
            niveau: 1,
            idConcours: 170,
            qualifFaute: 3,
            info: [
                {
                    a: "they're taking the train to Paris tomorrow.",
                    f: "ils prennent le train pour Paris demain."
                },
                {
                    a: "she's going to buy a new dress tonight.",
                    f: "elle va acheter une nouvelle robe ce soir."
                },
                {
                    a: "watch out, that car's going to hit you.",
                    f: "attention, cette voiture va te renverser."
                },
                {
                    a: "I will do my best.",
                    f: "je ferai de mon mieux."
                },
                {
                    a: "the sun will rise at 7 o'clock.",
                    f: "le soleil se lèvera à 7 heures."
                }
            ]
        },
        {
            id: 10173,
            titre: "Le futur",
            niveau: 2,
            idConcours: 170,
            qualifFaute: 1,
            info: [
                {
                    temps: "Future",
                    sujet: "he",
                    verbe: "to break",
                    forme: "affirmative",
                    reponsec: "he'll break",
                    reponse : "he will break"
                },
                {
                    temps: 'Future continuous',
                    sujet: 'he',
                    verbe: 'to listen',
                    forme: 'affirmative',
                    reponsec: "he'll be listening",
                    reponse : "he will be listening"

                },
                {
                    temps: 'Future',
                    sujet: 'they',
                    verbe: 'to lend',
                    forme: 'affirmative',
                    reponsec: "they'll lend",
                    reponse: "they will lend"
                },
                {
                    temps: 'Future',
                    sujet: 'you',
                    verbe: 'to belong',
                    forme: 'interrogative',
                    reponse: "will you belong?"
                }, {
                    temps: "Future",
                    sujet: "he",
                    verbe: "to begin",
                    forme: "negative",
                    reponse: "he won't begin"
                },


            ]
        },
        






        {
            id: 10160,
            titre: "L'usine",
            niveau: 3,
            idConcours: 160,
            qualifFaute: 2,
            info: [
                {
                    a: "a plant",
                    f: "une usine" 
                },
                {
                    a: "a workshop",
                    f: "un atelier"
                },
                {
                    a: "a warehouse",
                    f: "un entrepôt",
                },
                {
                    a: "a manufacturer",
                    f: "un fabricant"
                },
                {
                    a: "a trade union",
                    f: "un syndicat"
                },
                {
                    a: "to process",
                    f: "traiter",
                    indicF: "transformer"
                },
                {
                    a: "to handle",
                    f: "manipuler",
                    indicF: "gérer, traiter"
                },
                {
                    a: "a strike",
                    f: "une grêve"
                },
                {
                    a: "a job interview",
                    f: "un entretien d'embauche"
                },
                {
                    a: "to hire",
                    f: "embaucher"
                }
            ]
        },
        {
            id: 10161,
            titre: "Proposer des idées",
            niveau: 3,
            idConcours: 160,
            qualifFaute: 2,
            info: [
                {
                    audio: "audio131.mp3",
                    a: "we should watch that movie.",
                    f: "nous devrions regarder ce film."
                },
                {
                    audio: "audio132.mp3",
                    a: "she could try again tomorrow.",
                    f: "elle pourrait réessayer demain."
                },
                {
                    audio: "audio133.mp3",
                    a: "how about going to a movie tonight?",
                    f: "et si on allait voir un film ce soir ?"
                },
                {
                    audio: "audio134.mp3",
                    a: "I suggest that you study more.",
                    f: "je vous suggère d'étudier davantage."
                },
                {
                    audio: "audio135.mp3",
                    a: "you need to upgrade your skills.",
                    f: "vous devez améliorer vos compétences."
                }
            ]
        
        },
        {
            id: 10162,
            titre: "Compréhension texte",
            niveau: 3,
            idConcours: 160,
            qualifFaute: 0,
            info: {
                texte: "Alice Jones works in St. Louis, Missouri, as a sales representative for a big publishing company. She has a job offer from a smaller publishing company in New York City to become the marketing director for college textbooks. She can't decide if she should take the job or not.<br> In a smaller company, there may be more chances for advancement and promotion because more people get to know you. Her company now is so big that she doesn't know anyone outside her department. But the company in New York doesn't pay as well as the company in St. Louis.<br>  The move from St. Louis to New York is also a big change. New York is an exciting place to live in, but it is expensive and dangerous, especially for a single woman. (By Judy DeFilippo (Northeastern University) and Daphne Macke)" , 
                questions: [
                    {
                        typeQuestion: "ferme",
                        question: 'The company in New York is bigger than the company in St. Louis.',
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: 'The job in New York pays less than the job in St. Louis.',
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: 'New York is a safe city.',
                        reponse: false
                    }
                ]
            }
    },
    {
        id: 10163,
        titre: "Mots simples à prononcer",
        niveau: 1,
        idConcours: 160,
        qualifFaute: 1,
        info: [
            {
                audio: "motParle11.mp3",
                texteA: "easy",
                texteF: "facile"
                
            },
            {
                audio: "motParle12.mp3",
                texteA: "gorgeous",
                texteF: "magnifique, sublime"

            },
            {
                audio: "motParle13.mp3",
                texteA: "fancy",
                texteF: "recherché, raffiné"
            },
            {
                audio: "motParle14.mp3",
                texteA: "average",
                texteF: "moyenne"
            },
            {
                audio: "motParle15.mp3",
                texteA: "seldom",
                texteF: "rarement"
            }
        ]
    },



        {
            id: 10150,
            titre: "A la ferme",
            niveau: 3,
            idConcours: 150,
            qualifFaute: 2,
            info: [
                {
                    a: "farming",
                    f: "agriculture"
                },
                {
                    a: "a barn",
                    f: "une grange"
                },
                {
                    a: "a field",
                    f: "un champ",
                },
                {
                    a: "meadow",
                    f: "pré"
                },
                {
                    a: "a crop",
                    f: "une culture",
                    indicF: "une récolte"
                },
                {
                    a: "yield",
                    f: "rendement",
                },
                {
                    a: "cattle",
                    f: "bétail"
                },
                {
                    a: "to harvest",
                    f: "récolter"
                },
                {
                    a: "to pick",
                    f: "cueillir"
                },
                {
                    a: "to sow",
                    f: "semer"
                }
            ]
        }, 
        {
            id: 10151,
            titre: "must/have to",
            idConcours: 150,
            qualifFaute: 2,
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Must' target=”_blank” rel=”noopener noreferrer”> must/have to</a>.",
            niveau: 3,
            info: [
                {
                    phrase: "I @have to@ do my homework. My mother always checks if I have done the exercises.",
                    reponses: ["have to", "must"]
                },
                {
                    phrase: " I @must@ study hard if I want to pass the exam.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase: "They @mustn't@ shout in a library.",
                    reponses: ["mustn't", "don't have to"]
                },
                {
                    phrase: "Police officers @must@ wear a uniform.",
                    reponses: ["have to", "must"]
                }, 
                {
                    phrase: "I @have to@ buy a new oven, the one I have is broken.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase:"I don't like it but I @have to@ go to school every day.",
                    reponses: ["must", "have to"],
                }    
            ]
        },
        {
            id: 10152,
            titre: "Les modaux (can, must...)",
            idConcours: 150,
            qualifFaute: 2,
            niveau : 2,
            info: [
                {
                    a: "can you pass me the salt?",
                    f: "pouvez-vous me passer le sel ?"
                },
                {
                    a: "is she able to run away?",
                    f: "est-elle capable de s'enfuir ?"
                },
                {
                    a: "he must take care of the children.",
                    f: "il doit prendre soin des enfants."
                },
                {
                    a: "you have to slow down to enjoy the simple moments.",
                    f: "il faut ralentir pour profiter des moments simples."
                },
                {
                    a: "it shouldn't happen.",
                    f: "cela ne devrait pas arriver."
                }
            ]
        },
        {
            id: 10153,
            titre: "Des mots à retrouver 2",
            niveau: 2,
            temps: 140,
            qualifFaute: 0,
            idConcours: 150,
            liste: [
                {
                    definition : "It travels on a track",
                    mot: "train",
                    
                },
                {
                    definition: "A colorless, transparent, odorless, tasteless liquid that forms the seas, lakes, rivers, and rain.",
                    mot: "water",
                },
                {
                    definition: "The planet on which we live ",
                    mot: "earth",
                    
                },
                {
                    definition : "This animal has a very long neck",
                    mot: "giraffe",
                    
                },
                {
                    definition: "The last month of autumn",
                    mot: "november",
                },
                {
                    definition: "Likes to store and eat nuts",
                    mot: "squirrel",
                    
                }
            ]
        },


        {

            id: 10140,
            titre: "Vocabulaire intermédiaire",
            niveau: 2,
            idConcours: 140,
            qualifFaute: 2,
            info: [
                {
                    a: "to yell",
                    f: "crier",
                    indicF : "hurler quand on est en colère"
                },
                {
                    a: "to throw away",
                    f: "jeter"
                },
                {
                    a: "to come in",
                    f: "entrer"
                },
                {
                    a: "to put away",
                    f: "ranger",
                    indicF: "remettre à sa place"
                },
                {
                    a: "to pick up",
                    f: "ramasser"
                },
                {
                    a: "to share",
                    f: "partager"
                },
                {
                    a: "to hang out",
                    f: "trainer",
                    indicF: "passer du temps"

                },
                {
                    a: "to get in trouble",
                    f: "avoir des problèmes"
                },
                {
                    a: "naughty",
                    f: "vilain",
                    indicF :"coquin/coquine"
                },
                {
                    a: "aware",
                    f: "conscient",
                    indicF : "au courant de"
                }

            ]
        },
        {
            id: 10141,
            titre: "Test intermédiaire",
            idConcours: 140,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "You @should@ do more sport if you want to lose weight",
                    reponses: ["can", "should", "must"]
                },
                {
                    phrase: "Drivers @mustn't@ drive over the speed limit.",
                    reponses: ["mustn't", "shouldn't", "cannot"]
                },
                {
                    phrase: "You @have to@ stop when the traffic light is red.",
                    reponses: ["must", "have to", "should"]
                },
                {
                    phrase: "You @don't have to@ book a table. There's plenty of room in that restaurant.",
                    reponses: ["mustn't", "shouldn't", "don't have to"]
                },
                {
                    phrase: "She has just broken her leg so she @can't@ play football.",
                    reponses: ["mustn't", "shouldn't", "can't"]
                },
            ]
        },
        {
            id: 10142,
            titre: "Modal et vocabulaire",
            niveau: 1,
            idConcours: 140,
            qualifFaute: 3,
            info: [
                {
                    a: "she asked her daughter to put away her toys.",
                    f: "elle a demandé à sa fille de ranger ses jouets."
                },
                {
                    a: "I love to hang out with my friends.",
                    f: "j'aime passer du temps avec mes amis."
                },
                {
                    a: "I often get in trouble with my teachers.",
                    f: "j'ai souvent des problèmes avec mes professeurs."
                },
                {
                    a: "you don't have to come in",
                    f: "tu n'as pas besoin d'entrer"
                },
                {
                    a: "you shouldn't go to bed so late.",
                    f: "tu ne devrais pas te coucher si tard."
                }
            ]
        },
        {
            id: 10143,
            titre: "Mot épelé",
            niveau: 5,
            temps: 120,
            idConcours: 140,
            qualifFaute: 1,
            type: "motLettre",
            info: [
                {
                    audio: "rapide140.mp3",
                    a: "help",
                },
                {
                    audio: "rapide141.mp3",
                    a: "life",
                },
                {
                    audio: "rapide142.mp3",
                    a: "number",
                },
                {
                    audio: "rapide143.mp3",
                    a: "economy",
                },
                {
                    audio: "rapide144.mp3",
                    a: "science",
                }
            ]
        }, 



        {

            id: 10130,
            titre: "Vocabulaire intermédiaire",
            niveau: 2,
            idConcours: 130,
            qualifFaute: 2,
            info: [
                {
                    a: "ankle",
                    f: "cheville"
                },
                {
                    a: "to sprain",
                    f: "se fouler",
                    indicF: "se faire une entorse à"
                },
                {
                    a: "to blow",
                    f: "souffler",
                    indicF: "se moucher"
                },
                {
                    a: "to quarrel",
                    f: "se disputer"
                },
                {
                    a: "to surrender",
                    f: "se rendre"
                },
                {
                    a: "wallet",
                    f: "portefeuille"
                },
                {
                    a: "to behave",
                    f: "se comporter",
                },
                {
                    a: "to suspect",
                    f: "soupçonner"
                },
                {
                    a: "inquiry",
                    f: "enquête"
                },
                {
                    a: "burglary",
                    f: "cambriolage"
                }
            ]
        },
        {
            id: 10131,
            titre: "Test intermédiaire",
            idConcours: 130,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "They have a bike. That bike is @theirs@.",
                    reponses: ["their", "theirs"]
                },
                {
                    phrase: "Mum and I have a boat. It's @our@ boat.",
                    reponses: ["theirs", "our", "ours"]
                },
                {
                    phrase: "@Her@ name is Ann.",
                    reponses: ["His", "Her", "Its"]
                },
                {
                    phrase: "Is this your dictionary or @mine@?",
                    reponses: ["yours", "mine", "my", "your"]
                },
                {
                    phrase: "I can't find my wallet. It was next to @yours@.",
                    reponses: ["yours", "its", "mine"]
                },
            ]
        },
        {
            id: 10132,
            titre: "Leçon de vocabulaire",
            niveau: 1,
            idConcours: 130,
            qualifFaute: 3,
            info: [
                {
                    a: "she sprained her ankle.",
                    f: "elle s'est fait une entorse à la cheville."
                },
                {
                    a: "he blew his nose.",
                    f: "il s'est mouché."
                },
                {
                    a: "they brushed their teeth.",
                    f: "ils se sont brossés les dents."
                },
                {
                    a: "they love each other.",
                    f: "ils s'aiment."
                },
                {
                    a: "are you enjoying yourself?",
                    f: "vous amusez-vous ?"
                }
            ]
        },
        {
            id: 10133,
            titre : "Zorro",
            idConcours: 130,
            qualifFaute: 2,
        texte :   "Zorro is a popular superhero from Spanish California. He was a foxy and @clever revenger, who fought for justice. He wore a mask and a hat, was dressed in @black clothes, and was armed with a sword and a @whip . Zorro traveled by a black horse and wherever he was, he left a mark by three hits of the sword. Of course, his friends were honest poor natives, and his enemies were dishonest rich @men .",
        audio: "anglaisTrou2.mp3"   
    },




        {
            id: 10120,
            titre: "La ponctuation (anglais américain)",
            niveau: 3,
            idConcours: 120,
            qualifFaute: 2,
            info: [
                {
                    a: "period",
                    f: "point", 
                    indicA: "dans une phrase",
                    indicF: "dans une phrase"
                },
                {
                    a: "dot",
                    f: "point",
                    indicA: "dans www.evalquiz.com",
                    indicF: "dans www.evalquiz.com"
                },
                {
                    a: "point",
                    f: "point",
                    indicA: "dans 800.098",
                    indicF: "dans 800.098"
                },
                {
                    a: "exclamation point",
                    f: "point d'exclamation"
                },
                {
                    a: "parenthesis",
                    f: "parenthèse",
                },
                {
                    a: "bracket",
                    f: "crochet",
                    indicF: '[ ]',
                    indicA: '[ ]'

                },
                {
                    a: "comma",
                    f: "virgule"
                },
                {
                    a: "colon",
                    f: "deux-points"
                },
                {
                    a: "hyphen",
                    f: "trait d'union"
                },
                {
                    a: "slash",
                    f: "barre oblique",
                    indicF: '/',
                    indicA: '/'
                }
            ]
        },
        {
            id: 10121,
            titre: "Prépositions de position",
            aide : "Des informations sur la différence entre <a href='https://grammar.collinsdictionary.com/english-usage/what-is-the-difference-between-under-below-and-beneath' target=”_blank” rel=”noopener noreferrer”> under et below (in English)</a>.",
            niveau: 3,
            idConcours: 120,
            qualifFaute: 1,
            info: [
                {
                    phrase: "I am @under@ 18. So, I can't vote yet.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "The book is @under@ the table.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "We walked @along@ the river." ,
                    reponses: ["below", "around", "along"]
                },
                {
                    phrase: "Your nose is @below@ your eyes.",
                    reponses: ["above", "over", "under", "below"]
                }, 
                {
                    phrase: "When the ship sank, they jump @into@ the water.",
                    reponses: ["to", "at", "into", "on"]
                },
                {
                    phrase:"The book is @on@ the shelf.",
                    reponses: ["to", "at", "for", "on"]
                }

                
            ]
        },
        {
            id: 10122,
            titre: "Compréhension des prépositions (on, into, below...)",
            niveau: 3,
            idConcours: 120,
            qualifFaute: 1,
            info: {
                image: "imageMaison.jpg",
                questions: [
                    {
                        typeQuestion: "ferme",
                        question: "The bike is on the floor in the basement.",
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The stairs are beside the bathroom on the second floor.",
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The woman is putting something into the fridge.",
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The kitchen is on the first floor in the house.",
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: "The computer is in the room below the attic.",
                        reponse: true
                    }
                ]
            }
    },
    {
        id: 10123,
        titre: "Prépositions sur le temps",
        niveau: 3,
        idConcours: 120,
        qualifFaute: 1,
        info: [
            {
                phrase: "She lived in London @in@ 1911.",
                reponses: ["on", "in", "at", "to"]
            },
            {
                phrase: "@At@ Easter the weather is mild.",
                reponses: ["In", "On", "At", "To"]
            },
            {
                phrase: " I never go out @at@ night." ,
                reponses: ["at", "for", "in", "to"]
            },
            {
                phrase: "My father’s birthday is @on@ September 27th.",
                reponses: ["at", "for", "in", "on"]
            }, 
            {
                phrase: "The students don’t have school @in@ July.",
                reponses: ["to", "on", "at", "in"]
            },
            {
                phrase:"I don’t have English lessons @on@ Wednesday.",
                reponses: ["on", "at", "for", "about"]
            }

            
        ]
    },




        {
            id: 10110,
            titre: "L'entreprise",
            niveau: 3,
            idConcours: 110,
            qualifFaute: 2,
            info: [
                {
                    a: "estimate",
                    f: "devis"
                },
                {
                    a: "sales",
                    f: "ventes"
                },
                {
                    a: "invoice",
                    f: "facture"
                },
                {
                    a: "delivery",
                    f: "livraison"
                },
                {
                    a: "statement",
                    f: "déclaration",
                },
                {
                    a: "supplier",
                    f: "fournisseur",

                },
                {
                    a: "to merge",
                    f: "fusionner"
                },
                {
                    a: "good",
                    f: "marchandise"
                },
                {
                    a: "company",
                    f: "société"
                },
                {
                    a: "shareholder",
                    f: "actionnaire"
                }
            ]
        },
        {
            id: 10111,
            titre: "Le plus-que-parfait (past perfect)",
            niveau: 3,
            idConcours: 110,
            qualifFaute: 3,
            info: [
                {
                    temps: "Past perfect",
                    sujet: "he",
                    verbe: "to allot",
                    forme: "affirmative",
                    reponsec: "he'd allotted",
                    reponse: "he had allotted"
                },
                {
                    temps: 'Past perfect continuous',
                    sujet: 'we',
                    verbe: 'to shine',
                    forme: 'affirmative',
                    reponsec: "we'd been shining",
                    reponse: "we had been shining"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'you',
                    verbe: 'to cheat',
                    forme: 'interrogative',
                    reponse: "had you cheated?"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'you',
                    verbe: 'to enable',
                    forme: 'negative',
                    reponse: "you hadn't enabled"
                }, {
                    temps: "Past perfect",
                    sujet: "they",
                    verbe: "to mix",
                    forme: "interrogative",
                    reponse: "had they mixed?"
                }

            ]
        },
        {
            id: 10112,
            titre: "Le plus-que-parfait",
            idConcours: 110,
            qualifFaute: 4,
            niveau : 3,
            info: [
                {
                    a: "I'd already read the book.",
                    f: "j'avais déjà lu le livre."
                },
                {
                    a: "how many times had he called her at home?",
                    f: "combien de fois l'avait-il appelée chez elle ?"

                },
                {
                    a: "I'd just hung up my phone.",
                    f: "je venais de raccrocher mon téléphone."
                },
                {
                    a: "it was the first time he'd played on stage in 3 years.",
                    f: "c'était la première fois qu'il jouait sur scène en 3 ans."
                },
                {
                    a: "before that, he'd been living in town for about 7 years.",
                    f: "avant cela, il avait vécu en ville pendant environ 7 ans."
                }
            ]
        },
        {
            id: 10113,
            titre: "Des mots à retrouver",
            niveau: 3,
            temps:180,
            idConcours: 110,
            qualifFaute: 0,
            liste: [
                {
                    definition : "When you go to a pub you can order a fish and …",
                    mot: "chips",
                    
                },
                {
                    definition: "A small lake",
                    mot: "pond",
                },
                {
                    definition: "mid-day meal",
                    mot: "lunch",
                    
                },
                {
                    definition : "Striped animal that looks like a horse",
                    mot: "zebra",
                    
                },
                {
                    definition: "A person who freely offers to take part in an enterprise",
                    mot: "volunteer",
                },
                {
                    definition: "a sandwich consisting of beef served on a bun",
                    mot: "hamburger",
                    
                }
            ]
        },


      
        {
            id: 10100,
            titre: "Le sport",
            niveau: 3,
            idConcours: 100,
            qualifFaute: 2,
            info: [
                {
                    a: "yellow card",
                    f: "carton jaune"
                },
                {
                    a: "free kick",
                    f: "coup franc"
                },
                {
                    a: "goalkeeper",
                    f: "gardien"
                },
                {
                    a: "offside",
                    f: "hors-jeu"
                },
                {
                    a: "referee",
                    f: "arbitre",
                },
                {
                    a: "stadium",
                    f: "stade",

                },
                {
                    a: "pitch",
                    f: "terrain"
                },
                {
                    a: "lawn",
                    f: "pelouse"
                },
                {
                    a: "coach",
                    f: "entraîneur"
                },
                {
                    a: "to beat",
                    f: "battre"
                }
            ]
        },
        {
            id: 10101,
            titre: "Compréhension d'un texte à l'oral",
            niveau: 3,
            idConcours: 100,
            qualifFaute: 0,
            info: {
               son: "memoire1.mp3",
               questions: [
                {
                    typeQuestion: "ferme",
                    question: "Peach is the name of Jill’s friend.",
                    reponse: false
                },
                {
                    typeQuestion: "ferme",
                    question: "Jill was 50 last Thursday.",
                    reponse: true
                },
                {
                    typeQuestion: "ferme",
                    question: "Jill called a taxi at half past three.",
                    reponse: true
                },
                {
                    typeQuestion: "ferme",
                    question: "Jill and her friend talked about their days at work together.",
                    reponse: false
                }
            ]
        }
    },
    {
        id: 10102,
        titre: "I want a lover",
        niveau: 3,
        idConcours: 100,
        qualifFaute: 2,
        info: [
            {
                audio: "oral21.mp3",
                texte: "I don't want another drink or fight.",
                
            },
            {
                audio: "oral22.mp3",
                texte: "I don't care whether it's wrong or right.",
            },
            {
                audio: "oral23.mp3",
                texte: "I want a lover tonight.",   
            }
        ]
    },
    {
        id: 10103,
        titre: "Passé",
        niveau: 1,
        idConcours: 100,
        qualifFaute: 2,
        info: [
            {
                audio: "audio161.mp3",
                a: "someone has stolen my wallet.",
                f: "Quelqu'un a volé mon portefeuille."
            },
            {
                audio: "audio162.mp3",
                a: "we got married last year.",
                f: "Nous nous sommes mariés l'année dernière."
            },
            {
                audio: "audio163.mp3",
                a: "he made a big cake.",
                f: "Il a fait un gros gâteau."
            },
            {
                audio: "audio164.mp3",
                a: "I opened the window.",
                f: "J'ai ouvert la fenêtre."
            },
            {
                audio: "audio165.mp3",
                a: "when I was a child I loved swimming.",
                f: "Quand j'étais enfant j'adorais nager."
            }
        ]
    },
        



        {
            id: 10090,
            titre: "Le crime",
            niveau: 3,
            idConcours: 90,
            qualifFaute: 2,
            info: [
                {
                    a: "murderer",
                    f: "assassin"
                },
                {
                    a: "drowning",
                    f: "noyade"
                },
                {
                    a: "to strangle",
                    f: "étrangler"
                },
                {
                    a: "to stab",
                    f: "poignarder"
                },
                {
                    a: "to cut up a body",
                    f: "découper un corps",
                },
                {
                    a: "to slice",
                    f: "trancher",

                },
                {
                    a: "shoot",
                    f: "tir"
                },
                {
                    a: "to choke",
                    f: "étouffer"
                },
                {
                    a: "to poison",
                    f: "empoisonner"
                },
                {
                    a: "to rape",
                    f: "violer"
                }
            ]
        },
        {
            id: 10091,
            titre: "Conjugaison intermédiaire",
            idConcours: 90,
            qualifFaute: 2,
            info: [
                {
                    temps: "Present perfect",
                    sujet: "I",
                    verbe: "to hide",
                    forme: "affirmative",
                    reponsec: "I've hidden",
                    reponse: "I have hidden"
                },
                {
                    temps: "Present perfect continuous",
                    sujet: "we",
                    verbe: "to dream",
                    forme: "negative",
                    reponse: "we haven't been dreaming"
                },
                {
                    temps: "Present continuous",
                    sujet: "I",
                    verbe: "to do",
                    forme: "interrogative",
                    reponse: "am I doing?"
                },
                {
                    temps: "Preterite continuous",
                    sujet: "you",
                    verbe: "to breath",
                    forme: "affirmative",
                    reponse: "you were breathing"
                },
                {
                    temps: "Present perfect",
                    sujet: "we",
                    verbe: "to feel",
                    forme: "interrogative",
                    reponse: "have we felt?"
                },
            ]
        },
        {
            id: 10092,
            titre: "Present perfect progressif",
            niveau : 2,
            idConcours: 90,
            qualifFaute: 3,
            info: [
                {
                    a: "it's been raining.",
                    f: "il a plu.",
                    indicF: "le sol est encore mouillé."

                },
                {
                    a: "how long have you been smoking?",
                    f: "depuis combien de temps fumez-vous ?"

                },
                {
                    a: "they've been missing for years.",
                    f: "ils ont disparu depuis des années."
                },
                {
                    a: "she's been teaching for 35 years.",
                    f: "elle enseigne depuis 35 ans."
                },
                {
                    a: "my dad's been working for 45 years",
                    f: "mon père travaille depuis 45 ans."
                }
            ]
        },
        {
            id: 10093,
            titre: "Des mots simples 2",
            niveau: 1,
            temps:120,
            idConcours: 90,
            qualifFaute: 0,
            liste: [
                {
                    definition : "Source of wool",
                    mot: "sheep",
                    
                },
                {
                    definition: "Make up 21% of the atmosphere",
                    mot: "oxygen",
                },
                {
                    definition: "Food made from flour, water and usually yeast mixed together and baked",
                    mot: "bread",
                    
                },
                {
                    definition : "What falls from a tree in fall",
                    mot: "leaves",
                    
                },
                {
                    definition: "Man’s best friend",
                    mot: "dog",
                },
                {
                    definition: "Humorous anecdote or remark intended to provoke laughter ",
                    mot: "joke",
                    
                }
            ]
        },
       
       
       
       
       
       
       
       
       
       
        {

            id: 10080,
            titre: "Vocabulaire intermédiaire",
            niveau: 2,
            idConcours: 80,
            qualifFaute: 2,
            info: [
                {
                    a: "a lake",
                    f: "un lac"
                },
                {
                    a: "the countryside",
                    f: "la campagne"
                },
                {
                    a: "a bridge",
                    f: "un pont"
                },
                {
                    a: "a stream",
                    f: "un ruisseau"
                },
                {
                    f: "un immeuble",
                    a: "a building"
                },
                {
                    a: "parking space",
                    f: "place de parking"
                },
                {
                    f: "un parking",
                    a: "a car park",
                    indicF: "anglais britannique"
                },
                {
                    f: "la mairie",
                    a: "the town hall"
                },
            ]
        },
        {
            id: 10081,
            titre: "Present perfect",
            idConcours: 80,
            qualifFaute: 3,
            niveau : 2,
            info: [
                {
                    a: "I've already told you once.",
                    f: "je vous l'ai déjà dit une fois.",

                },
                {
                    a: "the movie hasn't finished yet.",
                    f: "le film n'est pas encore terminé."

                },
                {
                    a: "he's known her for 3 months.",
                    f: "il la connaît depuis 3 mois."
                },
                {
                    a: "how long has she worn glasses?",
                    f: "depuis combien de temps porte-t-elle des lunettes ?"
                },
                {
                    a: "she's been married to Cathy since 1995.",
                    f: "elle est mariée à Cathy depuis 1995."
                }
            ]
        },
        {
            id: 10082,
            idConcours: 80,
            qualifFaute: 2,
            titre : "20000 Leagues Under the Sea",
        texte :   "20000 Leagues Under the Sea is a classic science fiction @novel by Jules Verne. It was in 1867 when first rumors about dangerous and horrible monster that lives somewhere in @sea depths appeared. According to witnesses, this creature was enormous and could sink any @ship . Many people claimed that it was just a very big @whale , but this beast was twice bigger than the biggest known whale.",
        audio: "anglaisTrou3.mp3"   
        },
        {
            id: 10083,
            titre: "l'heure (format hh.mm)",
            niveau: 5,
            temps: 180,
            idConcours: 80,
            qualifFaute: 1,
            type: "heure",
            info: [
                {
                    audio: "rapide61.mp3",
                    a: "11.00",
                },
                {
                    audio: "rapide62.mp3",
                    a: "10.30",
                },
                {
                    audio: "rapide63.mp3",
                    a: "16.45",
                },
                {
                    audio: "rapide64.mp3",
                    a: "20.15",
                },
                {
                    audio: "rapide65.mp3",
                    a: "21.50",
                }
            ]
        },
        





        
        {

            id: 10070,
            titre: "Les mots trompeurs",
            niveau: 2,
            idConcours: 70,
            qualifFaute: 2,
            info: [
                {
                    a: "a cave",
                    f: "une caverne"
                },
                {
                    a: "former",
                    f: "ancien"
                },
                {
                    a: "current",
                    f: "actuel"
                },
                {
                    a: "to request",
                    f: "demander"
                },
                {
                    a: "a foreigner",
                    f: "un étranger"
                },
                {
                    a: "actually",
                    f: "en fait"
                },
                {
                    a: "to pretend",
                    f: "faire semblant",
                    indicF: "prétexter"

                },
                {
                    a: "to claim",
                    f: "prétendre",
                    indicF: "réclamer"

                },
                 {
                    a: "bookshop",
                    f: "librairie"
                },
                {
                    a: "library",
                    f: "bibliothèque"
                },
            ]
        },
        {
            id: 10071,
            titre: " Prétérit",
            niveau : 1,
            idConcours: 70,
            qualifFaute: 3,
            info: [
                {
                    a: "he came yesterday.",
                    f: "il est venu hier.",

                },
                {
                    a: "she left her husband 2 years ago.",
                    f: "elle a quitté son mari il y a 2 ans."

                },
                {
                    a: "what did you do last week?",
                    f: "qu'avez-vous fait la semaine dernière ?"
                },
                {
                    a: "he died 5 years ago.",
                    f: "il est mort il y a 5 ans."
                },
                {
                    a: "they worked hard in those days.",
                    f: "ils travaillaient dur à l'époque."
                }
            ]
        },
        {
            id: 10072,
            titre: "Les nombres décimaux",
            niveau: 2,
            temps: 100,
            type: "nombreVirgule",
            idConcours: 70,
            qualifFaute: 0,
            info: [
                {
                    audio: "rapide31.mp3",
                    a: "1.65",
                },
                {
                    audio: "rapide32.mp3",
                    a: "2.3",
                },
                {
                    audio: "rapide33.mp3",
                    a: "4.6",
                },
                {
                    audio: "rapide34.mp3",
                    a: "8.56",
                },
                {
                    audio: "rapide35.mp3",
                    a: "3.99",
                }
            ]
        },
        {
            id: 10073,
            titre: "Des mots simples",
            niveau: 1,
            temps: 120,
            idConcours: 70,
            qualifFaute: 0,
            liste: [
                {
                    definition: "The national drink of England",
                    mot: "tea",

                },
                {
                    definition: "One of the most famous British meals is fish and ...",
                    mot: "chips",

                },
                {
                    definition: "The first meal of the day eaten after waking up",
                    mot: "breakfast",

                },
                {
                    definition: "The most popular flavor of potato crisps in the UK is salt and ...",
                    mot: "vinegar",

                },
                {
                    definition: "Meat used in a shepherd's pie (or cottage pie) ",
                    mot: "lamb",

                },
                {
                    definition: "The cheese for cheeseburgers",
                    mot: "cheddar",

                }
            ]
        },





        {
            id: 10060,
            titre: "Dans la maison",
            niveau: 2,
            idConcours: 60,
            qualifFaute: 2,
            info: [
                {
                    a: "chest of drawers",
                    f: "commode"
                },
                {
                    a: "closet",
                    f: "armoire"
                },
                {
                    a: "blanket",
                    f: "couverture"
                },
                {
                    a: "switch",
                    f: "interrupteur"
                },
                {
                    a: "pillow",
                    f: "oreiller"
                },
                {
                    a: "tap",
                    f: "robinet"
                },
                {
                    a: "cushion",
                    f: "coussin"
                },
                {
                    a: "sofa",
                    f: "canapé"
                },
                {
                    a: "towel",
                    f: "serviette"
                },
                {
                    a: "dish towel",
                    f: "torchon",
                    indicF: "pour la vaisselle"
                }
            ]
        },
        {
            id: 10061,
            titre: "Test facile",
            idConcours: 60,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "Did he @play@ hockey every day?",
                    reponses: ["play", "played"]
                },
                {
                    phrase: "@Were@ they happy together?",
                    reponses: ["Was", "Were", "Did were", "Did"]
                },
                {
                    phrase: "@Was@ the soup hot?",
                    reponses: ["Was", "Were", "Did"]
                },
                {
                    phrase: "She @didn't drink@ all the wine.",
                    reponses: ["didn't drink", "didn't drank", "didn't drunk"]
                },
                {
                    phrase: "I @have never met@ the President.",
                    reponses: ["never met", "have never met"]
                },
            ]
        },
        {
            id: 10062,
            titre: "Passé",
            niveau: 1,
            idConcours: 60,
            qualifFaute: 3,
            info: [
                {
                    a: "she has just phoned.",
                    f: "elle vient de téléphoner."
                },
                {
                    a: "how long has she known him?",
                    f: "depuis combien de temps le connaît-elle ?"
                },
                {
                    a: "have you ever read this book?",
                    f: "avez-vous déjà lu ce livre ?"
                },
                {
                    a: "did you sleep well?",
                    f: "avez-vous bien dormi ?"
                },
                {
                    a: "when I was a student, I worked as a waiter.",
                    f: "quand j'étais étudiant, je travaillais comme serveur."
                }
            ]
        },
        {
            id: 10063,
            titre: "Conjugaison facile",
            idConcours: 60,
            qualifFaute: 3,
            info: [
                {
                    temps: "Preterite",
                    sujet: "you",
                    verbe: "to say",
                    forme: "affirmative",
                    reponse: "you said"
                },
                {
                    temps: "Preterite",
                    sujet: "we",
                    verbe: "to give",
                    forme: "negative",
                    reponse: "we didn't give"
                },
                {
                    temps: "Preterite",
                    sujet: "he",
                    verbe: "to go",
                    forme: "interrogative",
                    reponse: "did he go?"
                },
                {
                    temps: "Present perfect",
                    sujet: "he",
                    verbe: "to do",
                    forme: "affirmative",
                    reponsec: "he's done",
                    reponse: "he has done"
                },
                {
                    temps: "Present perfect",
                    sujet: "you",
                    verbe: "to produce",
                    forme: "negative",
                    reponse: "you haven't produced"
                },
            ]
        },




       
        {
            id: 10050,
            titre: "La nourriture",
            niveau: 2,
            idConcours: 50,
            qualifFaute: 2,
            info: [
                {
                    a: "rice",
                    f: "riz"
                },
                {
                    a: "soft bread",
                    f: "pain de mie"
                },
                {
                    a: "ham",
                    f: "jambon"
                },
                {
                    a: "mustard",
                    f: "moutarde"
                },
                {
                    a: "rusk",
                    f: "biscotte"
                },
                {
                    a: "cottage cheese",
                    f: "fromage blanc"
                },
                {
                    a: "vegetables",
                    f: "légumes"
                },
                {
                    a: "noodles",
                    f: "nouilles"
                },
                {
                    a: "jam",
                    f: "confiture"
                },
                {
                    a: "sausage",
                    f: "saucisse"
                }
            ]
        },
        {
            id: 10051,
            titre: "Les nombres",
            niveau: 2,
            temps: 100,
            idConcours: 50,
            qualifFaute: 0,
            type: "nombre",
            info: [
                {
                    audio: "rapide111.mp3",
                    a: "55",
                },
                {
                    audio: "rapide112.mp3",
                    a: "231",
                },
                {
                    audio: "rapide113.mp3",
                    a: "16",
                },
                {
                    audio: "rapide114.mp3",
                    a: "167",
                },
                {
                    audio: "rapide115.mp3",
                    a: "333",
                }
            ]
        },
        {
            id: 10052,
            titre: "Comprendre un texte",
            niveau: 3,
            idConcours: 50,
            qualifFaute: 0,
            info: {
                texte: "There are seven continents on our planet. Australia is one of the most interesting continents. Twenty five million people live in Australia. They speak English. The weather in Australia is hot and sunny. There are many interesting animals in Australia. Dingoes are dogs but they don’t live with people. They are wild and you can see them in the desert. Kangaroos and koalas live in Australia too. They carry their babies in a special pocket. Koalas are grey and have big ears. They can climb trees.",
                questions: [
                    {
                        typeQuestion: "ferme",
                        question: '25 million people live in Australia',
                        reponse: true
                    },
                    {
                        typeQuestion: "ferme",
                        question: 'Dingoes look like cats.',
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: 'Dingoes live in the forest.',
                        reponse: false
                    },
                    {
                        typeQuestion: "ferme",
                        question: 'Koalas are grey.',
                        reponse: true
                    }
                ]
            }
        },
        {
            id: 10053,
            titre: "Only the dark",
            niveau: 3,
            idConcours: 50,
            qualifFaute: 2,
            info: [
                {
                    audio: "oral11.mp3",
                    texte: "We are sitting in the dark.",
                    
                },
                {
                    audio: "oral12.mp3",
                    texte: "Let's not turn on the lights.",
                },
                {
                    audio: "oral13.mp3",
                    texte: "Don't be scared.",
                    
                },
                {
                    audio: "oral14.mp3",
                    texte: "It's time to make a fire.",
                },
                {
                    audio: "oral15.mp3",
                    texte: "I'm right here next to you.",
                    
                }
            ]
        },
       
       
       
        {
            id: 10040,
            titre: "Les vêtements",
            idConcours: 40,
            qualifFaute: 2,
            niveau: 2,
            info: [
                {
                    a: "trousers",
                    f: "pantalon"
                },
                {
                    a: "jacket",
                    f: "veste"
                },
                {
                    a: "coat",
                    f: "manteau"
                },
                {
                    a: "skirt",
                    f: "jupe"
                },
                {
                    a: "underwear",
                    f: "sous-vêtement"
                },
                {
                    a: "shorts",
                    f: "caleçon"
                },
                {
                    a: "wedding dress",
                    f: "robe de mariée"
                },
                {
                    a: "tie",
                    f: "cravate"
                },
                {
                    a: "bra",
                    f: "soutien-gorge"
                },
                {
                    a: "shirt",
                    f: "chemise"
                }
            ]
        },
        {
            id: 10041,
            titre: "Présent simple",
            niveau : 1,
            idConcours: 40,
            qualifFaute: 3,
            info: [
                {
                    a: "when does she start school?",
                    f: "quand commence-t-elle l'école ?"
                },
                {
                    a: "what time does she get up?",
                    f: "à quelle heure se lève-t-elle ?"
                },
                {
                    a: "are you thirsty?",
                    f: "avez-vous soif ? "
                },
                {
                    a: "how old are you?",
                    f: "quel âge avez-vous ?"
                },
                {
                    a: "she talks about her daily life.",
                    f: "elle parle de sa vie quotidienne."
                }
            ]
        },
        {
            id: 10042,
            titre: "Le présent",
            niveau: 2,
            idConcours: 40,
            qualifFaute: 2,
            info: [
                {
                    temps: "Present continuous",
                    sujet: "we",
                    verbe: "to become",
                    forme: "affirmative",
                    reponsec: "we're becoming",
                    reponse: "we are becoming"
                },
                {
                    temps: 'Present',
                    sujet: 'he',
                    verbe: 'to know',
                    forme: 'affirmative',
                    reponse: "he knows"
                },
                {
                    temps: "Present continuous",
                    sujet: "we",
                    verbe: "to dismiss",
                    forme: "negative",
                    reponse: "we aren't dismissing"
                },
                {
                    temps: 'Present',
                    sujet: 'they',
                    verbe: 'to play',
                    forme: 'interrogative',
                    reponse: "do they play?"
                }, {
                    temps: "Present",
                    sujet: "she",
                    verbe: "to sink",
                    forme: "interrogative",
                    reponse: "does she sink?"
                }

            ]
        },
        {
            id: 10043,
            titre : "Davy Crockett",
            idConcours: 40,
            qualifFaute: 2,
        texte :   "Davy Crockett is a legendary personality for the United States of America. This is the first hero of @this country. Davy proved himself to be an excellent scout, @hunter and politician. Davy refused the opportunity to build a brilliant career in Congress and went to Texas. He became the @hero of the Alamo. The courage and strong @spirit of this amazing officer changed the history of the country. Davy Crockett @died , defending the fortress in Alamo.", 
        audio: "anglaisTrou1.mp3"   
    },

        {
            id: 10030,
            titre: "Le corps humain",
            niveau: 2,
            idConcours: 30,
            qualifFaute: 2,
            info: [
                {
                    a: "stomach",
                    f: "estomac"
                },
                {
                    a: "back",
                    f: "dos"
                },
                {
                    a: "leg",
                    f: "jambe"
                },
                {
                    a: "arm",
                    f: "bras"
                },
                {
                    a: "belly",
                    f: "ventre"
                },
                {
                    a: "knee",
                    f: "genou"
                },
                {
                    a: "elbow",
                    f: "coude"
                },
                {
                    a: "heel",
                    f: "talon"
                },
                {
                    a: "chest",
                    f: "poitrine"
                },
                {
                    a: "shoulder",
                    f: "épaule"
                }
            ]
        },
        {
            id: 10031,
            titre: "Le corps humain",
            niveau : 2,
            idConcours: 30,
            qualifFaute: 3,
            info: [
                {
                    a: "I have a pain in my stomach.",
                    f: "j'ai une douleur dans mon estomac."
                },
                {
                    a: "I have a fever.",
                    f: "j'ai de la fièvre."
                },
                {
                    a: "where does it hurt?",
                    f: "où cela fait-il mal ?"
                },
                {
                    a: "you need a blood test.",
                    f: "vous avez besoin d'une analyse de sang."
                },
                {
                    a: "how are you?",
                    f: "Comment allez-vous ?"
                }
            ]
        },
        {
            id: 10032,
            titre: "Des mots simples 1",
            niveau: 1,
            temps: 80,
            idConcours: 30,
            qualifFaute: 0,
            liste: [
                {
                    definition: "The standard currency in England",
                    mot: "pound",

                },
                {
                    definition: "National flower of England",
                    mot: "rose",

                },
                {
                    definition: "Capital of England",
                    mot: "london",

                },
                {
                    definition: "River which runs through the city of London",
                    mot: "thames",

                },
                {
                    definition: "Winner of the Champions League in 2019",
                    mot: "liverpool",

                },
                {
                    definition: "Capital of Scotland",
                    mot: "edinburgh",

                }
            ]
        },
        {
            id: 10033,
            titre: "Mots très simples à prononcer",
            niveau: 1,
            idConcours: 30,
            qualifFaute: 1,
            info: [
                {
                    audio: "motParle31.mp3",
                    texteA: "country",
                    texteF: "pays"

                },
                {
                    audio: "motParle32.mp3",
                    texteA: "daughter",
                    texteF: "fille"

                },
                {
                    audio: "motParle40.mp3",
                    texteA: "family",
                    texteF: "famille"

                },
                {
                    audio: "motParle34.mp3",
                    texteA: "hairdresser",
                    texteF: "coiffeur"

                },
                {
                    audio: "motParle35.mp3",
                    texteA: "strawberry",
                    texteF: "fraise"

                }
            ]
        },
    

        {
            id: 10020,
            titre: "Dans la cuisine",
            niveau: 2,
            idConcours: 20,
            qualifFaute: 2,
            info: [
                {
                    a: "armchair",
                    f: "fauteuil"
                },
                {
                    a: "oven",
                    f: "four"
                },
                {
                    a: "fridge",
                    f: "réfrigérateur"
                },
                {
                    a: "sink",
                    f: "évier"
                },
                {
                    a: "cupboard",
                    f: "placard"
                },
                {
                    a: "freezer",
                    f: "congélateur"
                },
                {
                    a: "washing machine",
                    f: "machine à laver"
                },
                {
                    a: "dishwasher",
                    f: "lave-vaisselle"
                },
                {
                    a: "chair",
                    f: "chaise"
                },
                {
                    a: "washbasin",
                    f: "lavabo"
                }
            ]
        },
        {
            id: 10021,
            titre: "Les lettres",
            niveau: 2,
            temps: 80,
            idConcours: 20,
            qualifFaute: 1,
            type: "lettre",
            info: [
                {
                    audio: "rapide130.mp3",
                    a: "b",
                },
                {
                    audio: "rapide131.mp3",
                    a: "e",
                },
                {
                    audio: "rapide132.mp3",
                    a: "j",
                },
                {
                    audio: "rapide133.mp3",
                    a: "m",
                },
                {
                    audio: "rapide134.mp3",
                    a: "v",
                }
            ]
        },
        {
            id: 10022,
            titre: "Present",
            niveau: 1,
            idConcours: 20,
            qualifFaute: 2,
            info: [
                {
                    audio: "audio151.mp3",
                    a: "it rains very often.",
                    f: "Il pleut très souvent."
                },
                {
                    audio: "audio152.mp3",
                    a: "they never play chess.",
                    f: "Ils ne jouent jamais aux échecs."
                },
                {
                    audio: "audio153.mp3",
                    a: "he's skating at the moment.",
                    f: "Il est en train de patiner en ce moment."
                },
                {
                    audio: "audio154.mp3",
                    a: "we often buy vegetables.",
                    f: "Nous achetons souvent des légumes."
                },
                {
                    audio: "audio155.mp3",
                    a: "she's eating a sandwich.",
                    f: "Elle mange un sandwich."
                }
            ]
        },
        {
            id: 10023,
            titre: "Présent / to be",
            niveau: 3,
            idConcours: 20,
            qualifFaute: 2,
            info: [
                {
                    audio: "oral31.mp3",
                    texte: "The classroom is big and nice.",

                },
                {
                    audio: "oral32.mp3",
                    texte: "The teacher is standing next to the desk.",

                },
                {
                    audio: "oral33.mp3",
                    texte: "The children are not listening to him.",

                },

            ]
        },







        {
            id: 10010,
            titre: "La maison",
            niveau: 2,
            idConcours: 10,
            qualifFaute: 2,
            info: [
                {
                    a: "ceiling",
                    f: "plafond"
                },
                {
                    a: "kitchen",
                    f: "cuisine"
                },
                {
                    a: "cellar",
                    f: "cave"
                },
                {
                    a: "attic",
                    f: "grenier"
                },
                {
                    a: "bathroom",
                    f: "salle de bain"
                },
                {
                    a: "bedroom",
                    f: "chambre"
                },
                {
                    a: "balcony",
                    f: "balcon"
                },
                {
                    a: "floor",
                    f: "sol"
                },
                {
                    a: "living room",
                    f: "salon"
                },
                {
                    a: "roof",
                    f: "toit"
                }
            ]
        },
        {
            id: 10011,
            titre: "Test facile",
            idConcours: 10,
            qualifFaute: 1,
            niveau: 3,
            info: [
                {
                    phrase: "I don't love you anymore! I @am leaving@  you! ",
                    reponses: ["am leaving", "leave"]
                },
                {
                    phrase: "Tom is too fat! He @weighs@ 103 kilos!",
                    reponses: ["is weighting", "weighs"]
                },
                {
                    phrase: "@Does the train leave@ at 9.15?",
                    reponses: ["Does the train leave", "Is the train leaving"]
                },
                {
                    phrase: "She @is studying@  a lot at the moment.",
                    reponses: ["is studying", "studies"]
                },
                {
                    phrase: "She @works@ every day.",
                    reponses: ["is working", "works"]
                },
            ]
        },
        {
            id: 10012,
            titre: "Présent",
            niveau: 1,
            idConcours: 10,
            qualifFaute: 3,
            info: [
                {
                    a: "are you hungry?",
                    f: "avez-vous faim ?"
                },
                {
                    a: "he takes the bus to school.",
                    f: "il prend le bus pour aller à l'école."
                },
                {
                    a: "she's looking at you.",
                    f: "elle te regarde.",
                    indicF: "en ce moment"
                },
                {
                    a: "you're anxious.",
                    f: "tu es anxieux."
                },
                {
                    a: "you're tired.",
                    f: "tu es fatigué"
                }
            ]
        },
        {
            id: 10013,
            titre: "Conjugaison facile",
            idConcours: 10,
            qualifFaute: 2,
            info: [
                {
                    temps: "Present continuous",
                    sujet: "they",
                    verbe: "to read",
                    forme: "affirmative",
                    reponsec: "they're reading",
                    reponse: "they are reading"
                },
                {
                    temps: "Present continuous",
                    sujet: "he",
                    verbe: "to write",
                    forme: "negative",
                    reponse: "he isn't writing"
                },
                {
                    temps: "Present continuous",
                    sujet: "you",
                    verbe: "to write",
                    forme: "interrogative",
                    reponse: "are you writing?"
                },
                {
                    temps: "Present",
                    sujet: "he",
                    verbe: "to help",
                    forme: "negative",
                    reponse: "he doesn't help"
                },
                {
                    temps: "Present",
                    sujet: "he",
                    verbe: "to live",
                    forme: "interrogative",
                    reponse: "does he live?"
                },
            ]
        },

    ]


export default donneesJeuxConcours;