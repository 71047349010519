let dataOral =
 [
        {
            id: 1,
            titre: "Only the dark",
            niveau: 3,
            info: [
                {
                    audio: "oral11.mp3",
                    texte: "We are sitting in the dark.",
                    
                },
                {
                    audio: "oral12.mp3",
                    texte: "Let's not turn on the lights.",
                },
                {
                    audio: "oral13.mp3",
                    texte: "Don't be scared.",
                    
                },
                {
                    audio: "oral14.mp3",
                    texte: "It's time to make a fire.",
                },
                {
                    audio: "oral15.mp3",
                    texte: "I'm right here next to you.",
                    
                }
            ]
        }, {
            id: 2,
            titre: "I want a lover",
            niveau: 3,
            info: [
                {
                    audio: "oral21.mp3",
                    texte: "I don't want another drink or fight.",
                    
                },
                {
                    audio: "oral22.mp3",
                    texte: "I don't care whether it's wrong or right.",
                },
                {
                    audio: "oral23.mp3",
                    texte: "I want a lover tonight.",   
                }
            ]
        },
        {
            id: 3,
            titre: "I made my excuses and left",
            niveau: 3,
            info: [
                {
                    audio: "oral40.mp3",
                    texte: "I walked into the room.",
                    
                },
                {
                    audio: "oral41.mp3",
                    texte: "Imagine my surprise.",
                    
                },
                {
                    audio: "oral42.mp3",
                    texte: "You were sitting close to him.",
                },
                {
                    audio: "oral43.mp3",
                    texte: "I made my excuses and left.",   
                }
            ]
        }

    ]


export default dataOral;
