import React, {Component} from 'react';
import { verifierStatus } from './Utilitaire';
import { Table, Row, Col } from 'antd';
import { construireNiveauVerbesIrreguliers } from './Utilitaire';

export default class Classement extends Component {

    constructor(props) {
        super(props);
   
        this.state =
        {
            listePremiers : [],
        }
        this.columns = [
          
            {
                title: 'Prénom',
                dataIndex: 'prenom',
                key: 'prenom',
                render: (prenom) => {
                    return prenom[0] === '$' ? <b>{prenom.split("$")[1]}</b> :prenom.split("$")[0];
                }
            },
            {
                title: 'Victoires',
                dataIndex: 'victoire',
                key: 'victoire',
            },
            {
                title: 'Niveau',
                dataIndex: 'victoire',
                key: 'victoire',
                render: victoire => 
               { return construireNiveauVerbesIrreguliers(victoire)}
            }
           
        ]
    }

    async componentDidMount() {
        let url = null;

        if (this.props.jeu === "verbesirreguliers") {
            url = new URL(process.env.REACT_APP_URL_CLASSEMENTVERBESIRREGULIERS);
        }
        else if (this.props.jeu === "code") {
            url = new URL(process.env.REACT_APP_URL_CLASSEMENTCODE);
        }
       

   
        const reponse = await fetch(url);
        if (!verifierStatus(reponse.status))
        {
            return;
        }
        if(reponse.ok) {
            const res = await reponse.json();

            this.setState({
            listePremiers : res,
        })
        }
        else 
        {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }
        
    }


    render()
    {
        return <div>
            <div>
        <div> <Row justify="center">
        <Col xs={24} sm={24} md={16}><Table    pagination={{ defaultPageSize: 5, hideOnSinglePage: true }} columns={this.columns} dataSource={this.state.listePremiers} rowKey='cle' /></Col></Row>
</div>
    </div>
            </div>
            
          
    }
}