import React, { Component } from 'react';
import border from '../../images/border.png';
import {Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import Liens from './Liens.js';
import fond1 from '../../images/fond1.png';
import { withRouter,  Link } from 'react-router-dom';

import Classement from '../commun/Classement.js';

 class Verbes extends Component {

  constructor()
  {
    super();

  }

  render() {
    return <div className="margeHaut">
        <Helmet>
          <title>Les verbes irréguliers anglais</title>
          <meta name="description" content="La liste des verbes irréguliers anglais ainsi qu'un test pour apprendre rapidement et facilement les verbes irréguliers en anglais."/>
                 </Helmet>
              
      <h1 className="titre centre">Apprendre les verbes irréguliers anglais</h1>

      <div className='centre'><small >evalquiz.com : numéro 1 du divertissement intelligent</small></div>
<div className="centre"><img src={border} alt="bordure"></img></div>
<div className="texteBord">
  <p>Il y a environ 200 verbes irréguliers en anglais. Cependant, le nombre exact peut varier selon les sources. Ces verbes ne suivent pas les règles habituelles de conjugaison et ont des formes différentes pour le passé simple et le participe passé. Par conséquent, il est important de les apprendre individuellement.</p>
  
<p>Pour vous aider à les mémoriser, vous pouvez consulter <a href="./blog/verbesirreguliers">la liste des verbes irréguliers anglais</a> sur notre site puis  vous pouvez vous tester sur 10 verbes irréguliers pour essayer d'obtenir la note de 20/20. </p>
 <div className='plateauCategorie'>
      <Link to='/verbes-irreguliers'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Test des verbes irréguliers</div>
    <div className='imageCategorie'><img src={fond1} alt="test des verbes irréguliers anglais"></img></div>
        </div>
        </Link>
</div>

</div>

<h2>Le classement des meilleurs participants</h2>
<p>Nous considérons que si vous faites 8 sans-fautes, vous êtes un champion et vous maitrisez parfaitement les verbes irréguliers anglais </p>
<Classement jeu="verbesirreguliers"></Classement>
 <div className="centre margeHaut"><img src={border} alt="bordure"></img></div>
 <Liens></Liens><Ad></Ad>
</div>
  }
}

export default withRouter(Verbes);