let dataRapide =
 [
        {
            id: 1,
            titre: "Les petits nombres",
            niveau: 2,
            temps:100,
            type: "nombre",
            info: [
                {
                    audio: "rapide11.mp3",
                    a: "66",
                },
                {
                    audio: "rapide12.mp3",
                    a: "16",
                },
                {
                    audio: "rapide13.mp3",
                    a: "46",
                },
                {
                    audio: "rapide14.mp3",
                    a: "23",
                },
                {
                    audio: "rapide15.mp3",
                    a: "11",
                }
            ]
        },{
            id: 2,
            titre: "Les grands nombres",
            niveau: 4,
            temps: 100,
            type: "nombre",
            info: [
                {
                    audio: "rapide21.mp3",
                    a: "123",
                },
                {
                    audio: "rapide22.mp3",
                    a: "1487",
                },
                {
                    audio: "rapide23.mp3",
                    a: "3896",
                },
                {
                    audio: "rapide24.mp3",
                    a: "9856",
                },
                {
                    audio: "rapide25.mp3",
                    a: "2223",
                }
            ]
        },{
            id: 3,
            titre: "Les nombres décimaux",
            niveau: 2,
            temps: 100,
            type: "nombreVirgule",
            info: [
                {
                    audio: "rapide31.mp3",
                    a: "1.65",
                },
                {
                    audio: "rapide32.mp3",
                    a: "2.3",
                },
                {
                    audio: "rapide33.mp3",
                    a: "4.6",
                },
                {
                    audio: "rapide34.mp3",
                    a: "8.56",
                },
                {
                    audio: "rapide35.mp3",
                    a: "3.99",
                }
            ]
        },{
            id: 4,
            titre: "Les dates (format d/m/yyyy)",
            niveau: 4,
            temps: 150,
            type: "date",
            info: [
                {
                    audio: "rapide41.mp3",
                    a: "19.10.2016",
                },
                {
                    audio: "rapide42.mp3",
                    a: "3.3.2019",
                },
                {
                    audio: "rapide43.mp3",
                    a: "14.7.2020",
                },
                {
                    audio: "rapide44.mp3",
                    a: "2.1.1915",
                },
                {
                    audio: "rapide45.mp3",
                    a: "25.12.1852",
                }
            ]
        }
        ,{
            id: 5,
            titre: "Nom épelé",
            niveau: 5,
            temps: 200,
            type: "nom",
            info: [
                {
                    audio: "rapide51.mp3",
                    a: "johnson",
                },
                {
                    audio: "rapide52.mp3",
                    a: "simenon",
                },
                {
                    audio: "rapide53.mp3",
                    a: "planchon",
                },
                {
                    audio: "rapide54.mp3",
                    a: "hilaire",
                },
                {
                    audio: "rapide55.mp3",
                    a: "kospiwicz",
                }
            ]
        } ,{
            id: 9,
            titre: "l'heure 1 (format hh.mm)",
            niveau: 2,
            temps: 150,
            type: "heure",
            info: [
                {
                    audio: "rapide91.mp3",
                    a: "10.00",
                },
                {
                    audio: "rapide92.mp3",
                    a: "17.00",
                },
                {
                    audio: "rapide93.mp3",
                    a: "11.00",
                },
                {
                    audio: "rapide94.mp3",
                    a: "23.30",
                },
                {
                    audio: "rapide95.mp3",
                    a: "11.15",
                }
            ]
        },{
            id: 6,
            titre: "l'heure 2 (format hh.mm)",
            niveau: 5,
            temps: 200,
            type: "heure",
            info: [
                {
                    audio: "rapide61.mp3",
                    a: "11.00",
                },
                {
                    audio: "rapide62.mp3",
                    a: "10.30",
                },
                {
                    audio: "rapide63.mp3",
                    a: "16.45",
                },
                {
                    audio: "rapide64.mp3",
                    a: "20.15",
                },
                {
                    audio: "rapide65.mp3",
                    a: "21.50",
                }
            ]
        }
        ,{
            id: 7,
            titre: "Dans l'entreprise",
            niveau: 3,
            temps: 150,
            type: "mot",
            info: [
                {
                    audio: "rapide71.mp3",
                    a: "tremendous",
                },
                {
                    audio: "rapide72.mp3",
                    a: "achievement",
                },
                {
                    audio: "rapide73.mp3",
                    a: "commitment",
                },
                {
                    audio: "rapide74.mp3",
                    a: "statement",
                },
                {
                    audio: "rapide75.mp3",
                    a: "shipment",
                }
            ]
        }
        ,{
            id: 8,
            titre: "Évaluations de fin d'année",
            niveau: 3,
            temps: 150,
            type: "mot",
            info: [
                {
                    audio: "rapide81.mp3",
                    a: "outstanding",
                },
                {
                    audio: "rapide82.mp3",
                    a: "awesome",
                },
                {
                    audio: "rapide83.mp3",
                    a: "ownership",
                },
                {
                    audio: "rapide84.mp3",
                    a: "insight",
                },
                {
                    audio: "rapide85.mp3",
                    a: "involve",
                }
            ]
        }
        ,{
            id: 10,
            titre: "La relation amoureuse",
            niveau: 2,
            temps: 150,
            type: "mot",
            info: [
                {
                    audio: "rapide101.mp3",
                    a: "honeymoon",
                },
                {
                    audio: "rapide102.mp3",
                    a: "princess",
                },
                {
                    audio: "rapide103.mp3",
                    a: "engaged",
                },
                {
                    audio: "rapide104.mp3",
                    a: "fiancé",
                },
                {
                    audio: "rapide105.mp3",
                    a: "sweetheart",
                }
            ]
        },
        {
            id: 11,
            titre: "Le mariage et les sentiments",
            niveau: 2,
            temps: 150,
            type: "mot",
            info: [
                {
                    audio: "rapide150.mp3",
                    a: "bride",
                },
                {
                    audio: "rapide151.mp3",
                    a: "groom",
                },
                {
                    audio: "rapide152.mp3",
                    a: "delight",
                },
                {
                    audio: "rapide153.mp3",
                    a: "sorrow",
                },
                {
                    audio: "rapide154.mp3",
                    a: "gloomy",
                }
            ]
        }

    ]


export default dataRapide;

