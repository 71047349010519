let dataVocabulaire =
 [
        {
            id: 16,
            titreF: "Présent simple",
            titreA: "Present Simple",
            niveau : 1,
            info: [
                {
                    a: "when does she start school?",
                    f: "quand commence-t-elle l'école ?"
                },
                {
                    a: "what time does she get up?",
                    f: "à quelle heure se lève-t-elle ?"
                },
                {
                    a: "are you thirsty?",
                    f: "avez-vous soif ? "
                },
                {
                    a: "how old are you?",
                    f: "quel âge avez-vous ?"
                },
                {
                    a: "she talks about her daily life.",
                    f: "elle parle de sa vie quotidienne."
                }
            ]
        },
        {
            id: 18,
            titreF: "Présent simple vs présent continu",
            titreA: "Present Simple vs Present Continuous",
            niveau : 1,
            info: [
                {
                    a: "I'm thinking.",
                    f: "je réfléchis",
                    indicF: "en ce moment"

                },
                {
                    a: "it's raining a lot.",
                    f: "il pleut beaucoup.",
                    indicF: "en ce moment"

                },
                {
                    a: "I work in an office.",
                    f: "je travaille dans un bureau.",
                    indicF: "habituellement"
                },
                {
                    a: "I'm working in an office.",
                    f: "je travaille dans un bureau.",
                    indicF: "en ce moment"
                },
                {
                    a: "it rains every day in Brittany.",
                    f: "il pleut tous les jours en Bretagne."
                }
            ]
        },
        {
            id: 17,
            titreF: " Prétérit",
            titreA: "Past Simple",
            niveau : 1,
            info: [
                {
                    a: "he came yesterday.",
                    f: "il est venu hier.",

                },
                {
                    a: "she left her husband 2 years ago.",
                    f: "elle a quitté son mari il y a 2 ans."

                },
                {
                    a: "what did you do last week?",
                    f: "qu'avez-vous fait la semaine dernière ?"
                },
                {
                    a: "he died 5 years ago.",
                    f: "il est mort il y a 5 ans."
                },
                {
                    a: "they worked hard in those days.",
                    f: "ils travaillaient dur à l'époque."
                }
            ]
        },
        {
            id: 19,
            titreF: "Present perfect",
            titreA: "Present Perfect",
            niveau : 2,
            info: [
                {
                    a: "I've already told you once.",
                    f: "je vous l'ai déjà dit une fois.",

                },
                {
                    a: "the movie hasn't finished yet.",
                    f: "le film n'est pas encore terminé."

                },
                {
                    a: "he's known her for 3 months.",
                    f: "il la connaît depuis 3 mois."
                },
                {
                    a: "how long has she worn glasses?",
                    f: "depuis combien de temps porte-t-elle des lunettes ?"
                },
                {
                    a: "she's been married to Cathy since 1995.",
                    f: "elle est mariée à Cathy depuis 1995."
                }
            ]
        },
        {
            id: 20,
            titreF: "Present perfect progressif",
            titreA: "Present Perfect Continuous",
            niveau : 2,
            info: [
                {
                    a: "it's been raining.",
                    f: "il a plu.",
                    indicF: "le sol est encore mouillé."

                },
                {
                    a: "how long have you been smoking?",
                    f: "depuis combien de temps fumez-vous ?"

                },
                {
                    a: "they've been missing for years.",
                    f: "ils ont disparu depuis des années."
                },
                {
                    a: "she's been teaching for 35 years.",
                    f: "elle enseigne depuis 35 ans."
                },
                {
                    a: "my dad's been working for 45 years",
                    f: "mon père travaille depuis 45 ans."
                }
            ]
        },
        {
            id: 21,
            titreF: "Le plus-que-parfait",
            titreA: "Past Perfect",
            niveau : 3,
            info: [
                {
                    a: "I'd already read the book.",
                    f: "j'avais déjà lu le livre."
                },
                {
                    a: "how many times had he called her at home?",
                    f: "combien de fois l'avait-il appelée chez elle ?"

                },
                {
                    a: "I'd just hung up my phone.",
                    f: "je venais de raccrocher mon téléphone."
                },
                {
                    a: "it was the first time he'd played on stage in 3 years.",
                    f: "c'était la première fois qu'il jouait sur scène en 3 ans."
                },
                {
                    a: "before that, he'd been living in town for about 7 years.",
                    f: "avant cela, il avait vécu en ville pendant environ 7 ans."
                }
            ]
        },
        {
            id: 22,
            titreF: "Le futur",
            titreA: "Future Simple",
            niveau : 2,
            info: [
                {
                    a: "the bus is about to leave.",
                    f: "le bus est sur le point de partir."
                },
                {
                    a: "it's going to get worse and worse.",
                    f: "cela va être de pire en pire."
                },
                {
                    a: "I hope you'll visit the exhibition.",
                    f: "j'espère que vous visiterez l'exposition."
                },
                {
                    a: "will she be there?",
                    f: "sera-t-elle là ?"
                },
                {
                    a: "he won't attend the meeting.",
                    f: "il n'assistera pas à la réunion."
                }
            ]
        },
        {
            id: 23,
            titreF: "Les modaux (can, must...)",
            titreA: "Modal verbs",
            niveau : 2,
            info: [
                {
                    a: "can you pass me the salt?",
                    f: "pouvez-vous me passer le sel ?"
                },
                {
                    a: "is she able to run away?",
                    f: "est-elle capable de s'enfuir ?"
                },
                {
                    a: "he must take care of the children.",
                    f: "il doit prendre soin des enfants."
                },
                {
                    a: "you have to slow down to enjoy the simple moments.",
                    f: "il faut ralentir pour profiter des moments simples."
                },
                {
                    a: "it shouldn't happen.",
                    f: "cela ne devrait pas arriver."
                }
            ]
        },
        {
            id: 24,
            titreF: "Les verbes + prépositions",
            titreA: "Prepositions with verbs",
            niveau : 2,
            info: [
                {
                    a: "She's looking for her coat.",
                    f: "elle cherche son manteau."
                },
                {
                    a: "he phoned Tom yesterday.",
                    f: "il a téléphoné à Tom hier."
                },
                {
                    a: "she remembers him.",
                    f: "elle se souvient de lui."
                },
                {
                    a: "he gives up smoking.",
                    f: "il abandonne le tabac."
                },
                {
                    a: "he's just entered the room with a bowl of pasta.",
                    f: "il vient d'entrer dans la pièce avec un bol de pâtes."
                }
            ]
        },
        {
            id: 1,
            titreF: "La famille",
            titreA: "The family",
            niveau : 2,
            info: [
                {
                    a: "she writes books for children.",
                    f: "elle écrit des livres pour les enfants."
                },
                {
                    a: "my sister married a musician.",
                    f: "ma soeur a épousé un musicien."
                },
                {
                    a: "do you have any brothers?",
                    f: "avez-vous des frères ?"
                },
                {
                    a: "are you an only child?",
                    f: "êtes-vous enfant unique ?"
                },
                {
                    a: "are you married or single?",
                    f: "êtes-vous marié ou célibataire ?"
                }
            ]
        },
        {
            id: 2,
            titreF: "Des achats en boutique",
            titreA: "Shopping",
            niveau : 2,
            info: [
                {
                    a: "I'd like some apples.",
                    f: "je voudrais des pommes."
                },
                {
                    a: "anything else?",
                    f: "autre chose ?"
                },
                {
                    a: "that'll be all?",
                    f: "ce sera tout ?"
                },
                {
                    a: "do you sell books?",
                    f: "vendez-vous des livres ?"
                },
                {
                    a: "that's very expensive!",
                    f: "C'est très cher !"
                }
            ]
        },{
            id: 3,
            titreF: "La maison",
            titreA: "The house",
            niveau : 2,
            info: [
                {
                    a: "I keep the wine in the cellar.",
                    f: "je garde le vin dans la cave."
                },
                {
                    a: "the books are stored in the attic.",
                    f: "les livres sont rangés au grenier."
                },
                {
                    a: "we live on the third floor.",
                    f: "nous habitons au troisième étage."
                },
                {
                    a: "the sofa is in the living room.",
                    f: "le canapé est dans la salle de séjour."
                },
                {
                    a: "we had lunch in the kitchen.",
                    f: "nous avons déjeuné dans la cuisine."
                }
            ]
        },{
            id: 4,
            titreF: "Dans la cuisine",
            titreA: "In the kitchen",
            niveau : 2,
            info: [
                {
                    a: "I have meat in the freezer.",
                    f: "j'ai de la viande dans le congélateur."
                },
                {
                    a: "what do you use to clean a kitchen sink?",
                    f: "qu'est-ce que vous utilisez pour nettoyer un évier de cuisine ?"
                },
                {
                    a: "the roast is cooking in the oven.",
                    f: "le rôti est en train de cuire dans le four."
                },
                {
                    a: "which brand of dishwasher is the most reliable?",
                    f: "quelle marque de lave-vaisselle est la plus fiable ?"
                },
                {
                    a: "the salt is in the cupboard.",
                    f: "le sel est dans le placard."
                }
            ]
        },{
            id: 5,
            titreF: "A table",
            titreA: "The restaurant",
            niveau : 2,
            info: [
                {
                    a: "can we have lunch together?",
                    f: "pouvons-nous déjeuner ensemble ?"
                },
                {
                    a: "can you bring me the menu?",
                    f: "pouvez-vous m'apporter le menu ?"
                },
                {
                    a: "it's overcooked.",
                    f: "c'est trop cuit."
                },
                {
                    a: "I don't want a starter.",
                    f: "je ne veux pas d'entrée."
                },
                {
                    a: "the bill please.",
                    f: "l'addition s'il vous plait."
                }
            ]
        },{
            id: 6,
            titreF: "Le corps humain",
            titreA: "The human body",
            niveau : 2,
            info: [
                {
                    a: "I have a pain in my stomach.",
                    f: "j'ai une douleur dans mon estomac."
                },
                {
                    a: "I have a fever.",
                    f: "j'ai de la fièvre."
                },
                {
                    a: "will it hurt?",
                    f: "est-ce que cela va faire mal ?"
                },
                {
                    a: "you need a blood test.",
                    f: "vous avez besoin d'une analyse de sang."
                },
                {
                    a: "you must be in good health to donate a kidney.",
                    f: "vous devez être en bonne santé pour faire don d'un rein."
                }
            ]
        },{
            id: 7,
            titreF: "Au téléphone",
            titreA: "On the phone",
            niveau : 2,
            info: [
                {
                    a: "I'd like to speak to Tom.",
                    f: "je voudrais parler à Tom."
                },
                {
                    a: "can you call me back?",
                    f: "pouvez-vous me rappeler ?"
                },
                {
                    a: "I'd like to leave him a message.",
                    f: "je voudrais lui(homme) laisser un message."
                },
                {
                    a: "when is he going to be back?",
                    f: "quand sera-t-il de retour ?"
                },
                {
                    a: "how do you spell that?",
                    f: "comment épelez-vous cela ?"
                }
            ]
        },{
            id: 8,
            titreF: "Avoir rendez-vous",
            titreA: "To have an appointment",
            niveau : 2,
            info: [
                {
                    a: "I have an appointment.",
                    f: "j'ai rendez-vous."
                },
                {
                    a: "sit down please.",
                    f: "asseyez-vous s'il vous plaît."
                },
                {
                    a: "when are you available?",
                    f: "quand êtes-vous disponible ?"
                },
                {
                    a: "I'm busy.",
                    f: "je suis occupé."
                },
                {
                    a: "I have a meeting on Tuesday.",
                    f: "j'ai une réunion mardi."
                }
            ]
        },{
            id: 9,
            titreF: "Les transports",
            titreA: "Transport",
            niveau : 3,
            info: [
                {
                    a: "I'd like 3 tickets to Paris.",
                    f: "je voudrais 3 billets pour Paris."
                },
                {
                    a: "which platform does the train leave from?",
                    f: "de quel quai part le train ?"
                },
                {
                    a: "how much does it cost to go to Chelsea?",
                    f: "combien cela coûte-t-il d'aller à Chelsea ?"
                },
                {
                    a: "at what time do we expect to arrive?",
                    f: "à quelle heure l'arrivée est-elle prévue ?"
                },
                {
                    a: "how far am I from Manchester?",
                    f: "à quelle distance suis-je de Manchester ?"
                }
            ]
        },{
            id: 10,
            titreF: "La douloureuse relation amoureuse",
            titreA: "The painful relationship",
            niveau : 3,
            info: [
                {
                    a: "she broke my heart.",
                    f: "elle m'a brisé le coeur."
                },
                {
                    a: "she left me for another girl.",
                    f: "elle m'a quitté pour une autre fille."
                },
                {
                    a: "I found out she cheated on me.",
                    f: "j'ai découvert qu'elle m'avait trompé."
                },
                {
                    a: "she broke up with me by text.",
                    f: "elle a rompu avec moi par texto."
                },
                {
                    a: "why am I so afraid to date again?",
                    f: "pourquoi ai-je si peur de sortir à nouveau avec quelqu'un ?"
                }
            ]
        },{
            id: 11,
            titreF: "L'amour idéalisé",
            titreA: "The love of your dream",
            niveau : 2,
            info: [
                {
                    a: "you're all I need.",
                    f: "tu es tout ce dont j'ai besoin."
                },
                {
                    a: "I want to wake up next to you.",
                    f: "je veux me réveiller à côté de toi."
                },
                {
                    a: "I kissed a girl and I liked it.",
                    f: "j'ai embrassé une fille et j'ai aimé ça."
                },
                {
                    a: "it's love at first sight.",
                    f: "c'est le coup de foudre."
                },
                {
                    a: "I want to spend my life with you.",
                    f: "je veux passer ma vie avec toi."
                }
            ]
        },{
            id: 12,
            titreF: "Verbe suivi d'un verbe",
            titreA: "Verb followed by a verb",
            niveau : 3,
            info: [
                {
                    a: "I can't stand people smoking around me.",
                    f: "je ne supporte pas que les gens fument autour de moi."
                },
                {
                    a: "I can't stop eating sweets.",
                    f: "je ne peux pas m'arrêter de manger des sucreries."
                },
                {
                    a: "he likes going out at night.",
                    f: "il aime sortir le soir."
                },
                {
                    a: "I gave up playing tennis a long time ago.",
                    f: "j'ai arrêté de jouer au tennis il y a longtemps."
                },
                {
                    a: "he enjoyed looking at her.",
                    f: "il aimait la regarder."
                }
            ]
        },{
            id: 13,
            titreF: "La possession",
            titreA: "Possession",
            niveau : 3,
            info: [
                {
                    a: "whose car is it?",
                    f: "à qui appartient la voiture ?"
                },
                {
                    a: "is it yours or is it mine?",
                    f: "est-ce le vôtre ou le mien ?"
                },
                {
                    a: "is that Tom's bag?",
                    f: "c'est le sac de Tom ?"
                },
                {
                    a: "I've had one week's holiday this year.",
                    f: "j'ai eu une semaine de vacances cette année."
                },
                {
                    a: "the dog's dinner is in the fridge.",
                    f: "le dîner du chien est dans le frigo."
                }
            ]
        }
        ,{
            id: 14,
            titreF: "Question tags",
            titreA: "Question tags",
            niveau : 2,
            info: [
                {
                    a: "you're exhausted, aren't you?",
                    f: "vous êtes épuisé, n'est-ce pas ?"
                },
                {
                    a: "he looks old, doesn't he?",
                    f: "il a l'air vieux, n'est-ce pas ?"
                },
                {
                    a: "it's hot, isn't it?",
                    f: "il fait chaud, n'est-ce pas ?"
                },
                {
                    a: "he'll come tomorrow, won't he?",
                    f: "il viendra demain, n'est-ce pas ?"
                },
                {
                    a: "she didn't enjoy the show, did she?",
                    f: "elle n'a pas apprécié le spectacle, n'est-ce pas ?"
                }
            ]
        }
        ,{
            id: 15,
            titreF: "Les excuses",
            titreA: "The excuses",
            niveau : 3,
            info: [
                {
                    a: "he apologized for lying to me.",
                    f: "il s'est excusé de m'avoir menti."
                },
                {
                    a: "please excuse me for being so late.",
                    f: "veuillez m'excuser d'être si en retard."
                },
                {
                    a: "please accept my apologies.",
                    f: "veuillez accepter mes excuses."
                },
                {
                    a: "I'm sorry, would you mind repeating your name?",
                    f: "je suis désolé, pourriez-vous répéter votre nom ?"
                },
                {
                    a: "nothing can excuse such behavior.",
                    f: "rien ne peut excuser ce genre de comportement."
                }
            ]
        },
        {
            id: 25,
            titreF: "Used To",
            titreA: "Used To",
            niveau : 3,
            info: [
                {
                    a: "she is not used to smoking.",
                    f: "elle n'a pas l'habitude de fumer."
                },
                {
                    a: "she used to read novels.",
                    f: "elle lisait des romans."
                },
                {
                    a: "are you used to coming early?",
                    f: "avez-vous l'habitude d'arriver tôt ?"
                },
                {
                    a: "are you used to waking up early?",
                    f: "avez-vous l'habitude de vous réveiller tôt ?"
                },
                {
                    a: "they used to come here.",
                    f: "ils venaient ici."
                }
            ]
        },
        {
            id: 26,
            titreF: "Poser une question",
            titreA: "Questions",
            niveau : 3,
            info: [
                {
                    a: "how long is the wall?",
                    f: "quelle est la longueur du mur ?"
                },
                {
                    a: "how tall is your mother?",
                    f: "quelle est la taille de votre mère ?"
                },
                {
                    a: "how wide is the table?",
                    f: "quelle est la largeur de la table ?"
                },
                {
                    a: "how far is the station?",
                    f: "à quelle distance se trouve la gare ?"
                },
                {
                    a: "how much is the cake?",
                    f: "combien coûte le gâteau ?"
                }
            ]
        },
        {
            id: 27,
            titreF: "L'hôtellerie",
            titreA: "The hospitality",
            niveau : 3,
            info: [
                {
                    a: "can I check in?",
                    f: "puis-je effectuer l'enregistrement ?"
                },
                {
                    a: "what time is check-out?",
                    f: "à quelle heure est le départ ?"
                },
                {
                    a: "is breakfast included?",
                    f: "le petit-déjeuner est-il inclus ?"
                },
                {
                    a: "could I get some extra towels?",
                    f: "pourrais-je avoir des serviettes supplémentaires ?"
                },
                {
                    a: "can I have a late check-out?",
                    f: "puis-je bénéficier d'un départ tardif ?"
                }
            ]
        },
        {
            id: 28,
            titreF: "La restauration",
            titreA: "Food service",
            niveau : 3,
            info: [
                {
                    a: "is there a vegetarian version of this dish?",
                    f: "y a-t-il une version végétarienne de ce plat ?"
                },
                {
                    a: "could I get some more bread?",
                    f: "pourrais-je avoir un peu plus de pain ?"
                },
                {
                    a: "is the tip included in the bill?",
                    f: "le pourboire est-il inclus dans l'addition ?"
                },
                {
                    a: "what's the soup of the day?",
                    f: "quelle est la soupe du jour ?"
                },
                {
                    a: "may I have the check?",
                    f: "puis-je avoir l'addition ?"
                }
            ]
        },
        {
            id: 29,
            titreF: "L'environnement et l'écologie",
            titreA: "The environment",
            niveau : 4,
            info: [
                {
                    a: "our planet's health is in our hands.",
                    f: "la santé de notre planète est entre nos mains."
                },
                {
                    a: "clean air is a basic human right.",
                    f: "l'air pur est un droit fondamental de l'homme."
                },
                {
                    a: "clean energy reduces greenhouse gas emissions.",
                    f: "l'énergie propre réduit les émissions de gaz à effet de serre."
                },
                {
                    a: "air pollution harms human health.",
                    f: "la pollution de l'air nuit à la santé humaine."
                },
                {
                    a: "pollution affects both the environment and human health.",
                    f: "la pollution affecte à la fois l'environnement et la santé humaine."
                }
            ]
        },
        {
            id: 30,
            titreF: "Les entretiens",
            titreA: "The interview",
            niveau : 4,
            info: [
                {
                    a: "what are your career goals in the next 5 years?",
                    f: "quels sont vos objectifs de carrière pour les 5 prochaines années ?"
                },
                {
                    a: "one of my strengths is my ability to quickly adapt to new technologies.",
                    f: "une de mes forces est ma capacité à m'adapter rapidement à de nouvelles technologies."
                },
                {
                    a: "I'm excited about the opportunity to work on innovative projects.",
                    f: "Je suis enthousiaste à l'idée de travailler sur des projets innovants."
                },
                {
                    a: "a salesperson needs strong communication skills.",
                    f: "un vendeur a besoin de solides compétences en communication."
                },
                {
                    a: "I enjoy connecting with people and solving their problems.",
                    f: "j'aime établir des liens avec les gens et résoudre leurs problèmes."
                }
            ]
        },
        {
            id: 31,
            titreF: "Les ressources humaines (RH)",
            titreA: "Human Resources (HR)",
            niveau : 4,
            info: [
                {
                    a: "our company values diversity and inclusion in the workplace.",
                    f: "notre entreprise valorise la diversité et l'inclusion sur le lieu de travail."
                },
                {
                    a: "we have a strict policy against workplace harassment.",
                    f: "nous avons une politique stricte contre le harcèlement au travail."
                },
                {
                    a: "we encourage employees to provide feedback through regular surveys.",
                    f: "nous encourageons les employés à donner leur avis grâce à des enquêtes régulières."
                },
                {
                    a: "we're hiring for a new position in our department.",
                    f: "nous recrutons pour un nouveau poste dans notre service."
                },
                {
                    a: "we offer competitive salaries and benefits to our employees.",
                    f: "nous proposons des salaires compétitifs et des avantages sociaux à nos employés."
                }
            ]
        },
        
    ]


export default dataVocabulaire;