import React from 'react';
import MenuHaut from './MenuHaut';
import Routeur from './Routeur';

import './app.css';


function App() {
 

  return (
      <div><MenuHaut>
          <div className="margePrincipale">
              <Routeur>
              </Routeur>
              </div>
      </MenuHaut>
      </div>
  );
}

export default App;
