const dataQcm =
    [
        {
            id: 1,
            titre: "Présent simple/continu",
            niveau: 2,
            info: [
                {
                    phrase: "I @work@ in a bank.",
                    reponses: ["work", "am working"]
                },
                {
                    phrase: "She @is working@ on a new project at the moment.",
                    reponses: ["is working", "works"]
                },
                {
                    phrase: "They @play@ football every weekend.",
                    reponses: ["play", "are playing"]
                },
                {
                    phrase: "Right now, he @is eating@ lunch.",
                    reponses: ["is eating", "eats"]
                },
                {
                    phrase: "We @travel@ to Paris every summer.",
                    reponses: ["travel", "are traveling"]
                },
                {
                    phrase: "He @is studying@ for his exams this week.",
                    reponses: ["is studying", "studies"]
                },
                {
                    phrase: "The sun @rises@ in the east.",
                    reponses: ["rises", "is rising"]
                },
                {
                    phrase: "I @am learning@ English this year.",
                    reponses: ["am learning", "learn"]
                },
                {
                    phrase: "She @goes@ to the gym every morning.",
                    reponses: ["goes", "is going"]
                },
                {
                    phrase: "Right now, I @am listening@ to music.",
                    reponses: ["am listening", "listen"]
                },
                {
                    phrase: "What time @does@ the train leave?",
                    reponses: ["does", "is"]
                },
                {
                    phrase: "What @are@ you doing right now?",
                    reponses: ["are", "do"]
                }
            ]
        }
        ,
        {
            id: 2,
            titre: "Past Simple/Present Perfect",
            niveau: 2,
            info: [
                {
                    phrase: "I @went@ to the bank yesterday",
                    reponses: ["gone", "went", "have gone"]
                },
                {
                    phrase: "I @watched@ a great movie last night.",
                    reponses: ["watched", "have watched", "watch"]
                },
                {
                    phrase: "She @has never been@ to Japan.",
                    reponses: ["has never been", "was", "never went"]
                },
                {
                    phrase: "They @arrived@ at the party two hours ago.",
                    reponses: ["arrived", "have arrived", "were arriving"]
                },
                {
                    phrase: "We @have already eaten@ dinner.",
                    reponses: ["have already eaten", "already ate", "already eat"]
                },
                {
                    phrase: "He @bought@ a new car last week.",
                    reponses: ["bought", "has bought", "buy"]
                },
                {
                    phrase: "I @have never seen@ such a beautiful sunset.",
                    reponses: ["have never seen", "never saw", "saw"]
                },
                {
                    phrase: "They @went@ to the concert last Saturday.",
                    reponses: ["went", "have gone", "were going"]
                },
                {
                    phrase: "She @has just finished@ her homework.",
                    reponses: ["has just finished", "just finished", "finishes"]
                },
                {
                    phrase: "We @played@ soccer in the park yesterday.",
                    reponses: ["played", "have played", "were playing"]
                },
                {
                    phrase: "He @has lived@ in London for five years.",
                    reponses: ["has lived", "lived", "is living"]
                },
                {
                    phrase: "He @didn't go@ to the meeting yesterday.",
                    reponses: ["didn't go", "hasn't gone", "didn't went"]
                },
                {
                    phrase: "She @hasn't called@ me yet.",
                    reponses: ["hasn't called", "didn't call", "hasn't call"]
                },
                {
                    phrase: "Did you @watch@ the movie last night?",
                    reponses: ["watch", "watched", "have watched"]
                },
                {
                    phrase: "Have they @ever traveled@ abroad?",
                    reponses: ["ever traveled", "has traveled", "have travel"]
                },
                {
                    phrase: "We @haven't seen@ him for years.",
                    reponses: ["haven't seen", "didn't see", "haven't saw"]
                },
                {
                    phrase: "Did she @finish@ her homework on time?",
                    reponses: ["finish", "finished", "has finished"]
                },
                {
                    phrase: "They @haven't been@ to the new restaurant yet.",
                    reponses: ["haven't been", "weren't", "haven't be"]
                },


            ]
        },
        {
            id: 10,
            titre: "Les questions simples",
            niveau: 2,
            info: [
                {
                    phrase: "Is the cat sleeping?$ Yes, it @is@ sleeping.",
                    reponses: ["is", "are", "does", "do"]
                },

                {
                    phrase: "Does he like pizza? Yes, he @does@.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "Are they coming to the party? Yes, they @are@ coming.",
                    reponses: ["are", "is", "does", "do"]
                },
                {
                    phrase: "Do you have any pets? Yes, I @do@ have a dog.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "Is she at the office? Yes, she @is@ at the office.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "Do we need to buy tickets? Yes, we @do@.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "Is it raining outside? Yes, it @is@.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "Do you know her? Yes, I @do@.",
                    reponses: ["is", "are", "does", "do"]
                },
                {
                    phrase: "How old is your brother? He @is@ 30 years old.",
                    reponses: ["is", "have", "does", "has"]
                },
                {
                    phrase: "Does she have a smartphone? Yes, she @does@.",
                    reponses: ["have", "does", "do"]
                },

                {
                    phrase: "How old are you? I @am@ 25 years old.",
                    reponses: ["am", "have", "is"]
                },

            ]
        },  
        {
            id: 15,
            titre: "Some/any",
            niveau: 2,
            info: [
                {
                    phrase: "There aren't @any@ children in the school.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Can I have @some@ sugar, please?",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I don't have @any@ coffee at home.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Do you want @some@ money ?",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I don't want @any@ cream with my tea.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I need @some@ sugar for my coffee.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I need @some@ sugar for my coffee.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "If you have @any@ questions about the project, feel free to ask.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "She bought @some@ flowers for the table.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Do you have @any@ idea what happened?",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "There are @some@ apples in the basket.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "We don't have @any@ milk left in the fridge.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Would you like @some@ water to drink?",
                    reponses: ["some", "any"]
                }
            ]

        },
        {
            id: 25,
            titre: "Les adjectifs possessifs",
            niveau: 2,
            info: [
                {
                    phrase: "He kissed @his@ mother.",
                    reponses: ["his", "her"]
                },
                {
                    phrase: "She received @her@ diploma.",
                    reponses: ["their", "its", "her"]
                },
                {
                    phrase: "They left @their@ car in the underground car park.",
                    reponses: ["its", "their"]
                },
                {
                    phrase: "Everybody brought @their@ umbrellas and raincoats.",
                    reponses: ["its", "her", "their"]
                },
                {
                    phrase: "Somebody forgot @their@ key here.",
                    reponses: ["their", "my", "its"]
                },
                {
                    phrase: "He lost @his@ life in a plane accident.",
                    reponses: ["his", "her", "its"],
                },
                {
                    phrase: "He forgot to bring @his@ notebook.",
                    reponses: ["his", "their"]
                },
                {
                    phrase: "The cat is chasing @its@ tail.",
                    reponses: ["his", "its", "their"]
                },
                {
                    phrase: "We should clean @our@ office to keep it tidy.",
                    reponses: ["its", "our", "their"]
                },
                {
                    phrase: "They are proud of @their@ achievements.",
                    reponses: ["its", "their"]
                },
                {
                    phrase: "This is @my@ favorite novel.",
                    reponses: ["my", "its"]
                }
            ]
        },
        {
            id: 11,
            titre: "Les questions avec mots interrogatifs",
            niveau: 2,
            info: [
                {
                    phrase: "@Where@ is the cat? The cat is in the garden.",
                    reponses: ["Where", "When", "What", "Who"]
                },
                {
                    phrase: "@What@ does she want? She wants a cup of tea.",
                    reponses: ["What", "Where", "Who", "When"]
                },
                {
                    phrase: "@Who@ is calling? It's your friend, Emma.",
                    reponses: ["Who", "Where", "When", "How"]
                },
                {
                    phrase: "@Where@ do they live? They live in New York.",
                    reponses: ["Where", "What", "Who", "When"]
                },
                {
                    phrase: "@When@ is the meeting? The meeting is at 3 PM.",
                    reponses: ["When", "Where", "Who", "Why"]
                },
                {
                    phrase: "@Why@ are you late? Because the bus broke down.",
                    reponses: ["Why", "What", "When", "Where"]
                },
                {
                    phrase: "@How@ is your brother? He's feeling much better now.",
                    reponses: ["How", "What", "Who", "Where"]
                },
                {
                    phrase: "@How much@ does this book cost? It costs $10.",
                    reponses: ["How much", "What", "Where", "When"]
                },
                {
                    phrase: "@Which@ color do you prefer? I prefer the blue one.",
                    reponses: ["Which", "What", "Where", "When"]
                },
                {
                    phrase: "@Whose@ bag is this? It's Sarah's bag.",
                    reponses: ["Whose", "Who", "Where", "What"]
                },
                {
                    phrase: "@How far@ is the school from here? It's about 5 kilometers.",
                    reponses: ["How far", "Where", "When", "How much"]
                },
                {
                    phrase: "@What@ are you doing? I’m reading a book.",
                    reponses: ["What", "Where", "Who", "When"]
                },
                {
                    phrase: "@Who@ is responsible for this project? John is responsible.",
                    reponses: ["Who", "Why", "How", "What"]
                },
                {
                    phrase: "@Where@ do you work? I work in a software company.",
                    reponses: ["Where", "When", "Why", "What"]
                },
                {
                    phrase: "@When@ do they usually arrive? They usually arrive at 8 AM.",
                    reponses: ["When", "What", "How", "Who"]
                },
                {
                    phrase: "@Why@ is she crying? She lost her phone.",
                    reponses: ["Why", "When", "What", "Who"]
                }

            ]
        },
        {
            id: 16,
            titre: "Much/many/a lot of",
            niveau: 2,
            info: [
                {
                    phrase: "You need @a lot of@ time to achieve your project.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "I don't earn @much@ money with this company.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "How @many@ people are there?",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "How @much@ sugar did you buy?",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "You didn't give me @much@ information.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "How @much@ are three cupcakes?",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "There isn't @much@ sugar left in the jar.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "How @many@ books do you own in your collection?",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "They don't have @much@ time to finish the project.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "There are @a lot of@ students in the classroom today.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "I don't have @much@ money to spend on the trip.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "How @many@ friends do you think will come to the party?",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "She has @a lot of@ friends in her new school.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "There were @lots of@ people at the concert last night.",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "He spent @a lot of@ time working on that project.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "We saw @lots of@ birds near the lake this morning.",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "They have @a lot of@ money saved for their vacation.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "The park is beautiful with @lots of@ flowers blooming everywhere.",
                    reponses: ["much", "many", "lots of"]
                },
                {
                    phrase: "I don't have @much@ money left after shopping.",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "How @many@ books do you read in a year?",
                    reponses: ["much", "many", "a lot of"]
                },
                {
                    phrase: "We didn't get @much@ sleep last night because of the noise.",
                    reponses: ["much", "many", "a lot of"]
                },
            ]
        }, {
            id: 21,
            titre: "Les modaux",
            niveau: 2,
            info: [
                {
                    phrase: "You @can@ swim in this lake; it's safe.",
                    reponses: ["can", "may", "must", "should"], // Correct: "can"
                },
                {
                    phrase: "When I was younger, I @could@ run faster.",
                    reponses: ["can", "might", "could", "should"], // Correct: "could"
                },
                {
                    phrase: "It @might@ rain later, so take an umbrella.",
                    reponses: ["might", "will", "must", "can"], // Correct: "might"
                },
                {
                    phrase: "You @must@ wear a helmet while riding a bike.",
                    reponses: ["must", "might", "can", "will"], // Correct: "must"
                },
                {
                    phrase: "I @shall@ return shortly with the results.",
                    reponses: ["shall", "will", "may", "must"], // Correct: "shall"
                },
                {
                    phrase: "He @should@ see a doctor about his headache.",
                    reponses: ["must", "should", "can", "might"], // Correct: "should"
                },
                {
                    phrase: "I @will@ call you tomorrow to confirm the details.",
                    reponses: ["might", "should", "will", "shall"], // Correct: "will"
                },
                {
                    phrase: "You @ought to@ exercise more often to stay healthy.",
                    reponses: ["must", "ought to", "can", "might"], // Correct: "ought to"
                },
                {
                    phrase: "I @would@ travel more if I had enough money.",
                    reponses: ["might", "could", "would", "should"], // Correct: "would"
                },
                {
                    phrase: "You @may@ leave now; the meeting is over.",
                    reponses: ["shall", "might", "may", "can"], // Correct: "may"
                },
                {
                    phrase: "She @needs to@ finish the report by Friday.",
                    reponses: ["must", "needs to", "should", "will"], // Correct: "needs to"
                },
                {
                    phrase: "I @dare@ you to jump into the cold water!",
                    reponses: ["will", "dare", "might", "must"], // Correct: "dare"
                },
                    {
                        phrase: "You @must@ finish your homework before going out.",
                        reponses: ["must", "might", "can", "should"], // Correct: "must"
                    },
                    {
                        phrase: "He @can@ play the guitar really well.",
                        reponses: ["must", "can", "might", "should"], // Correct: "can"
                    },
                    {
                        phrase: "You @should@ apologize for what you said.",
                        reponses: ["can", "may", "should", "might"], // Correct: "should"
                    },
                    {
                        phrase: "If you don’t hurry, we @might@ miss the bus.",
                        reponses: ["might", "must", "will", "should"], // Correct: "might"
                    },
                    {
                        phrase: "We @will@ visit our grandparents this weekend.",
                        reponses: ["may", "might", "will", "can"], // Correct: "will"
                    },
                    {
                        phrase: "You @may@ borrow my book if you need it.",
                        reponses: ["must", "might", "may", "should"], // Correct: "may"
                    },
                    {
                        phrase: "He @could@ be the one who left the door open.",
                        reponses: ["must", "could", "shall", "might"], // Correct: "could"
                    },
                    {
                        phrase: "She @shall@ overcome this challenge with hard work.",
                        reponses: ["shall", "will", "can", "might"], // Correct: "shall"
                    },
                    {
                        phrase: "I @would@ like to order a cup of tea, please.",
                        reponses: ["will", "would", "may", "should"], // Correct: "would"
                    },
                    {
                        phrase: "Drivers @must@ stop at red traffic lights.",
                        reponses: ["can", "might", "must", "should"], // Correct: "must"
                    },
                    {
                        phrase: "You @need to@ study harder if you want to pass.",
                        reponses: ["must", "need to", "can", "might"], // Correct: "need to"
                    },
                    {
                        phrase: "I @dare@ you to tell him the truth!",
                        reponses: ["dare", "will", "must", "might"], // Correct: "dare"
                    },
                    {
                        phrase: "He @ought to@ save more money for the future.",
                        reponses: ["shall", "might", "ought to", "can"], // Correct: "ought to"
                    },
                    {
                        phrase: "We @will@ definitely win the match tomorrow.",
                        reponses: ["might", "can", "will", "should"], // Correct: "will"
                    },
                    {
                        phrase: "You @can@ take this seat; it's not occupied.",
                        reponses: ["can", "may", "might", "shall"], // Correct: "can"
                    },
                    {
                        phrase: "You @should@ wear a coat; it's really cold outside.",
                        reponses: ["might", "should", "must", "can"], // Correct: "should"
                    },
                    {
                        phrase: "I @might@ join the party if I finish my work on time.",
                        reponses: ["must", "might", "could", "will"], // Correct: "might"
                    },
                    {
                        phrase: "He @shall@ report to the office tomorrow at 9 AM.",
                        reponses: ["shall", "must", "might", "can"], // Correct: "shall"
                    },
                    {
                        phrase: "We @could@ meet for lunch if you're free.",
                        reponses: ["can", "could", "should", "might"], // Correct: "could"
                    },
                    {
                        phrase: "You @must@ wear a helmet when riding a motorcycle.",
                        reponses: ["might", "must", "should", "can"], // Correct: "must"
                    }
                
            ]
        },
        {
            id: 29,
            titre: "Few/little",
            niveau: 2,
            info: [
                {
                    phrase: "Alice is very busy. She has @little@ free time.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There were @few@ people in the supermarket.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "The weather has been very dry recently. We've had @little@ rain.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "We've got a @few@ books.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There's only a @little@ pasta left.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There are only a @few@ sausages in the fridge,",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There are @few@ chairs in the room, so not everyone can sit down.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "I have @little@ patience for people who are always late.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "The town is small, and there are @few@ shops to visit.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "He showed @little@ interest in the topic during the discussion.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "We made @few@ mistakes during the presentation, so it went well.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There's @little@ hope of finishing this project on time.",
                    reponses: ["little", "few"]
                }
            ]
        },
        {
            id: 40,
            titre: "Prépositions à connaitre",
            niveau: 3,
            info: [
                {
                    phrase: "My brother is afraid @of@ spiders.",
                    reponses: ["about", "of", "from", "to"]
                },
                {
                    phrase: "My aunt is married @to@ a policewoman.",
                    reponses: ["to", "with", "of", "in"]
                },
                {
                    phrase: "Alice was fed up @with@ her job.",
                    reponses: ["of", "for", "to", "with"]
                },
                {
                    phrase: "Your homework is full @of@ mistakes.",
                    reponses: ["in", "on", "with", "of"]
                },
                {
                    phrase: "Don't disturb me. I am busy @with@ my project.",
                    reponses: ["in", "on", "of", "with"]
                },
                {
                    phrase: "French people are famous @for@ their hospitality.",
                    reponses: ["to", "for", "of", "with"]
                },

                {
                    phrase: "I'm bad @at@ drawing pictures, but I like visiting museums.",
                    reponses: ["to", "in", "at", "for"]
                },
                {
                    phrase: "I'm not interested @in@ sport.",
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "I'm fond @of@ music.",
                    reponses: ["in", "on", "with", "of"]
                },
                {
                    phrase: "I'm still angry @with@ Tom.",
                    reponses: ["to", "on", "of", "with"]
                },
                {
                    phrase: " They have English lessons @on@ Friday.",
                    reponses: ["on", "at", "for", "about"]
                },
                {
                    phrase: "  Thank you for your help! It is very kind @of@ you!",
                    reponses: ["about", "of", "at", "with"]
                },
                {
                    phrase: "She is very fond @of@ her cat and spends hours playing with it.",
                    reponses: ["in", "of", "at", "about"]
                },
                {
                    phrase: "He is really interested @in@ learning new programming languages.",
                    reponses: ["at", "in", "with", "of"]
                },
                {
                    phrase: "Sarah is good @at@ solving complex mathematical problems.",
                    reponses: ["in", "at", "about", "with"]
                },
                {
                    phrase: "Tom is angry @at@ his brother for breaking his phone.",
                    reponses: ["at", "about", "in", "with"]
                },
                {
                    phrase: "The children are excited @about@ going to the amusement park this weekend.",
                    reponses: ["at", "about", "in", "with"]
                },
                {
                    phrase: "She is good @at@ cooking delicious meals for her family.",
                    reponses: ["of", "in", "at", "about"]
                }


            ]

        },   {
            id: 20,
            titre: "Must/have to",
            niveau: 3,
            info: [
                {
                    phrase: "I @have to@ do my homework. My mother always checks if I have done the exercises.",
                    reponses: ["have to", "must"]
                },
                {
                    phrase: " I @must@ study hard if I want to pass the exam.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase: "They @mustn't@ shout in a library.",
                    reponses: ["mustn't", "don't have to"]
                },
                {
                    phrase: "Police officers @must@ wear a uniform.",
                    reponses: ["have to", "must"]
                },
                {
                    phrase: "I @have to@ buy a new oven, the one I have is broken.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase: "I don't like it but I @have to@ go to school every day.",
                    reponses: ["must", "have to"],
                },
                    {
                        phrase: "I @must@ finish this report by tomorrow.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "You @have to@ wear a helmet when riding a bike.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "She @must@ be very tired after the long journey.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "They @have to@ attend the meeting if they want to participate.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "I @must@ admit, I didn’t expect you to arrive so early.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "He @has to@ finish his homework before going out.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "You @must not@ talk during the exam.",
                        reponses: ["must", "have to",]
                    },
                    {
                        phrase: "We @don't have to@ pay for the tickets because they're free today.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "She @must@ know the answer, she studied so hard.",
                        reponses: ["must", "have to"]
                    },
                    {
                        phrase: "I @have to@ leave now if I want to catch the train.",
                        reponses: ["must", "have to"]
                    },
                        {
                            phrase: "I @must@ go to the doctor; I’ve been feeling unwell for days.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "She @has to@ stay home today because of the storm.",
                            reponses: ["must", "have to",]
                        },
                        {
                            phrase: "We @must@ improve our customer service if we want to stay competitive.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "You @have to@ finish your work before you leave the office.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "Tom @must@ be joking; there's no way he passed the test without studying.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "They @have to@ wear uniforms at work, it's part of the company policy.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "I @must@ say, your presentation was outstanding.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "He @doesn't have to@ attend the meeting if he’s too busy.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "We @must@ leave immediately to catch the last bus.",
                            reponses: ["must", "have to"]
                        },
                        {
                            phrase: "You @have to@ be at the airport two hours before your flight.",
                            reponses: ["must", "have to"]
                        }
                    
            ]
        },
        {
            id: 43,
            titre: "Prépositions sur le temps",
            niveau: 3,
            info: [
                {
                    phrase: "She lived in London @in@ 1911.",
                    reponses: ["on", "in", "at", "to"]
                },
                {
                    phrase: "@At@ Easter the weather is mild.",
                    reponses: ["In", "On", "At", "To"]
                },
                {
                    phrase: " I never go out @at@ night.",
                    reponses: ["at", "for", "in", "to"]
                },
                {
                    phrase: "My father's birthday is @on@ September 27th.",
                    reponses: ["at", "for", "in", "on"]
                },
                {
                    phrase: "The students don't have school @in@ July.",
                    reponses: ["to", "on", "at", "in"]
                },
                {
                    phrase: "I don’t have English lessons @on@ Wednesday.",
                    reponses: ["on", "at", "for", "about"]
                },
                {
                    phrase: "I will arrive @at@ 5 p.m. tomorrow.",
                    reponses: ["on", "at", "in"], // Correct: "at"
                },
                {
                    phrase: "She was born @on@ July 4th, 1990.",
                    reponses: ["on", "at", "in"], // Correct: "on"
                },
                {
                    phrase: "We will meet @at@ the weekend.",
                    reponses: ["at", "on", "in"], // Correct: "at"
                },
                {
                    phrase: "I haven't seen him @since@ Monday.",
                    reponses: ["since", "for", "by"], // Correct: "since"
                },
                {
                    phrase: "He worked @for@ two hours yesterday.",
                    reponses: ["for", "since", "by"], // Correct: "for"
                },
                {
                    phrase: "The event is scheduled to finish @by@ 10 p.m.",
                    reponses: ["by", "until", "during"], // Correct: "by"
                },
                {
                    phrase: "I will finish the project @by@ next week.",
                    reponses: ["by", "in", "on"], // Correct: "by"
                },
                {
                    phrase: "She arrived @at@ the airport just in time.",
                    reponses: ["on", "at", "in"], // Correct: "at"
                },
                {
                    phrase: "He has been working here @since@ 2015.",
                    reponses: ["since", "for", "by"], // Correct: "since"
                },
                {
                    phrase: "We are going on vacation @in@ the summer.",
                    reponses: ["in", "on", "by"], // Correct: "in"
                }



            ]
        },
        {
            id: 44,
            titre: "Prépositions de position",
            niveau: 3,
            info: [
                {
                    phrase: "I am @under@ 18. So, I can't vote yet.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "The book is @under@ the table.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "We walked @along@ the river.",
                    reponses: ["below", "on", "along", "in"]
                },
                {
                    phrase: "Your nose is @below@ your eyes.",
                    reponses: ["above", "over", "under", "below"]
                },

                {
                    phrase: "The book is @on@ the shelf.",
                    reponses: ["to", "at", "for", "on"]
                },

                {
                    phrase: "The shoes are @under@ the table, placed there by mistake.",
                    reponses: ["below", "in", "under", "over"], // Correct: "under"
                },
                {
                    phrase: "The picture is hanging @on@ the wall, right above the sofa.",
                    reponses: ["on", "in", "under"], // Correct: "on"
                },
                {
                    phrase: "The lamp is placed @on@ the desk, next to the computer.",
                    reponses: ["on", "in", "between"], // Correct: "on"
                },
                {
                    phrase: "The dog is sleeping @under@ the bed, where it's nice and quiet.",
                    reponses: ["under", "on", "in"], // Correct: "under"
                },
                {
                    phrase: "The restaurant is located @between@ the bank and the post office.",
                    reponses: ["on", "in", "between"], // Correct: "between"
                },
                {
                    phrase: "The children are playing @in@ the park, near the playground.",
                    reponses: ["in", "on", "under"], // Correct: "in"
                },
                {
                    phrase: "The bird is @in@ the cage, singing happily.",
                    reponses: ["in", "on", "under"]
                },
                {
                    phrase: "The book is @under@ the pile of papers, hidden from view.",
                    reponses: ["under", "above", "beside"]
                },
                {
                    phrase: "The keys are @in@ the pocket of my coat.",
                    reponses: ["in", "on", "behind"]
                },
                {
                    phrase: "The apple is @on@ the tree, high up in the branches.",
                    reponses: ["on", "in", "under"]
                },
                {
                    phrase: "The picture is @above@ the fireplace, on the wall.",
                    reponses: ["above", "below", "beside"]
                },
                {
                    phrase: "The dog is hidden @behind@ the sofa, chewing on a bone.",
                    reponses: ["behind", "in front of", "beside"]
                },
                {
                    phrase: "The pen is @between@ the book and the notebook, on the desk.",
                    reponses: ["between", "above", "below"]
                },
                {
                    phrase: "The fish is @in@ the aquarium, swimming around the coral.",
                    reponses: ["in", "on", "under"]
                },
                {
                    phrase: "The hat is @on@ the rack, near the door.",
                    reponses: ["on", "in", "under"]
                }


            ]
        },
        {
            id: 28,
            titre: "La comparaison",
            niveau: 3,
            info: [

                {
                    phrase: "A bicycle is @cheaper@ than a plane.",
                    reponses: ["more cheap", "cheaper"]
                },

                {
                    phrase: "Motorcycles are @more dangerous@ than bicycles.",
                    reponses: ["most dangerous", "more dangerous"]
                },
                {
                    phrase: "Mount Everest is @taller@ than Mont Blanc.",
                    reponses: ["taller", "more tall"]
                },
                {
                    phrase: "Alice gets to work @earlier@ than I do.",
                    reponses: ["earlier", "more early", "as early as"]
                },
                {
                    phrase: "This book is @more interesting@ than the one I read last week.",
                    reponses: ["more interesting", "interestinger", "as interesting as"]
                },
                {
                    phrase: "John runs @faster@ than his brother.",
                    reponses: ["faster", "more fast"]
                },
                {
                    phrase: "The second movie was @better@ than the first one.",
                    reponses: ["better", "more good"]
                },
                {
                    phrase: "She speaks English @more fluently@ than her colleagues.",
                    reponses: ["more fluently", "fluentlier", "as fluent as"]
                },
                {
                    phrase: "This room is @quieter@ than the one downstairs.",
                    reponses: ["quieter", "more quiet", "as quiet as"]
                },
                {
                    phrase: "Today is @colder@ than yesterday.",
                    reponses: ["colder", "more cold", "as cold as"]
                },
                {
                    phrase: "She is @as tall as@ her brother, so they can share the same clothes.",
                    reponses: ["as tall as", "taller than"]
                },
                {
                    phrase: "This car is @as fast as@ the one we rented last week, so we can expect the same speed.",
                    reponses: ["as fast as", "more fast than"]
                },
                {
                    phrase: "The movie was @as interesting as@ the book, so I enjoyed it just as much.",
                    reponses: ["as interesting as", "more interesting than"]
                },
                {
                    phrase: "John is @as old as@ his cousin, so they are in the same grade at school.",
                    reponses: ["as old as", "older than"]
                },
                {
                    phrase: "Their house is @as big as@ ours, so we can invite the same number of guests.",
                    reponses: ["as big as", "bigger than"]
                },
                {
                    phrase: "This question is @as difficult as@ the last one, so it will take the same amount of time to solve.",
                    reponses: ["as difficult as", "more difficult than"]
                },
                {

                    phrase: "She ran @further@ than I expected. ",
                    reponses: ["further", "far", "farter", "as far as"]
                }

            ]
        },
        {
            id: 45,
            titre: "Verbes avec préposition",
            niveau: 3,
            info: [

                {
                    phrase: " The plane is going to take @off@ in few minutes.",
                    reponses: ["up", "by", "at", "off"]
                },
                {
                    phrase: " It is not easy to cope @with@ all my problems.",
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "She should not shout @at@ him.",
                    reponses: ["at", "on", "with", "by"]
                },
                {
                    phrase: " She always gets @into@ trouble.",
                    reponses: ["into", "on", "off", "with"]
                },
                {
                    phrase: "It is not easy to cope @with@ all my problems.",
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "He came @across@ an interesting article while browsing the internet.",
                    reponses: ["to", "across", "in", "over"]
                },
                {
                    phrase: "They decided to call @off@ the wedding because of unforeseen circumstances.",
                    reponses: ["up", "on", "off", "over"]
                },
                {
                    phrase: "The plane took @off@ smoothly despite the bad weather.",
                    reponses: ["off", "in", "over", "up"]
                },
                {
                    phrase: "She looks @after@ her little brother when their parents are out.",
                    reponses: ["after", "on", "at", "over"]
                },
                {
                    phrase: "I ran @into@ an old friend at the supermarket yesterday.",
                    reponses: ["on", "into", "in", "across"]
                },
                {
                    phrase: "He gave @up@ smoking after many years of trying.",
                    reponses: ["off", "out", "up", "away"]
                },
                {
                    phrase: "While cleaning the attic, I came @across@ an old photo album.",
                    reponses: ["to", "across", "on", "over"]
                },
                {
                    phrase: "We need to set @up@ the equipment before the event starts.",
                    reponses: ["up", "off", "in", "over"]
                },
                {
                    phrase: "The alarm went @off@ in the middle of the night, waking everyone up.",
                    reponses: ["up", "out", "off", "in"]
                },
                {
                    phrase: "He shouted @at@ his brother for making a mess in the kitchen.",
                    reponses: ["at", "to", "on", "in"]
                },
                {
                    phrase: "She is really scared @of@ spiders and won't go near them.",
                    reponses: ["of", "about", "with", "on"]
                },
                {
                    phrase: "We ran @out@ of milk, so I had to go to the store.",
                    reponses: ["out", "over", "up", "off"]
                },
                {
                    phrase: "I need to catch @up@ on my reading before the exam.",
                    reponses: ["up", "on", "in", "out"]
                },
                {
                    phrase: "He took @off@ his jacket because it was too warm inside.",
                    reponses: ["off", "in", "out", "down"]
                },
                {
                    phrase: "She put @off@ her trip because of the weather forecast.",
                    reponses: ["off", "on", "up", "in"]
                },
                {
                    phrase: "The teacher told us to carry @on@ with our work despite the noise.",
                    reponses: ["on", "off", "in", "out"]
                },
                {
                    phrase: "The police are looking @into@ the case of the missing jewelry.",
                    reponses: ["into", "for", "on", "up"]
                },
                {
                    phrase: "He turned @down@ the job offer because the salary was too low.",
                    reponses: ["down", "off", "over", "in"]
                },
                {
                    phrase: "She takes @after@ her mother in both looks and personality.",
                    reponses: ["after", "on", "up", "off"]
                }




            ]

        },

        {
            id: 32,
            titre: "Le superlatif",
            niveau: 3,
            info: [
                {
                    phrase: "Mount Everest is the @highest@ mountain in the world.",
                    reponses: ["highest", "high"]
                },
                {
                    phrase: "This is the @most interesting@ book I've ever read.",
                    reponses: ["most interesting", "more interesting"]
                },
                {
                    phrase: "She is the @best@ player on the team.",
                    reponses: ["best", "better"]
                },
                {
                    phrase: "This is the @cheapest@ option available.",
                    reponses: ["cheapest", "cheaper"]
                },
                {
                    phrase: "That was the @longest@ movie I've ever seen.",
                    reponses: ["longest", "longer"]
                },
                {
                    phrase: "He is the @smartest@ student in the class.",
                    reponses: ["smartest", "smarter"]
                },
                {
                    phrase: "The blue dress is the @prettiest@ one in the store.",
                    reponses: ["prettiest", "prettier"]
                },
                {
                    phrase: "This is the @most expensive@ restaurant in town.",
                    reponses: ["most expensive", "expensiver"]
                },
                {
                    phrase: "Today is the @coldest@ day of the year.",
                    reponses: ["coldest", "colder"]
                },
                {
                    phrase: "The Grand Canyon is one of the @most famous@ landmarks in the world.",
                    reponses: ["most famous", "famous"]
                },
                {
                    phras: "She is the @worst@ cook in the class.",
                    reponses: ["worst", "worse"]
                },
                {
                    phrase: "This is the @best@ cake I've ever tasted.",
                    reponses: ["best", "better"]
                },
                {
                    phrase: "He is the @furthest@ along in the race.",
                    reponses: ["furthest", "farther"]
                },
                {
                    phrase: "This is the @least@ interesting topic in the course.",
                    reponses: ["least", "less"]
                },
                {
                    phrase: "She has the @oldest@ dog in the neighborhood.",
                    reponses: ["oldest", "older"]
                }
            ]

        }, {
            id: 30,
            titre: "Les pronoms réfléchis et réciproques",
            niveau: 3,
            info: [
                {
                    phrase: "She looked at @herself@ in the mirror.",
                    reponses: ["herself", "each other", "themselves", "myself"], // Correct: "herself"
                },
                {
                    phrase: "The two friends hugged @each other@ warmly.",
                    reponses: ["themselves", "each other", "herself", "itself"], // Correct: "each other"
                },
                {
                    phrase: "The dog is chasing @itself@ in circles.",
                    reponses: ["himself", "herself", "itself", "each other"], // Correct: "itself"
                },
                {
                    phrase: "We enjoyed @ourselves@ at the party.",
                    reponses: ["ourselves", "themselves", "each other", "myself"], // Correct: "ourselves"
                },
                {
                    phrase: "You should believe in @yourself@ to achieve success.",
                    reponses: ["herself", "yourself", "each other", "oneself"], // Correct: "yourself"
                },
                {
                    phrase: "The players congratulated @one another@ after the game.",
                    reponses: ["itself", "themselves", "one another", "herself"], // Correct: "one another"
                },
                {
                    phrase: "He blamed @himself@ for the mistake.",
                    reponses: ["herself", "themselves", "himself", "each other"], // Correct: "himself"
                },
                {
                    phrase: "The group organized @themselves@ for the presentation.",
                    reponses: ["each other", "themselves", "ourselves", "himself"], // Correct: "themselves"
                },
                {
                    phrase: "They always help @each other@ with their homework.",
                    reponses: ["themselves", "each other", "herself", "yourself"], // Correct: "each other"
                },
                {
                    phrase: "I taught @myself@ how to code using online tutorials.",
                    reponses: ["oneself", "yourself", "myself", "himself"], // Correct: "myself"
                },
                {
                    phrase: "She prepared @herself@ for the important interview.",
                    reponses: ["herself", "each other", "themselves", "myself"], // Correct: "herself"
                },
                {
                    phrase: "We promised to support @one another@ in difficult times.",
                    reponses: ["itself", "one another", "ourselves"], // Correct: "one another"
                },
                {
                    phrase: "The cat scratched @itself@ while playing with the toy.",
                    reponses: ["itself", "herself", "themselves", "each other"], // Correct: "itself"
                },
                {
                    phrase: "They challenged @themselves@ to complete the marathon.",
                    reponses: ["themselves", "each other", "ourselves", "herself"], // Correct: "themselves"
                },
                {
                    phrase: "You and I should trust @each other@ to make this work.",
                    reponses: ["ourselves", "each other", "themselves", "itself"], // Correct: "each other"
                },
                {
                    phrase: "He introduced @himself@ to the new colleagues.",
                    reponses: ["herself", "himself", "yourself", "themselves"], // Correct: "himself"
                },
                {
                    phrase: "The children entertained @themselves@ with a board game.",
                    reponses: ["each other", "themselves", "ourselves", "himself"], // Correct: "themselves"
                },
                {
                    phrase: "They smiled at @one another@ during the meeting.",
                    reponses: ["itself", "one another", "herself", "themselves"], // Correct: "one another"
                },
                {
                    phrase: "I need to remind @myself@ to buy groceries later.",
                    reponses: ["myself", "oneself", "yourself", "himself"], // Correct: "myself"
                },
                {
                    phrase: "You should take care of @yourself@ after working so hard.",
                    reponses: ["yourself", "herself", "himself", "themselves"], // Correct: "yourself"
                }
            ]
        },
        {
            id: 33,
            titre: "Verbes infinitif/gérondif",
            niveau: 4,
            info: [
                {
                    phrase: "My brother enjoys @singing@ very much.",
                    reponses: ["singing", "to sing", "sing"]
                },
                {
                    phrase: "My sister doesn't want @to dance@ with Tom.",
                    reponses: ["to dance", "dancing", "dance"]
                },
                {
                    phrase: "I don't expect you @to do@ all the work for me.",
                    reponses: ["to do", "doing", "do"]
                },
                {
                    phrase: "John hates @flying@.",
                    reponses: ["to fly", "flying"]
                },
                {
                    phrase: "They should @go@ to the doctor.",
                    reponses: ["to go", "go", "going"]
                },
                {
                    phrase: "You must give up @trying@.",
                    reponses: ["to try", "trying", "try"],
                },
                    {
                        phrase: "She enjoys @reading@ books in her free time.",
                        reponses: ["reading", "to read"]
                    },
                    {
                        phrase: "They avoid @eating@ junk food.",
                        reponses: ["eating", "to eat"]
                    },
                    {
                        phrase: "He suggested @taking@ the bus to work.",
                        reponses: ["taking", "to take"]
                    },
                    {
                        phrase: "I finished @working@ on the project.",
                        reponses: ["working", "to work"]
                    },
                    {
                        phrase: "We missed @seeing@ you at the party.",
                        reponses: ["seeing", "to see"]
                    },
                    {
                        phrase: "She loves @swimming@ in the ocean.",
                        reponses: ["swimming", "to swim"]
                    },
                    {
                        phrase: "I can't stop @laughing@ at that joke.",
                        reponses: ["laughing", "to laugh"]
                    },
                    {
                        phrase: "He kept @talking@ during the meeting.",
                        reponses: ["talking", "to talk"]
                    },
                    {
                        phrase: "They considered @moving@ to another city.",
                        reponses: ["moving", "to move"]
                    },
                    {
                        phrase: "I don't mind @waiting@ for you.",
                        reponses: ["waiting", "to wait"]
                    },
                    {
                        phrase: "I love @reading@ books in my free time.",
                        reponses: ["reading", "to read"]
                    },
                    {
                        phrase: "She admitted @cheating@ on the test.",
                        reponses: ["cheating", "to cheat"]
                    },
                    {
                        phrase: "They suggested @taking@ a different route.",
                        reponses: ["taking", "to take"]
                    },
                    {
                        phrase: "He can't stand @waiting@ in long lines.",
                        reponses: ["waiting", "to wait"]
                    },
                    {
                        phrase: "We enjoyed @walking@ along the beach.",
                        reponses: ["walking", "to walk"]
                    },
                    {
                        phrase: "You should @help@ your friend with the project.",
                        reponses: ["help", "to help"]
                    },
                
                
            ]
        },
        {
            id: 31,
            titre: "Les questions tag",
            niveau: 3,
            info: [
                {
                    phrase: "Do you like coffee, @don't@ you?",
                    reponses: ["don't", "do", "aren't", "isn't"] // Correct: "don't"
                },
                {
                    phrase: "She went to the park, @didn't@ she?",
                    reponses: ["did", "does", "didn't", "isn't"] // Correct: "didn't"
                },
                {
                    phrase: "You can drive, @can't@ you?",
                    reponses: ["can't", "can", "do", "don't"] // Correct: "can't"
                },
                {
                    phrase: "They weren't ready, @were@ they?",
                    reponses: ["was", "were", "weren't", "did"] // Correct: "were"
                },
                {
                    phrase: "Let's go for a walk, @shall@ we?",
                    reponses: ["shall", "will", "won't", "do"] // Correct: "shall"
                },
                {
                    phrase: "Nobody called, @did@ they?",
                    reponses: ["do", "does", "did", "didn't"] // Correct: "did"
                },
                {
                    phrase: "I am late, @aren't@ I?",
                    reponses: ["isn't", "aren't", "am", "arm"] // Correct: "aren't"
                },
                {
                    phrase: "You don't like tea, @do@ you?",
                    reponses: ["do", "does", "don't", "aren't"] // Correct: "do"
                },
                {
                    phrase: "They have finished their homework, @haven't@ they?",
                    reponses: ["haven't", "didn't", "have", "hasn't"] // Correct: "haven't"
                },
                {
                    phrase: "You went to the concert, @didn't@ you?",
                    reponses: ["did", "do", "didn't", "don't"] // Correct: "did"
                },
                {
                    phrase: "It was a great party, @wasn't@ it?",
                    reponses: ["wasn't", "didn't", "isn't", "weren't"] // Correct: "wasn't"
                },
                {
                    phrase: "I can't find my keys, @can@ you?",
                    reponses: ["can", "can't", "could", "do"] // Correct: "can"
                },
                {
                    phrase: "You speak French, @don't@ you?",
                    reponses: ["do", "don't", "does", "did"] // Correct: "don't"
                },
                {
                    phrase: "She doesn't like pizza, @does@ she?",
                    reponses: ["does", "did", "don't", "is"] // Correct: "does"
                },
                {
                    phrase: "We should leave now, @shouldn't@ we?",
                    reponses: ["shouldn't", "should", "do", "mustn't"] // Correct: "shouldn't"
                },
                {
                    phrase: "He won't be at the meeting, @will@ he?",
                    reponses: ["won't", "will", "is", "does"] // Correct: "will"
                },
                {
                    phrase: "They haven't seen the movie yet, @have@ they?",
                    reponses: ["have", "haven't", "did", "do"] // Correct: "have"
                },
                {
                    phrase: "You were working late, @weren't@ you?",
                    reponses: ["weren't", "were", "isn't", "didn't"] // Correct: "weren't"
                }
            ]
        },
     
     
      
        {
            id: 27,
            titre: "Les pronoms possessifs",
            niveau: 3,
            info: [
                {
                    phrase: "This coat is @mine@.",
                    reponses: ["my", "mine"]
                },
                {
                    phrase: "My umbrella is red. What colours is @yours@?",
                    reponses: ["your", "yours"]
                },
                {
                    phrase: "Is this Emmy's book? Yes, it's @hers@.",
                    reponses: ["her", "hers", "their"]
                },
                {
                    phrase: "That's an old friend of @ours@.",
                    reponses: ["our", "ours", "its", "it"]
                },
                {
                    phrase: "Tom: My parents are American. Tim: @Mine@ too",
                    reponses: ["Their", "Mine", "My", "Its"]
                },
                {
                    phrase: "Tom : Can I borrow your computer this weekend? Tim : No, because @mine@ has broken down.",
                    reponses: ["mine", "their", "my", "theirs"],
                },
                {
                    phrase: "This is the Smith family's house, and the garden is @theirs@.",
                    reponses: ["theirs", "our"]
                },
                {
                    phrase: "This is Jennifer's book. She told me it was @hers@, not her brother's.",
                    reponses: ["hers", "her", "their"]
                },
                {
                    phrase: "The blue jacket is mine, but the red one is definitely @his@. It's always been his favorite color.",
                    reponses: ["his", "him", "its"]
                },
                {
                    phrase: "This is a big house. Is this @yours@ or is it someone else's?",
                    reponses: ["yours", "your"]
                },
                {
                    phrase: "These are nice coats. Are these @theirs@?",
                    reponses: ["them", "their", "theirs", "his"]
                },
            ]
        },

      
        {
            id: 26,
            titre: "Le génitif",
            niveau: 3,
            info: [
                {
                    phrase: "This is @John's@ car. It's very fast.",
                    reponses: ["John's", "Johns'", "John"]
                },
                {
                    phrase: "The @children's@ toys are scattered everywhere.",
                    reponses: ["childrens'", "children's", "child's"]
                },
                {
                    phrase: "The @dog's@ leash is hanging by the door.",
                    reponses: ["dog's", "dogs'", "dog"]
                },
                {
                    phrase: "Have you seen the @students'@ classroom? It's very organized.",
                    reponses: ["students'", "student's", "students"]
                },
                {
                    phrase: "The @woman's@ handbag is on the table.",
                    reponses: ["woman's", "womans'", "women's"]
                },
                {
                    phrase: "This is the @roof of the house@.",
                    reponses: ["roof of the house", "house's roof", "houses roof"]
                },
                {
                    phrase: "The @men's@ jackets are hanging by the door.",
                    reponses: ["men's", "mens'", "man's"]
                },

                {
                    phrase: "The @cat's@ tail is very fluffy.",
                    reponses: ["cat's", "cats'", "cat"]
                },
                {
                    phrase: "The @teacher's@ explanation was very clear.",
                    reponses: ["teacher's", "teachers'", "teacher"]
                },
                {
                    phrase: "@Whose@ bag is this? It's Sarah's.",
                    reponses: ["Whose", "Who's", "Who"]
                },
                {
                    phrase: "@Whose@ phone is ringing? It’s mine.",
                    reponses: ["Whose", "Who's", "Who"]
                },
                {
                    phrase: "This is the @roof of the house@. It's newly repaired.",
                    reponses: ["roof of the house", "house's roof", "houses roof"]
                },
                {
                    phrase: "The @owner of the car@ is waiting outside.",
                    reponses: ["owner of the car", "car's owner", "cars' owner"]
                },
                {
                    phrase: "The @cover of the book@ is torn.",
                    reponses: ["cover of the book", "book's cover", "books' cover"]
                },
                {
                    phrase: "@Whose@ turn is it to clean the kitchen? It's John's.",
                    reponses: ["Whose", "Who's", "Who"]
                },
                {
                    phrase: "The @handle of the door@ is broken.",
                    reponses: ["handle of the door", "door's handle", "doors' handle"]
                },
                {
                    phrase: "@Whose@ shoes are these? They're Tom's.",
                    reponses: ["Whose", "Who's", "Who"]
                },
                {
                    phrase: "The @beauty of the city@ is in its architecture.",
                    reponses: ["beauty of the city", "city's beauty", "cities beauty"]
                },
                {
                    phrase: "The @responsibility of the manager@ is to ensure the team's success.",
                    reponses: ["responsibility of the manager", "manager's responsibility", "managers' responsibility"]
                }

            ]
        },
        
        {
            id: 41,
            titre: "Each, every, all et whole",
            niveau: 4,
            info: [
                {
                    phrase: "He gave a gift to @each@ child at the party.",
                    reponses: ["each", "every", "all"] // Correct: "each"
                },
                {
                    phrase: "@Every@ morning, she drinks a cup of coffee.",
                    reponses: ["Each", "Every", "Whole"] // Correct: "Every"
                },
                {
                    phrase: "We spent the @whole@ day cleaning the house.",
                    reponses: ["whole", "all", "every"] // Correct: "whole"
                },
                {
                    phrase: "@All@ the students in the class passed the exam.",
                    reponses: ["Each", "Every", "All"] // Correct: "All"
                },
                {
                    phrase: "She checked @each@ item on the list carefully.",
                    reponses: ["each", "all", "whole"] // Correct: "each"
                },
                {
                    phrase: "@All@ the lights in the house were turned off.",
                    reponses: ["Every", "Each", "All"] // Correct: "All"
                },
                {
                    phrase: "I stayed awake for the @whole@ night before the exam.",
                    reponses: ["whole", "all", "every"] // Correct: "whole"
                },
                {
                    phrase: "@Every@ student must bring their own materials.",
                    reponses: ["Each", "Every", "Whole"] // Correct: "Every"
                },
                {
                    phrase: "The teacher gave a different question to @each@ student.",
                    reponses: ["each", "all", "every"] // Correct: "each"
                },
                {
                    phrase: "He read @all@ the books in the library about history.",
                    reponses: ["every", "all", "whole"] // Correct: "all"
                },
                {
                    phrase: "@Each@ of the apples was carefully inspected for bruises.",
                    reponses: ["Each", "Every", "All"] // Correct: "Each"
                },
                {
                    phrase: "@Every@ corner of the house was cleaned thoroughly.",
                    reponses: ["Each", "Every", "Whole"] // Correct: "Every"
                },
                {
                    phrase: "He spent the @whole@ evening talking about his new job.",
                    reponses: ["whole", "all", "every"] // Correct: "whole"
                },
                {
                    phrase: "@All@ the members of the team agreed on the new strategy.",
                    reponses: ["Each", "Every", "All"] // Correct: "All"
                },
                {
                    phrase: "The teacher asked @each@ student to write an essay.",
                    reponses: ["each", "every", "whole"] // Correct: "each"
                },
                {
                    phrase: "I love visiting Paris in @all@ seasons of the year.",
                    reponses: ["each", "all", "every"] // Correct: "all"
                },
                {
                    phrase: "She spent the @whole@ day preparing for the exam.",
                    reponses: ["whole", "all", "every"] // Correct: "whole"
                },
                {
                    phrase: "@Every@ guest received a small gift at the end of the event.",
                    reponses: ["Each", "Every", "All"] // Correct: "Every"
                },
                {
                    phrase: "The referee checked @all@ the equipment before the match started.",
                    reponses: ["all", "whole", "each"] // Correct: "all"
                },
                {
                    phrase: "I gave a candy to @each@ child at the party.",
                    reponses: ["each", "every", "all"] // Correct: "each"
                }
            ]

        },
        {
            id: 52,
            titre: "Used to, Be Used to, Get Used to",
            niveau: 4,
            info: [
                {
                    phrase: "I @used to run@ but I gave it up when we moved to the London.",
                    reponses: ["used to run", "got used to running"]
                },
                {
                    phrase: "She doesn’t think Tom is strange. She @is used to@ him.",
                    reponses: ["used to", "is used to"]
                },
                {
                    phrase: "You come from a big city. You will never @get used to living@ in a small village",
                    reponses: ["get used to living", "used to live"]
                },
                {
                    phrase: "My sister has been a doctor for 7 years. She @is used to working@ at night.",
                    reponses: ["gets used to working", "is used to working"]
                },
                {
                    phrase: "When I was a child, I @used to play@ in the street every day.",
                    reponses: ["got used to playing", "used to play"]
                },
                {
                    phrase: "I hated this haircut at first. But I @got used to@ it.  I like it now!",
                    reponses: ["got used to", "was used to"],
                },
                {
                    phrase: "I @used to@ play football every weekend.",
                    reponses: ["used to", "am used to", "get used to", "used for"]
                },
                {
                    phrase: "She @is used to@ living alone.",
                    reponses: ["used to", "is used to", "get used to", "used for"]
                },
                {
                    phrase: "He @got used to@ the cold weather quickly.",
                    reponses: ["used to", "got used to", "is used to", "used for"]
                },
                {
                    phrase: "I @am used to@ waking up early every day.",
                    reponses: ["used to", "am used to", "get used to", "used for"]
                },
                {
                    phrase: "They @used to@ be best friends but not anymore.",
                    reponses: ["used to", "are used to", "got used to", "used for"]
                },
                {
                    phrase: "I @get used to@ driving on the left side of the road.",
                    reponses: ["used to", "am used to", "get used to", "used for"]
                },
                {
                    phrase: "She @got used to@ the new work schedule.",
                    reponses: ["used to", "is used to", "got used to", "used for"]
                },
                {
                    phrase: "We @are used to@ working long hours.",
                    reponses: ["used to", "are used to", "get used to", "used for"]
                }
            ]
        },
       
       
           
            {
                id: 50,
                titre: "Verbes à deux compléments",
                niveau : 4,
                info: [
            
                        { 
                            phrase: "She gave @him a book@.", 
                            reponses: ["him a book", "a book to him", "to him a book", "book him a to"] // Correct: "him a book"
                        },
                        { 
                            phrase: "I sent @her a letter@ yesterday.", 
                            reponses: ["her a letter", "a letter to her", "to her a letter", "letter her a to"] // Correct: "her a letter"
                        },
                        { 
                            phrase: "They showed @the teacher the homework@.", 
                            reponses: ["the teacher the homework", "homework the teacher", "the homework to the teacher", "teacher homework the the"] // Correct: "the teacher the homework"
                        },
                        { 
                            phrase: "He told @me a story@.", 
                            reponses: ["me a story", "a story me", "story a me", "to me a story"] // Correct: "me a story"
                        },
                        { 
                            phrase: "We lent @them some money@.", 
                            reponses: ["some money to them", "to them some money", "them some money", "money them to some"] // Correct: "them some money"
                        },
                        { 
                            phrase: "She bought @me a coffee@.", 
                            reponses: ["a coffee for me", "me a coffee", "for me a coffee", "coffee me a for"] // Correct: "me a coffee"
                        },
                        { 
                            phrase: "I asked @him a question@.", 
                            reponses: ["to him a question", "him a question", "a question him", "question him a to"] // Correct: "him a question"
                        },
                        { 
                            phrase: "They offered @us a discount@.", 
                            reponses: ["a discount to us", "to us a discount", "us a discount", "discount us a to"] // Correct: "us a discount"
                        },
                        { 
                            phrase: "He gave @me a ride@ to the airport.", 
                            reponses: ["me a ride", "a ride me", "to me a ride", "ride me to a"] // Correct: "me a ride"
                        },
                        { 
                            phrase: "She taught @them English@.", 
                            reponses: ["to them English", "them English", "English them to", "them to English"] // Correct: "them English"
                        },
                        { 
                            phrase: "She gave @me a gift@ for my birthday.", 
                            reponses: ["me a gift", "a gift to me", "to me a gift", "gift me a to"] // Correct: "me a gift"
                        },
                        { 
                            phrase: "They showed @us the pictures@ from their trip.", 
                            reponses: ["us the pictures", "the pictures to us", "to us the pictures", "pictures us the to"] // Correct: "us the pictures"
                        },
                        { 
                            phrase: "I told @them a secret@ last night.", 
                            reponses: ["them a secret", "a secret to them", "to them a secret", "secret them a to"] // Correct: "them a secret"
                        },
                        { 
                            phrase: "We sent @her a postcard@ from Paris.", 
                            reponses: ["her a postcard", "a postcard to her", "to her a postcard", "postcard her a to"] // Correct: "her a postcard"
                        },
                        { 
                            phrase: "He offered @me some advice@ about the project.", 
                            reponses: ["me some advice", "some advice to me", "to me some advice", "advice me some to"] // Correct: "me some advice"
                        },
                        { 
                            phrase: "I lent @them my car@ for the weekend.", 
                            reponses: ["them my car", "my car to them", "to them my car", "car them my to"] // Correct: "them my car"
                        },
                        { 
                            phrase: "She asked @me a question@ during the meeting.", 
                            reponses: ["me a question", "a question to me", "to me a question", "question me a to"] // Correct: "me a question"
                        },
                        { 
                            phrase: "We taught @the students French@ last year.", 
                            reponses: ["the students French", "French to the students", "to the students French", "students French to the"] // Correct: "the students French"
                        },
                        { 
                            phrase: "He gave @his friend a book@ as a gift.", 
                            reponses: ["his friend a book", "a book to his friend", "to his friend a book", "book his friend a to"] // Correct: "his friend a book"
                        },
                        { 
                            phrase: "They showed @me their new house@ yesterday.", 
                            reponses: ["me their new house", "their new house to me", "to me their new house", "house me their new to"] // Correct: "me their new house"
                        }
               
                ]
            },
            {
                id: 51,
                titre: "One, The Two, Both, Either, Neither",
                niveau : 4,
                info: [
                    { 
                        phrase: "I only need @one@ pen because I already have enough pencils.", 
                        reponses: ["one", "the two", "both", "either"] // Correct: "one"
                    },
                    { 
                        phrase: "The teacher asked @the two@ students who were late to explain their delay.", 
                        reponses: ["one", "the two", "both", "neither"] // Correct: "the two"
                    },
                    { 
                        phrase: "@Both@ books, the one about science and the one about history, are interesting.", 
                        reponses: ["Both", "Either", "Neither", "One"] // Correct: "Both"
                    },
                    { 
                        phrase: "You can sit on @either@ side of the table, left or right; it’s your choice.", 
                        reponses: ["either", "neither", "one", "both"] // Correct: "either"
                    },
                    { 
                        phrase: "@Neither@ of the two solutions offered by the team was acceptable for the client.", 
                        reponses: ["Neither", "Either", "Both", "One"] // Correct: "Neither"
                    },
                    { 
                        phrase: "@Either you clean your room, or I will call your mother!@", 
                        reponses: ["Either you clean your room, or I will call your mother!", "Neither you clean your room, nor I will call your mother!", "Both you clean your room, and I will call your mother!", "One you clean your room, or I will call your mother!"] // Correct: "Either you clean your room, or I will call your mother!"
                    },
                    { 
                        phrase: "She likes @neither cats nor dogs@ because she is allergic to both.", 
                        reponses: ["neither cats nor dogs", "either cats or dogs", "both cats and dogs", "the two cats and dogs"] // Correct: "neither cats nor dogs"
                    },
                    { 
                        phrase: "You can take @one of the apples@ from the basket because the others are rotten.", 
                        reponses: ["one of the apples", "both apples", "either apples", "neither apples"] // Correct: "one of the apples"
                    },
                    { 
                        phrase: "@The two@ cars in the driveway, the blue one and the red one, belong to my neighbors.", 
                        reponses: ["The two", "Both", "Neither", "One"] // Correct: "The two"
                    },
                    { 
                        phrase: "@Both@ students, Sarah and Tom, passed the test with flying colors.", 
                        reponses: ["Both", "Either", "Neither", "The two"] // Correct: "Both"
                    },
                    { 
                        phrase: "I want to read @one@ book at a time, not two.", 
                        reponses: ["one", "the two", "both", "either"] // Correct: "one"
                    },
                    { 
                        phrase: "@The two@ teachers, Mr. Smith and Mrs. Brown, will attend the meeting.", 
                        reponses: ["The two", "Both", "Neither", "Either"] // Correct: "The two"
                    },
                    { 
                        phrase: "They visited @both@ cities, Paris and London, during their vacation.", 
                        reponses: ["both", "either", "neither", "one"] // Correct: "both"
                    },
                    { 
                        phrase: "You can wear @either@ the red dress or the blue one; they both suit you.", 
                        reponses: ["either", "neither", "both", "one"] // Correct: "either"
                    },
                    { 
                        phrase: "@Neither@ of the two options seems good enough to solve the problem.", 
                        reponses: ["Neither", "Either", "Both", "One"] // Correct: "Neither"
                    },
                    { 
                        phrase: "For breakfast, you can have @either eggs or toast@, but not both.", 
                        reponses: ["either eggs or toast", "neither eggs nor toast", "both eggs and toast", "one egg and toast"] // Correct: "either eggs or toast"
                    },
                    { 
                        phrase: "@Neither my brother nor my sister@ enjoys playing video games.", 
                        reponses: ["Neither my brother nor my sister", "Either my brother or my sister", "The two, my brother and my sister"] // Correct: "Neither my brother nor my sister"
                    },
                    { 
                        phrase: "You can borrow @one of the bikes@, but please leave the other one for me.", 
                        reponses: ["one of the bikes", "both bikes", "neither bike", "either bike"] // Correct: "one of the bikes"
                    },
                    { 
                        phrase: "@Both@ players, the goalkeeper and the striker, were awarded medals for their performance.", 
                        reponses: ["Both", "Either", "Neither", "The two"] // Correct: "Both"
                    },
                    { 
                        phrase: "You can choose to go to @either the beach or the mountains@ this weekend.", 
                        reponses: ["either the beach or the mountains", "neither the beach nor the mountains", "both the beach and the mountains", "one of the beaches or mountains"] // Correct: "either the beach or the mountains"
                    }
                ]
            },
           



    ]
export default dataQcm;