import React, {Component} from 'react';
import { verifierStatus } from './Utilitaire';
import { Card, Row, Col, Avatar } from 'antd';
import laurier from '../../images/laurier.png'

import DataAccess from '../../data/DataAccess';
const { Meta } = Card;
export default class HonneurGrammaire extends Component {

    constructor(props) {
        super(props);
   
        this.state =
        {
            listePremiers : [],
        }

     
       
     
    }

    async componentDidMount() {
    
        let url = new URL(process.env.REACT_APP_URL_TABLEAUHONNEURGRAMMAIRE);

        const reponse = await fetch(url);
        if (!verifierStatus(reponse.status))
        {
            return;
        }
        if(reponse.ok) {
            const res = await reponse.json();

            this.setState({
            listePremiers : res,
        })
        }
        else 
        {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }
        
    }

  
    render()
    {
        return <div className='margeHaut30'>
            <h1 className='centre'>Le tableau d'honneur </h1>
            <p className='centre'>Tu peux retrouver les derniers participants à avoir validé un niveau complet. Bravo à eux. 👏👏👏</p>
           <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {this.state.listePremiers.map((info, i) => <Col key={i} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Card type="flex" bodyStyle={{ backgroundColor: '#a9bbff' }} >

            <Meta
              avatar={<Avatar src={laurier} />}
              title={<span className="couleurHonneur centre margeHaut10">{info.prenom.length > 18 ? info.prenom.slice(0, 18) : info.prenom}</span> }
            />
            <div className="centre">a validé le niveau</div>
           <div className="centre margeHaut10"><b>{DataAccess.transformeNiveau(info.id)}</b></div>
            <p>le {info.date}</p>
          </Card> </Col>)}
      </Row>
            </div>
            
          
    }
}