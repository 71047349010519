import React, { Component } from 'react';
import { Row, Col} from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import SousMenuJour from './SousMenuJour.js';
import {Helmet } from 'react-helmet';
import Ad from '../commun/AdSense.js';
import { withRouter } from 'react-router-dom';
import Tableau from '../../jeux/commun/Tableau.js';



 class Exercice extends Component {

  constructor()
  {
    super();

  }


  render() {
    return <div className="margeHaut">
        <Helmet>
          <title>Réviser l'anglais gratuitement</title>
          <meta name="description" content="Une série d'exercices à faire chaque jour pour réviser son anglais. Les exercices concernent la grammaire, le vocabulaire, les conjugaisons..."/>
                 </Helmet>
              
      <h1 className="titre centre">Révision anglais</h1>
     <div className="centre"><img src={border} alt="bordure"></img></div>
  <p>Nous vous proposons une série d'exercices pour réviser les bases de l'anglais. Les exercices sont répartis par groupe, vous pouvez les faire  à votre rythme. Essayez de consacrer un peu de temps chaque jour à l'apprentissage de l'anglais. Même une courte session de 15 à 30 minutes peut être bénéfique. Cela vous permettra de maintenir votre progression et de consolider vos connaissances. Il est important de garder à l'esprit que l'apprentissage d'une langue est un processus continu. La clé du succès réside dans la régularité, la persévérance et le maintien d'une pratique constante.</p>
 <p>Ces exercices de révisions sont principalement destinés à des adultes mais ils peuvent aussi être utiles à des étudiants à partir de la 3e.</p>
 <h2 className="titre centre">Le programme de révision anglais</h2>
    <div className="centre"><img src={border} alt="bordure"></img></div>
   
    <Row  className="margeHaut">
<Col md={24}>
  <SousMenuJour nom={env.nomDefi}></SousMenuJour>
</Col>
</Row>
<h2>Le programme de révision</h2>
<p>Ce programme de révision est conçu à partir de tous les exercices présents sur notre site. Il essaye de regrouper toutes les notions fondamentales de la langue anglaise avec une certaine progression. Les exercices les plus durs sont à partir du jour 20 mais la fin est plus facile.</p>
<div><b>Jour 1 - 5</b> : <a target="_blank" rel="noopener noreferrer"  href="https://www.educastream.com/fr/le-present-simple-a1">Present simple</a> - <a target="_blank" rel="noopener noreferrer" href="https://www.ispeakspokespoken.com/present-continuous/">Present continu</a> - <a  target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/temps-present-continu">Les différences</a></div>
<div><b>Jour 5 - 11</b> : <a  target="_blank" rel="noopener noreferrer" href="https://www.ef.fr/ressources-anglais/grammaire-anglaise/passe-simple/">Prétérit</a> - <a  target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/temps-present-perfect">Present Perfect</a> - <a  target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/present-perfect-progressif-continu.html">Present Perfect Progressif</a> - <a  target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/temps-plus-que-parfait">Past Perfect</a></div>
<div><b>Jour 12</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.ispeakspokespoken.com/prepositions-en-anglais/">Les prépositions (in, on, at, below...)</a></div>
<div><b>Jour 13</b> : <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/pronoms-possessifs-en-anglais">Les pronoms possessifs</a> - <a target="_blank" rel="noopener noreferrer" href="https://parlezvousanglais.fr/grammaire/liste-et-utilisation-des-adjectifs-possessifs-anglais/">Les adjectifs possessifs</a></div>
<div><b>Jour 14 - 16</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/modaux-anglais.html">Les modaux</a> - <a target="_blank" rel="noopener noreferrer" href="https://www.gymglish.com/fr/gymglish/grammaire-anglaise/must-vs-have-to">Must/Have to</a></div>
<div><b>Jour 17</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/futur-en-anglais.html">Le futur</a> - <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/futur-going-to-will.html">Will/Going to</a></div>
<div><b>Jour 18</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.letudiant.fr/etudes/tests-examens-langues/l-anglais-facile-33-cours-d-anglais-pour-progresser-a-l-ecrit/cours-d-anglais-15-les-verbes-prepositionnels-ou-a-particules-adverbiales.html">Les verbes et les prépositions</a></div>
<div><b>Jour 19</b> : <a target="_blank" rel="noopener noreferrer" href="https://fr.bab.la/grammaire/anglais/question-tags">Question tags</a> - <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/imperatif-anglais.html">L'impératif</a></div>
<div><b>Jour 20</b> : <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/vocabulaire/much-many-difference">Much/Many</a> - <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/apostrophe-s-possession">La possession</a> - <a target="_blank" rel="noopener noreferrer" href="https://www.gymglish.com/fr/gymglish/grammaire-anglaise/few-little">Few/Little</a></div>
<div><b>Jour 21</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/comparatif-anglais-superlatif-anglais.html">La comparaison</a></div>
<div><b>Jour 22</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.llceranglais.fr/le-passif.html">Le passif</a></div>
<div><b>Jour 23</b> : <a target="_blank" rel="noopener noreferrer" href="https://www.gymglish.com/fr/gymglish/grammaire-anglaise/some-et-any">Some/Any</a></div>
<div><b>Jour 24</b> : <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/used-to">To use to/To be used to/To get used to</a></div>
<div><b>Jour 25</b> : Les expressions courantes - <a target="_blank" rel="noopener noreferrer" href="https://www.anglaiscours.fr/would-comment-lutiliser.html">Conjugaison au conditionnel</a></div>
<div><b>Jour 26 - 27</b> : <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/phrasal-verbs-anglais">verbes suivis par une particule</a></div>
<div><b>Jour 28</b> : <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/either-neither-or">Either/Neither</a> - <a target="_blank" rel="noopener noreferrer" href="https://wallstreetenglish.fr/fiches-anglais/grammaire/moi-aussi-moi-non-plus-en-anglais">Moi aussi/ Moi non plus</a></div>
<div><b>Jour 29</b> : Les expressions courantes</div>
<div><b>Jour 30</b> : Les sentiments</div>
<p className="margeHaut 30">Vous pouvez effectuer  ce programme à votre rythme en 6 mois ou en un jour. Votre progression est sauvegardée.</p>
<p>Vous pouvez envoyer un commentaire sur la page d'accueil pour nous signaler un problème, une mauvaise traduction ou une amélioration.</p>
<Ad></Ad>

 <Tableau></Tableau>
<div className="centre"><img src={border} alt="bordure"></img></div>

  
    </div> 
  }
}

export default withRouter(Exercice);