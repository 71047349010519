import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import borderHonneur from '../../images/borderHonneur.png';

import DataAccess from '../../data/DataAccess';
import { Progress } from 'antd';

class Tableau extends Component {

  constructor(props) {
    super(props);
    this.state = { resultats: [] }
    //this.type = this.props.match.params.type;
    this.type = 'defi-anglais-jour';
  }

  chercherTitre = (noExo) => {

    this.defi = new DataAccess(this.type, noExo);
    if (this.defi.jeu != null) {
      let titre = this.defi.jeu.titre;
      return titre.length > 38 ? titre.slice(0, 38) + '...' : titre;
    }

    return '';

  }

  componentDidMount() {


    let url = new URL(process.env.REACT_APP_URL_TABLEAU);
    var data = new FormData();
    data.append('type', this.type);
    fetch(url, {
      method: "POST",
      body: data
    }).then(res => res.json())
      .then(res => {
        this.setState({ resultats: res });
      }
      );


  }
  render() {
    return <div className="margeHaut10" >
      <div className="centre">

        <h1>Le défi des 30 jours</h1>
        <div>Progression des autres participants</div>
     <img src={borderHonneur} alt="bordure"></img>
      </div>
      <Row className="margeHaut20">
        {this.state.resultats.map((info, i) => <Col key={i} xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 6 }} xl={{ span: 3 }}><div className='phraseAnglaisVocal'><Progress type="circle" percent={Math.round((info.noExo / 30)*100)} />
        <div className="couleurRose">{info.prenom.split('$')[0]}</div></div>
        </Col>)}
      </Row>
    </div>
  }
}
//  <Col key={i} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}><Card className="hauteurCarte">
//           <Meta
//             avatar={<Avatar src={laurier} />}
//             title={<span className="couleurHonneur">{info.prenom.length > 15 ? info.prenom.slice(0, 15) : info.prenom}</span>}
//           />
//           <p>30 jours d'anglais !</p>
//           <p>{this.chercherTitre(info.noExo)}</p>
//           <p>le {info.date}</p>
//         </Card> </Col>
export default withRouter(Tableau);
