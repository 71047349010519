let dataVocabulaire =
 [
        {
            id: 1,
            titreF: "La famille",
            titreA: "The family",
            niveau: 2,
            info: [
                {
                    a: "husband",
                    f: "mari"
                },
                {
                    a: "the bride",
                    f: "la mariée"
                },
                {
                    a: "boy",
                    f: "garçon"
                },
                {
                    a: "first name",
                    f: "prénom"
                },
                {
                    a: "last name",
                    f: "nom de famille"
                },
                {
                    a: "single",
                    f: "célibataire"
                },
                {
                    a: "daughter",
                    f: "fille",
                     indicF: "parenté"
                },
                {
                    a: "son",
                    f: "fils"
                },
                {
                    a: "the children",
                    f: "les enfants"
                },
                {
                    a: "grandmother",
                    f: "grand-mère"
                }
            ]
        },
        {
            id: 2,
            titreF: "Les fruits et légumes",
            titreA: "Fruits and vegetables",
            niveau: 2,
            info: [
                {
                    a: "green bean",
                    f: "haricot vert"
                },
                {
                    a: "cucumber",
                    f: "concombre"
                },
                {
                    a: "green pea",
                    f: "petit pois"
                },
                {
                    a: "potato",
                    f: "pomme de terre"
                },
                {
                    a: "salad",
                    f: "salade"
                },
                {
                    a: "grapefruit",
                    f: "pamplemousse"
                },
                {
                    a: "apple",
                    f: "pomme"
                },
                {
                    a: "pear",
                    f: "poire"
                },
                {
                    a: "olive",
                    f: "olive"
                },
                {
                    a: "lemon",
                    f: "citron"
                }
            ]
        },
        {
            id: 3,
            titreF: "La maison",
            titreA: "The house",
            niveau: 2,
            info: [
                {
                    a: "ceiling",
                    f: "plafond"
                },
                {
                    a: "kitchen",
                    f: "cuisine"
                },
                {
                    a: "cellar",
                    f: "cave"
                },
                {
                    a: "attic",
                    f: "grenier"
                },
                {
                    a: "bathroom",
                    f: "salle de bain"
                },
                {
                    a: "bedroom",
                    f: "chambre"
                },
                {
                    a: "balcony",
                    f: "balcon"
                },
                {
                    a: "floor",
                    f: "sol"
                },
                {
                    a: "living room",
                    f: "salon"
                },
                {
                    a: "roof",
                    f: "toit"
                }
            ]
        },
        {
            id: 4,
            titreF: "Dans la cuisine",
            titreA: "In the kitchen",
            niveau: 2,
            info: [
                {
                    a: "armchair",
                    f: "fauteuil"
                },
                {
                    a: "oven",
                    f: "four"
                },
                {
                    a: "fridge",
                    f: "réfrigérateur"
                },
                {
                    a: "sink",
                    f: "évier"
                },
                {
                    a: "cupboard",
                    f: "placard"
                },
                {
                    a: "freezer",
                    f: "congélateur"
                },
                {
                    a: "washing machine",
                    f: "machine à laver"
                },
                {
                    a: "dishwasher",
                    f: "lave-vaisselle"
                },
                {
                    a: "chair",
                    f: "chaise"
                },
                {
                    a: "washbasin",
                    f: "lavabo"
                }
            ]
        }, {
            id: 5,
            titreF: "À table",
            titreA: "it's time to eat",
            niveau: 2,
            info: [
                {
                    a: "knife",
                    f: "couteau"
                },
                {
                    a: "fork",
                    f: "fourchette"
                },
                {
                    a: "well cooked",
                    f: "bien cuit"
                },
                {
                    a: "raw meat",
                    f: "viande crue"
                },
                {
                    a: "starter",
                    f: "entrée"
                },
                {
                    a: "pastry",
                    f: "pâtisserie"
                },
                {
                    a: "napkin",
                    f: "serviette"
                },
                {
                    a: "cold cuts",
                    f: "charcuterie"
                },
                {
                    a: "breakfast",
                    f: "petit déjeuner"
                },
                {
                    a: "lunch",
                    f: "déjeuner"
                }
            ]
        }, {
            id: 6,
            titreF: "Le corps humain",
            titreA: "The human body",
            niveau: 2,
            info: [
                {
                    a: "stomach",
                    f: "estomac"
                },
                {
                    a: "back",
                    f: "dos"
                },
                {
                    a: "leg",
                    f: "jambe"
                },
                {
                    a: "arm",
                    f: "bras"
                },
                {
                    a: "belly",
                    f: "ventre"
                },
                {
                    a: "knee",
                    f: "genou"
                },
                {
                    a: "elbow",
                    f: "coude"
                },
                {
                    a: "heel",
                    f: "talon"
                },
                {
                    a: "chest",
                    f: "poitrine"
                },
                {
                    a: "shoulder",
                    f: "épaule"
                }
            ]
        },
        {
            id: 7,
            titreF: "Les vêtements",
            titreA: "The clothes",
            niveau: 2,
            info: [
                {
                    a: "trousers",
                    f: "pantalon"
                },
                {
                    a: "jacket",
                    f: "veste"
                },
                {
                    a: "coat",
                    f: "manteau"
                },
                {
                    a: "skirt",
                    f: "jupe"
                },
                {
                    a: "underwear",
                    f: "sous-vêtement"
                },
                {
                    a: "shorts",
                    f: "caleçon"
                },
                {
                    a: "wedding dress",
                    f: "robe de mariée"
                },
                {
                    a: "tie",
                    f: "cravate"
                },
                {
                    a: "bra",
                    f: "soutien-gorge"
                },
                {
                    a: "shirt",
                    f: "chemise"
                }
            ]
        }, {
            id: 8,
            titreF: "Les courses",
            titreA: "In the supermarket",
            niveau: 2,
            info: [
                {
                    a: "household products",
                    f: "produits d'entretien"
                },
                {
                    a: "washing powder",
                    f: "lessive en poudre"
                },
                {
                    a: "ready-made meal",
                    f: "plat cuisiné"
                },
                {
                    a: "organic food",
                    f: "nourriture bio"
                },
                {
                    a: "frozen food",
                    f: "produits surgelés"
                },
                {
                    a: "alcoholic beverage",
                    f: "boisson alcolisée"
                },
                {
                    a: "cosmetics",
                    f: "produits de beauté"
                },
                {
                    a: "crockery",
                    f: "vaisselle"
                },
                {
                    a: "lump sugar",
                    f: "sucre en morceaux"
                },
                {
                    a: "pasta",
                    f: "pâtes"
                }
            ]
        }, {
            id: 9,
            titreF: "La nourriture",
            titreA: "The food",
            niveau: 2,
            info: [
                {
                    a: "rice",
                    f: "riz"
                },
                {
                    a: "soft bread",
                    f: "pain de mie"
                },
                {
                    a: "ham",
                    f: "jambon"
                },
                {
                    a: "mustard",
                    f: "moutarde"
                },
                {
                    a: "rusk",
                    f: "biscotte"
                },
                {
                    a: "cottage cheese",
                    f: "fromage blanc"
                },
                {
                    a: "vegetables",
                    f: "légumes"
                },
                {
                    a: "noodles",
                    f: "nouilles"
                },
                {
                    a: "jam",
                    f: "confiture"
                },
                {
                    a: "sausage",
                    f: "saucisse"
                }
            ]
        }, {
            id: 10,
            titreF: "Dans la maison",
            titreA: "In the house",
            niveau: 2,
            info: [
                {
                    a: "chest of drawers",
                    f: "commode"
                },
                {
                    a: "closet",
                    f: "armoire"
                },
                {
                    a: "blanket",
                    f: "couverture"
                },
                {
                    a: "switch",
                    f: "interrupteur"
                },
                {
                    a: "pillow",
                    f: "oreiller"
                },
                {
                    a: "tap",
                    f: "robinet"
                },
                {
                    a: "cushion",
                    f: "coussin"
                },
                {
                    a: "sofa",
                    f: "canapé"
                },
                {
                    a: "towel",
                    f: "serviette"
                },
                {
                    a: "tea towel",
                    f: "torchon",
                    indicF: "pour la vaisselle"
                }
            ]
        }, {
            id: 11,
            titreF: "Sur scène",
            titreA: "On stage",
            niveau: 3,
            info: [
                {
                    a: "ticket",
                    f: "billet"
                },
                {
                    a: "seat",
                    f: "fauteuil"
                },
                {
                    a: "performance",
                    f: "représentation"
                },
                {
                    a: "stage",
                    f: "scène"
                },
                {
                    a: "set",
                    f: "décor"
                },
                {
                    a: "rehearsal",
                    f: "répétition"
                },
                {
                    a: "play",
                    f: "pièce de théâtre"
                },
                {
                    a: "audience",
                    f: "public",
                    indicF: "le public dans une salle"
                },
                {
                    a: "intermission",
                    f: "entracte"
                },
                {
                    a: "star",
                    f: "vedette"
                }
            ]
        },
        {
            id: 12,
            titreF: "Le crime",
            titreA: "The crime",
            niveau: 3,
            info: [
                {
                    a: "murderer",
                    f: "assassin"
                },
                {
                    a: "drowning",
                    f: "noyade"
                },
                {
                    a: "to strangle",
                    f: "étrangler"
                },
                {
                    a: "to stab",
                    f: "poignarder"
                },
                {
                    a: "to cut up a body",
                    f: "découper un corps",
                },
                {
                    a: "to slice",
                    f: "trancher",

                },
                {
                    a: "shoot",
                    f: "tir"
                },
                {
                    a: "to choke",
                    f: "étouffer"
                },
                {
                    a: "to poison",
                    f: "empoisonner"
                },
                {
                    a: "to rape",
                    f: "violer"
                }
            ]
        },
        {
            id: 13,
            titreF: "Le football",
            titreA: "The football",
            niveau: 3,
            info: [
                {
                    a: "yellow card",
                    f: "carton jaune"
                },
                {
                    a: "free kick",
                    f: "coup franc"
                },
                {
                    a: "goalkeeper",
                    f: "gardien"
                },
                {
                    a: "offside",
                    f: "hors-jeu"
                },
                {
                    a: "referee",
                    f: "arbitre",
                },
                {
                    a: "stadium",
                    f: "stade",

                },
                {
                    a: "pitch",
                    f: "terrain"
                },
                {
                    a: "lawn",
                    f: "pelouse"
                },
                {
                    a: "coach",
                    f: "entraîneur"
                },
                {
                    a: "to beat",
                    f: "battre"
                }
            ]
        },
        {
            id: 14,
            titreF: "L'entreprise",
            titreA: "The company",
            niveau: 3,
            info: [
                {
                    a: "estimate",
                    f: "devis"
                },
                {
                    a: "sales",
                    f: "ventes"
                },
                {
                    a: "invoice",
                    f: "facture"
                },
                {
                    a: "delivery",
                    f: "livraison"
                },
                {
                    a: "statement",
                    f: "déclaration",
                },
                {
                    a: "supplier",
                    f: "fournisseur",

                },
                {
                    a: "to merge",
                    f: "fusionner"
                },
                {
                    a: "good",
                    f: "marchandise"
                },
                {
                    a: "company",
                    f: "société"
                },
                {
                    a: "shareholder",
                    f: "actionnaire"
                }
            ]
        },
        {
            id: 15,
            titreF: "La ponctuation (anglais américain)",
            titreA: "Punctuation (American English)",
            niveau: 3,
            info: [
                {
                    a: "period",
                    f: "point", 
                    indicA: "dans une phrase",
                    indicF: "dans une phrase"
                },
                {
                    a: "dot",
                    f: "point",
                    indicA: "dans www.evalquiz.com",
                    indicF: "dans www.evalquiz.com"
                },
                {
                    a: "point",
                    f: "point",
                    indicA: "dans 800.098",
                    indicF: "dans 800.098"
                },
                {
                    a: "exclamation point",
                    f: "point d'exclamation"
                },
                {
                    a: "parenthesis",
                    f: "parenthèse",
                },
                {
                    a: "bracket",
                    f: "crochet",
                    indicF: '[ ]',
                    indicA: '[ ]'

                },
                {
                    a: "comma",
                    f: "virgule"
                },
                {
                    a: "colon",
                    f: "deux-points"
                },
                {
                    a: "hyphen",
                    f: "trait d'union"
                },
                {
                    a: "slash",
                    f: "barre oblique",
                    indicF: '/',
                    indicA: '/'
                }
            ]
        },
        {
            id: 16,
            titreF: "Les sentiments joyeux",
            titreA: "Happy feelings",
            niveau: 4,
            info: [
                {
                    a: "infatuated",
                    f: "épris",
                      indicF: "sous le charme"
                },
                {
                    a: "bliss",
                    f: "félicité",
                    indicF: "bonheur"
                },
                {
                    a: "madness",
                    f: "folie"
                },
                {
                    a: "pleasant",
                    f: "agréable"
                },
                {
                    a: "to enjoy",
                    f: "apprécier",
                },
                {
                    a: "eager",
                    f: "impatient"
                },
                {
                    a: "to please",
                    f: "plaire"
                },
                {
                    a: "delighted",
                    f: "ravi"
                },
                {
                    a: "affair",
                    f: "liaison",
                    indicF: "amoureuse"
                },
                {
                    a: "to cheer up",
                    f: "réconforter"
                }
            ]
        },
        {
            id: 17,
            titreF: "Les sentiments tristes 1",
            titreA: "Sad feelings 1",
            niveau: 4,
            info: [
                {
                    a: "grief",
                    f: "chagrin",
                    indicF: "deuil"
                },
                {
                    a: "staggering",
                    f: "stupéfiant",
                },
                {
                    a: "to upset",
                    f: "bouleverser"
                },
                {
                    a: "stubborn",
                    f: "têtu",
                    indicF: "obstiné"
                },
                {
                    a: "fear",
                    f: "crainte",
                },
                {
                    a: "disappointment",
                    f: "déception"
                },
                {
                    a: "scorn",
                    f: "mépris"
                },
                {
                    a: "to hate",
                    f: "détester"
                },
                {
                    a: "dreadful",
                    f: "terrible",
                    indicF: "horrible"
                },
                {
                    a: "ashamed",
                    f: "honteux"
                }
            ]
        },
        {
            id: 18,
            titreF: "Les sentiments tristes 2",
            titreA: "Sad feelings 2",
            niveau: 4,
            info: [
                {
                    a: "to cry",
                    f: "pleurer"
                },
                {
                    a: "to fear",
                    f: "redouter",
                },
                {
                    a: "to sniff",
                    f: "renifler"
                },
                {
                    a: "to sob",
                    f: "sangloter"
                },
                {
                    a: "gloomy",
                    f: "sombre",
                    indicF: "sinistre"
                },
                {
                    a: "to sigh",
                    f: "soupirer"
                },
                {
                    a: "resentment",
                    f: "ressentiment"
                },
                {
                    a: "to betray",
                    f: "trahir"
                },
                {
                    a: "to astonish",
                    f: "étonner",
                    indicF: "époustoufler"
                },
                {
                    a: "to annoy",
                    f: "contrarier",
                    indicF: "ennuyer"

                }
            ]
        }
        ,
        {
            id: 19,
            titreF: "Les qualités humaines",
            titreA: "Human quality",
            niveau: 3,
            info: [
                {
                    a: "kindness",
                    f: "gentillesse"
                },
                {
                    a: "confidence",
                    f: "confiance",
                },
                {
                    a: "humor",
                    f: "humour",
                    indicF: "orthographe US"
                },
                {
                    a: "wisdom",
                    f: "sagesse"
                },
                {
                    a: "strength",
                    f: "force",
                },
                {
                    a: "caring",
                    f: "bienveillance"
                },
                {
                    a: "contentment",
                    f: "satisfaction"
                },
                {
                    a: "fair",
                    f: "juste",
                    indicF: "honnête"
                },
                {
                    a: "optimistic",
                    f: "optimiste"
                },
                {
                    a: "clever",
                    f: "intelligent",
                    indicF: "astucieux"
                }
            ]
        },
        {
            id: 20,
            titreF: "Sentir, toucher, voir...",
            titreA: "Feel, touch, see...",
            niveau: 3,
            info: [
                {
                    a: "to whisper",
                    f: "chuchoter"
                },
                {
                    a: "to snore",
                    f: "ronfler",
                },
                {
                    a: "shrill",
                    f: "strident",
                },
                {
                    a: "nasty",
                    f: "mauvais"
                },
                {
                    a: "to stare",
                    f: "fixer",
                    indicF: "du regard"

                },
                {
                    a: "to grasp",
                    f: "étreindre",
                    indicF: "saisir"
                },
                {
                    a: "smooth",
                    f: "lisse"
                },
                {
                    a: "to squeeze",
                    f: "serrer"
                },
                {
                    a: "to scratch",
                    f: "gratter"
                },
                {
                    a: "to swallow",
                    f: "avaler"
                }
            ]
        }, 
        {
            id: 21,
            titreF: "Dans l'entreprise",
            titreA: "In the company",
            niveau: 3,
            info: [
                {
                    a: "tremendous",
                    f: "énorme",
                    indicF: "formidable"

                },
                {
                    a: "achievement",
                    f: "réalisation",
                    indicF: "succès"
                },
                {
                    a: "commitment",
                    f: "engagement",
                },
                {
                    a: "shipment",
                    f: "expédition",
                    indicF: "envoi"
                },
                {
                    a: "outstanding",
                    f: "excellent"
                },
                {
                    a: "awesome",
                    f: "fantastique",
                },
                {
                    a: "ownership",
                    f: "propriété"
                },
                {
                    a: "to involve",
                    f: "impliquer",
                    indicF: "concerner"
                },
                {
                    a: "to plan",
                    f: "prévoir"
                },
                {
                    a: "to provide",
                    f: "fournir"
                }
            ]
        }, 
        {
            id: 22,
            titreF: "A la ferme",
            titreA: "At the farm",
            niveau: 3,
            info: [
                {
                    a: "farming",
                    f: "agriculture"
                },
                {
                    a: "a barn",
                    f: "une grange"
                },
                {
                    a: "a field",
                    f: "un champ",
                },
                {
                    a: "meadow",
                    f: "pré"
                },
                {
                    a: "a crop",
                    f: "une culture",
                    indicF: "une récolte"
                },
                {
                    a: "yield",
                    f: "rendement",
                },
                {
                    a: "cattle",
                    f: "bétail"
                },
                {
                    a: "to harvest",
                    f: "récolter"
                },
                {
                    a: "to pick",
                    f: "cueillir"
                },
                {
                    a: "to sow",
                    f: "semer"
                }
            ]
        }, 
        {
            id: 23,
            titreF: "Les sciences",
            titreA: "Science",
            niveau: 3,
            info: [
                {
                    a: "a breakthrough",
                    f: "une avancée" 
                },
                {
                    a: "a patent",
                    f: "un brevet"
                },
                {
                    a: "innovative",
                    f: "innovant",
                },
                {
                    a: "efficient",
                    f: "efficace"
                },
                {
                    a: "hazardous",
                    f: "dangereux"
                },
                {
                    a: "to carry out",
                    f: "réaliser",
                    indicF: "effectuer"
                },
                {
                    a: "electrical outlet",
                    f: "prise électrique"
                },
                {
                    a: "to switch on",
                    f: "allumer"
                },
                {
                    a: "to update",
                    f: "mettre à jour"
                },
                {
                    a: "an engineer",
                    f: "un ingénieur"
                }
            ]
        },
        {
            id: 24,
            titreF: "L'usine",
            titreA: "The factory",
            niveau: 3,
            info: [
                {
                    a: "a plant",
                    f: "une usine" 
                },
                {
                    a: "a workshop",
                    f: "un atelier"
                },
                {
                    a: "a warehouse",
                    f: "un entrepôt",
                },
                {
                    a: "a manufacturer",
                    f: "un fabricant"
                },
                {
                    a: "a trade union",
                    f: "un syndicat"
                },
                {
                    a: "to process",
                    f: "traiter",
                    indicF: "transformer"
                },
                {
                    a: "to handle",
                    f: "manipuler",
                    indicF: "gérer, traiter"
                },
                {
                    a: "a strike",
                    f: "une grêve"
                },
                {
                    a: "a job interview",
                    f: "un entretien d'embauche"
                },
                {
                    a: "to hire",
                    f: "embaucher"
                }
            ]
        },
        {
            id: 25,
            titreF: "La loi et l'ordre",
            titreA: "Law and order",
            niveau: 3,
            info: [
                {
                    a: "an offence",
                    f: "une infraction" ,
                    indicF: "anglais britanniquue"
                },
                {
                    a: "burglary",
                    f: "cambriolage"
                },
                {
                    a: "riot",
                    f: "émeute",
                },
                {
                    a: "court",
                    f: "tribunal"
                },
                {
                    a: "robber",
                    f: "voleur"
                },
                {
                    a: "trial",
                    f: "procès"
                },
                {
                    a: "attorney",
                    f: "avocat"
                },
                {
                    a: "witness",
                    f: "témoin"
                },
                {
                    a: "fine",
                    f: "amende"
                },
                {
                    a: "to prosecute",
                    f: "traduire en justice"
                }
            ]
        },
        {
            id: 26,
            titreF: "Dans la salle de bain",
            titreA: "In the bathroom",
            niveau: 3,
            info: [
                {
                    a: "make-up",
                    f: "maquillage"
                },
                {
                    a: "lipstick",
                    f: "rouge à lèvres"
                },
                {
                    a: "comb",
                    f: "peigne",
                },
                {
                    a: "toilet paper",
                    f: "papier toilette"
                },
                {
                    a: "toothpaste",
                    f: "dentifrice"
                },
                {
                    a: "to shave",
                    f: "se raser",
                    indicF: "s'épiler"
                },
                {
                    a: "moisturizer",
                    f: "crème hydratante"
                },
                {
                    a: "nail clippers",
                    f: "coupe-ongles"
                },
                {
                    a: "nail polish",
                    f: "vernis à ongles"
                },
                {
                    a: "to rub",
                    f: "frotter"
                }
            ]
        },
        {
            id: 27,
            titreF: "La météo",
            titreA: "The weather",
            niveau: 3,
            info: [
                {
                    a: "fog",
                    f: "brouillard"
                },
                {
                    a: "weather forecast",
                    f: "prévision météo"
                },
                {
                    a: "wind",
                    f: "vent",
                },
                {
                    a: "hazy",
                    f: "brumeux",
                    indicF: "flou"
                },
                {
                    a: "to hail",
                    f: "grêler"
                },
                {
                    a: "a thunderstorm",
                    f: "un orage",
                },
                {
                    a: "cool",
                    f: "frais",
                    indicF: "sympa"
                },
                {
                    a: "drizzle",
                    f: "bruine"
                },
                {
                    a: "the heat",
                    f: "la chaleur"
                },
                {
                    a: "the mist",
                    f: "la brume"
                }
            ]
        },
        {
            id: 28,
            titreF: "Les jeux vidéo",
            titreA: "Video games",
            niveau: 3,
            info: [
                {
                    a: "casual gamer",
                    f: "joueur occasionnel"
                },
                {
                    a: "to cheat",
                    f: "tricher"
                },
                {
                    a: "freeware",
                    f: "logiciel gratuit",
                },
                {
                    a: "game over",
                    f: "partie terminée",
                },
                {
                    a: "roster",
                    f: "liste",
                    indicF: "répertoire"
                },
                {
                    a: "loot",
                    f: "butin",
                },
                {
                    a: "map",
                    f: "carte",
                    indicF : "pour se repérer"
                },
                {
                    a: "puzzle game",
                    f: "jeu de réflexion"
                },
                {
                    a: "to dash",
                    f: "partir en courant",
                    indicF: "jeter violemment qqch"
                },
                {
                    a: "screenshot",
                    f: "capture d'écran"
                }
            ]
        },
        {
            id: 29,
            titreF: "Les religions",
            titreA: "Religions",
            niveau: 3,
            info: [
                {
                    a: "a Jew",
                    f: "un juif"
                },
                {
                    a: "a Christian",
                    f: "un chrétien"
                },
                {
                    a: "a Muslim",
                    f: "un musulman",
                },
                {
                    a: "a prayer",
                    f: "une prière",
                },
                {
                    a: "a council",
                    f: "un conseil",
                    indicF: "un groupe de personnes"
                },
                {
                    a: "secularism",
                    f: "laïcité",
                },
                {
                    a: "an atheist",
                    f: "un athée"
                },
                {
                    a: "a church",
                    f: "une église"
                },
                {
                    a: "the faith",
                    f: "la foi"
                },
                {
                    a: "to worship",
                    f: "adorer",
                    indicF: "vénérer"
                }
            ]
        },
        {
            id: 30,
            titreF: "La musique",
            titreA: "The music",
            niveau: 3,
            info: [
                {
                    a: "a band",
                    f: "un groupe",
                    indicF : "de musique"
                },
                {
                    a: "to practice",
                    f: "pratiquer",
                    indicF : "s'entraîner"
                },
                {
                    a: "the beat",
                    f: "le rythme",
                    indicF: "le tempo"
                },
                {
                    a: "a tune",
                    f: "un air",
                    indicF: "un morceau"
                },
                {
                    a: "a keyboard",
                    f: "un clavier"
                },
                {
                    a: "soundtrack",
                    f: "bande sonore",
                },
                {
                    a: "a singer",
                    f: "un chanteur"
                },
                {
                    a: "to applaud",
                    f: "applaudir"
                },
                {
                    a: "to record",
                    f: "enregistrer"
                },
                {
                    a: "a track",
                    f: "une piste",
                    indicF: "une chanson"
                }
            ]
        },
        {
            id: 31,
            titreF: "Les ustensiles de cuisine",
            titreA: "Kitchen utensils",
            niveau: 3,
            info: [
                {
                    a: "corkscrew",
                    f: "tire-bouchon",
                },
                {
                    a: "juicer",
                    f: "presse-fruits",
                },
                {
                    a: "ladle",
                    f: "louche"
                },
                {
                    a: "rolling pin",
                    f: "rouleau à pâtisserie"
                },
                {
                    a: "tray",
                    f: "plateau"
                },
                {
                    a: "cookbook",
                    f: "livre de recettes",
                },
                {
                    a: "grater",
                    f: "râpe"
                },
                {
                    a: "blender",
                    f: "mixeur"
                },
                {
                    a: "pan",
                    f: "poêle",
                    indicF: "pour préparer des plats"
                },
                {
                    a: "pot",
                    f: "casserole"
                }
            ]
        },
        {
            id: 32,
            titreF: "Les métiers",
            titreA: "The jobs",
            niveau: 3,
            info: [
                {
                    a: "nurse",
                    f: "infirmier",
                    indicF: "infirmiere"
                },
                {
                    a: "waiter",
                    f: "serveur",
                },
                {
                    a: "butcher",
                    f: "boucher"
                },
                {
                    a: "hairdresser",
                    f: "coiffeur"
                },
                {
                    a: "cook",
                    f: "cuisinier"
                },
                {
                    a: "pharmacist",
                    f: "pharmacien",
                },
                {
                    a: "a civil servant",
                    f: "un fonctionnaire"
                },
                {
                    a: "a caretaker",
                    f: "un gardien"
                },
                {
                    a: "a mechanic",
                    f: "un mécanicien"
                },
                {
                    a: "an accountant",
                    f: "un comptable"
                }
            ]
        },
        {
            id: 33,
            titreF: "Le voyage",
            titreA: "The journey",
            niveau: 3,
            info: [
                {
                    a: "a tour",
                    f: "un voyage organisé"
                },
                {
                    a: "itinerary",
                    f: "un itinéraire",
                },
                {
                    a: "a flashlight",
                    f: "une lampe de poche"
                },
                {
                    a: "a cruise",
                    f: "une croisière"
                },
                {
                    a: "a camera",
                    f: "un appareil photo"
                },
                {
                    a: "backpack",
                    f: "sac à dos",
                },
                {
                    a: "fare",
                    f: "tarif",
                    indicF: "prix"
                },
                {
                    a: "resort",
                    f: "hôtel",
                    indicF: "complexe touristique"
                },
                {
                    a: "ticket office",
                    f: "billetterie",
                    indicF: "guichet"
                },
                {
                    a: "accommodation",
                    f: "hébergement"
                }
            ]
        },
        {
            id: 34,
            titreF: "Les verbes à particules 1",
            titreA: "Phrasal verbs 1",
            niveau: 4,
            info: [
                {
                    a: "to find out",
                    f: "découvrir",
                    indicF: "apprendre"
                },
                {
                    a: "to give up",
                    f: "abandonner",
                    indicF: "renoncer à",

                },
                {
                    a: "to break up",
                    f: "se séparer"
                },
                {
                    a: "to look forward to",
                    f: "attendre avec impatience",
                    indicF: "avoir hâte de faire qqchose"
                },
                {
                    a: "to look after",
                    f: "s'occuper de",
                    indicF: "prendre soin de"
                },
                {
                    a: "to fall apart",
                    f: "s'effondrer"
                },
                {
                    a: "to throw up",
                    f: "vomir"
                },
                {
                    a: "to blow up",
                    f: "exploser",
                },
                {
                    a: "to come up with",
                    f: "trouver",
                    indicF: "fournir"
                },
                {
                    a: "to get along",
                    f: "bien s'entendre"
                }
            ]
        },
        {
            id: 35,
            titreF: "Les verbes à particules 2",
            titreA: "Phrasal verbs 2",
            niveau: 4,
            info: [
                {
                    a: "to cheer up",
                    f: "remonter le moral"
                },
                {
                    a: "to come across",
                    f: "rencontrer",
                    indicF: "tomber sur",
                },
                {
                    a: "to drop off",
                    f: "déposer"
                },
                {
                    a: "to give in",
                    f: "céder"
                },
                {
                    a: "to go ahead",
                    f: "poursuivre"
                },
                {
                    a: "to work out",
                    f: "s'entraîner",
                    indicF: "résoudre"
                },
                {
                    a: "to pass away",
                    f: "décéder"
                },
                {
                    a: "to catch up",
                    f: "rattraper",
                },
                {
                    a: "to put off",
                    f: "reporter"
                },
                {
                    a: "to calm down",
                    f: "se calmer"
                }
            ]
        },
        {
            id: 36,
            titreF: "Internet",
            titreA: "Internet",
            niveau: 3,
            info: [
                {
                    a: "summary",
                    f: "résumé"
                },
                {
                    a: "to sign in",
                    f: "s'identifier",
                    indicF: "s'authentifier",
                },
                {
                    a: "sign up",
                    f: "s'inscrire",
                    indicF: "s'enregistrer",
                },
                {
                    a: "form",
                    f: "formulaire"
                },
                {
                    a: "to subscribe",
                    f: "s'abonner"
                },
                {
                    a: "offline",
                    f: "hors ligne",
                },
                {
                    a: "follower",
                    f: "disciple",
                    indicF :"partisan - fan"
                },
                {
                    a: "to log off",
                    f: "fermer la session",
                },
                {
                    a: "to shut down",
                    f: "éteindre"
                },
                {
                    a: "shortcut",
                    f: "raccourci"
                }
            ]
        }
        
        ,
        {
            id: 37,
            titreF: "L'hôtellerie 1",
            titreA: "The hospitality 1",
            niveau: 3,
            info: [
                {
                    a: "room",
                    f: "chambre"
                },
                {
                    a: "check-in",
                    f: "enregistrement",
        
                },
                {
                    a: "check-out",
                    f: "départ",
                
                },
                {
                    a: "bellboy",
                    f: "bagagiste"
                },
                {
                    a: "maid",
                    f: "femme de chambre"
                },
                {
                    a: "pillow",
                    f: "oreiller",
                },
                {
                    a: "bill",
                    f: "facture",
                },
                {
                    a: "key card",
                    f: "carte-clé",
                },
                {
                    a: "room service",
                    f: "service en chambre"
                },
                {
                    a: "front desk",
                    f: "bureau de réception"
                }
            ]
        }
        ,
        {
            id: 38,
            titreF: "L'hôtellerie 2",
            titreA: "The hospitality 2",
            niveau: 3,
            info: [
                {
                    a: "booking",
                    f: "réservation",
                },
                {
                    a: "room rate",
                    f: "tarif de la chambre",
        
                },
                {
                    a: "conference room",
                    f: "salle de conférence",
                
                },
                {
                    a: "elevator",
                    f: "ascenseur"
                },
                {
                    a: "valet parking",
                    f: "service voiturier"
                },
                {
                    a: "luggage",
                    f: "bagages",
                },
                {
                    a: "guest",
                    f: "client",
                },
                {
                    a: "towel",
                    f: "serviette",
                },
                {
                    a: "sheet",
                    f: "drap"
                },
                {
                    a: "vending machine",
                    f: "distributeur automatique"
                }
            ]
        },
        {
            id: 39,
            titreF: "La restauration 1",
            titreA: "Food service 1",
            niveau: 3,
            info: [
                {
                    a: "waitress",
                    f: "serveuse"
                },
                {
                    a: "chef",
                    f: "chef cuisinier",
        
                },
                {
                    a: "dish",
                    f: "plat",
                
                },
                {
                    a: "starter" ,
                    indicA: "appetizer",
                    f: "entrée"
                },
                {
                    a: "main course",
                    f: "plat principal"
                },
                {
                    a: "veggie burger",
                    f: "burger végétarien",
                },
                {
                    a: "dessert",
                    f: "dessert",
                },
                {
                    a: "beverage ",
                    f: "boisson",
                },
                {
                    a: "tip",
                    f: "pourboire"
                },
                {
                    a: "menu card",
                    f: "carte du menu"
                }
            ]
        }
        ,
        {
            id: 40,
            titreF: "La restauration 2",
            titreA: "Food service 2",
            niveau: 3,
            info: [
                {
                    a: "dish of the day",
                    f: "plat du jour"
                },
                {
                    a: "fork",
                    f: "fourchette",
        
                },
                {
                    a: "knife",
                    f: "couteau",
                
                },
                {
                    a: "rosé wine",
                    f: "vin rosé"
                },
                {
                    a: "spoon",
                    f: "cuiller"
                },
                {
                    a: "coffee spoon",
                    f: "cuillère à café",
                },
                {
                    a: "wine glass",
                    f: "verre à vin",
                },
                {
                    a: "still water",
                    f: "eau plate",
                    indicF: "en bouteille"
                },
                {
                    a: "tap water",
                    f: "eau du robinet"
                },
                {
                    a: "orange juice",
                    f: "jus d'orange"
                }
            ]
        },
        
        {
            id: 41,
            titreF: "Les aliments 1",
            titreA: "Foodstuffs 1",
            niveau: 3,
            info: [
                {
                    a: "salad",
                    f: "salade"
                },
                {
                    a: "fish",
                    f: "poisson",
        
                },
                {
                    a: "chicken",
                    f: "poulet",
                
                },
                {
                    a: "cauliflower",
                    f: "chou-fleur"
                },
                {
                    a: "shrimp",
                    f: "crevette"
                },
                {
                    a: "rice",
                    f: "riz",
                },
                {
                    a: "lobster",
                    f: "homard",
                },
                {
                    a: "mussel",
                    f: "moule"
                },
                {
                    a: "oyster",
                    f: "huître"
                },
                {
                    a: "ice cream",
                    f: "glace"
                }
            ]
        },
        
        {
            id: 42,
            titreF: "Les aliments 2",
            titreA: "Foodstuffs 2",
            niveau: 3,
            info: [
                {
                    a: "tuna",
                    f: "thon"
                },
                {
                    a: "waffle",
                    f: "gaufre",
        
                },
                {
                    a: "turkey",
                    f: "dinde",
                
                },
                {
                    a: "gravy",
                    f: "sauce"
                },
                {
                    a: "avocado",
                    f: "avocat"
                },
                {
                    a: "ham",
                    f: "jambon",
                },
                {
                    a: "pork chop",
                    f: "côte de porc",
                },
                {
                    a: "mashed potatoes",
                    f: "purée"
                },
                {
                    a: "lamb",
                    f: "agneau"
                },
                {
                    a: "sausage",
                    f: "saucisse"
                }
            ]
        },
        {
            id: 43,
            titreF: "L'environnement 1",
            titreA: "The environment 1",
            niveau: 4,
            info: [
                {
                    a: "environment",
                    f: "environnement"
                },
                {
                    a: "sustainability",
                    f: "durabilité",
        
                },
                {
                    a: "climate",
                    f: "climat",
                
                },
                {
                    a: "biodiversity",
                    f: "biodiversité"
                },
                {
                    a: "renewable",
                    f: "renouvelable"
                },
                {
                    a: "climate action",
                    f: "action climatique",
                },
                {
                    a: "global warming",
                    f: "réchauffement climatique",
                },
                {
                    a: "carbon footprint",
                    f: "empreinte carbone"
                },
                {
                    a: "waste reduction",
                    f: "réduction des déchets"
                },
                {
                    a: "eco-friendly",
                    f: "écologique"
                }
            ]
        },
        
        {
            id: 44,
            titreF: "L'environnement 2",
            titreA: "The environment 2",
            niveau: 4,
            info: [
                {
                    a: "wildlife",
                    f: "faune"
                },
                {
                    a: "hazardous waste",
                    f: "déchets dangereux",
        
                },
                {
                    a: "organic farming",
                    f: "agriculture biologique",
                
                },
                {
                    a: "eco-conscious",
                    f: "soucieux de l'environnement"
                },
                {
                    a: "organic food",
                    f: "alimentation biologique"
                },
                {
                    a: "greenhouse gases",
                    f: "gaz à effet de serre",
                },
                {
                    a: "sustainable living",
                    f: "mode de vie durable",
                },
                {
                    a: "endangered species",
                    f: "espèces en voie de disparition"
                },
                {
                    a: "biodynamic farming",
                    f: "agriculture biodynamique"
                },
                {
                    a: "clean air",
                    f: "air pur"
                }
            ]
        },
        
        {
            id: 45,
            titreF: "L'entretien d'embauche 1",
            titreA: "The interview 1",
            niveau: 4,
            info: [
                {
                    a: "interview ",
                    f: "entretien"
                },
                {
                    a: "resume",
                    indicA:  "CV",
                    f: "curriculum vitae",
        
                },
                {
                    a: "cover letter",
                    f: "lettre de motivation",
                
                },
                {
                    a: "job application",
                    f: "candidature à un emploi"
                },
                {
                    a: "employer",
                    f: "employeur"
                },
                {
                    a: "employee",
                    f: "employé",
                },
                {
                    a: "skills ",
                    f: "compétences",
                },
                {
                    a: "qualifications ",
                    f: "qualifications"
                },
                {
                    a: "hiring manager",
                    f: "responsable du recrutement"
                },
                {
                    a: "job description",
                    f: "description de poste"
                }
            ]
        },
        {
            id: 46,
            titreF: "L'entretien d'embauche 2",
            titreA: "The interview 2",
            niveau: 4,
            info: [
                {
                    a: "career",
                    f: "carrière"
                },
                {
                    a: "goal",
                    f: "objectif",
        
                },
                {
                    a: "strengths",
                    f: "points forts",
                
                },
                {
                    a: "soft skills",
                    f: "compétences relationnelles"
                },
                {
                    a: "weaknesses",
                    f: "points faibles"
                },
                {
                    a: "teamwork",
                    f: "travail d'équipe",
                },
                {
                    a: "achievement",
                    f: "réalisation",
                },
                {
                    a: "leadership",
                    f: "leadership"
                },
                {
                    a: "communication skills",
                    f: "compétences en communication"
                },
                {
                    a: "salary",
                    f: "salaire"
                }
            ]
        },
        {
            id: 47,
            titreF: "L'entretien d'embauche 3",
            titreA: "The interview 3",
            niveau: 4,
            info: [
                {
                    a: "benefits",
                    f: "avantages sociaux"
                },
                {
                    a: "resignation",
                    f: "démission",
        
                },
                {
                    a: "team",
                    f: "équipe",
                
                },
                {
                    a: "workplace",
                    f: "lieu de travail"
                },
                {
                    a: "company values",
                    f: "valeurs de l'entreprise"
                },
                {
                    a: "resume gap",
                    f: "écart dans le CV",
                },
                {
                    a: "hiring process",
                    f: "processus de recrutement",
                },
                {
                    a: "onboarding process",
                    f: "processus d'intégration"
                },
                {
                    a: "candidate assessment",
                    f: "évaluation du candidat"
                },
                {
                    a: "employment history",
                    f: "historique professionnel"
                }
            ]
        },
        {
            id: 48,
            titreF: "Ressources humaines (RH) 1",
            titreA: "Human Resources (HR) 1",
            niveau: 4,
            info: [
                {
                    a: "human resources",
                    f: "ressources humaines"
                },
                {
                    a: "recruitment",
                    f: "recrutement",
        
                },
                {
                    a: "training",
                    f: "formation",
                
                },
                {
                    a: "job application",
                    f: "candidature à un emploi"
                },
                {
                    a: "career path",
                    f: "parcours professionnel"
                },
                {
                    a: "exit interview",
                    f: "entretien de départ",
                },
                {
                    a: "salary negotiation",
                    f: "négociation de salaire",
                },
                {
                    a: "workplace culture",
                    f: "culture d'entreprise"
                },
                {
                    a: "to assess",
                    f: "évaluer"
                },
                {
                    a: "salary",
                    f: "salaire"
                }
            ]
        },
        {
            id: 49,
            titreF: "Ressources humaines (RH) 2",
            titreA: "Human Resources (HR) 2",
            niveau: 4,
            info: [
                {
                    a: "wages",
                    f: "salaire",
                    indicF: "Le salaire non fixe (travail à l'heure)"
                },
                {
                    a: "gross income",
                    f: "revenu brut",
        
                },
                {
                    a: "health insurance",
                    f: "assurance santé",
                
                },
                {
                    a: "pension plan",
                    f: "plan de retraite"
                },
                {
                    a: "performance review ",
                    f: "évaluation de performance"
                },
                {
                    a: "profit-sharing",
                    f: "participation aux bénéfices",
                },
                {
                    a: "benefits package",
                    f: "ensemble d'avantages sociaux",
                },
                {
                    a: "incentive pay",
                    f: "rémunération incitative",
                    indicF: "en fonction de la performance"
                },
                {
                    a: "salary survey ",
                    f: "enquête sur les salaires"
                },
                {
                    a: "payroll processing ",
                    f: "traitement de la paie"
                }
            ]
        },
        
        {
            "id": 50,
            "titreF": "Le secrétariat",
            "titreA": "The secretariat",
            "niveau": 2,
            "info": [
              {
                "a": "appointment",
                "f": "rendez-vous"
              },
              {
                "a": "desk",
                "f": "bureau",
                indicF: "la table"
              },
              {
                "a": "file",
                "f": "fichier"
              },
              {
                "a": "schedule",
                "f": "calendrier",
                indicF : "programme"
              },
              {
                "a": "meeting",
                "f": "réunion"
              },
              {
                "a": "printer",
                "f": "imprimante"
              },
              {
                "a": "folder",
                "f": "dossier"
              },
              {
                "a": "memo",
                "f": "note de service"
              },
              {
                "a": "notepad",
                "f": "bloc-notes"
              },
              {
                "a": "conference call",
                "f": "conférence téléphonique"
              },
            ]
          }
        
        ,
        {
            "id": 51,
            "titreF": "Vocabulaire de base 1",
            "titreA": "Basic vocabulary 1",
            "niveau": 1,
            "info": [
              {
                "a": "hello",
                "f": "bonjour"
              },
              {
                "a": "goodbye",
                "f": "au revoir"
              },
              {
                "a": "please",
                "f": "s'il vous plaît"
              },
              {
                "a": "thank you",
                "f": "merci"
              },
              {
                "a": "yes",
                "f": "oui"
              },
              {
                "a": "no",
                "f": "non"
              },
              {
                "a": "excuse me",
                "f": "excusez-moi"
              },
              {
                "a": "sorry",
                "f": "désolé"
              },
              {
                "a": "movie",
                "f": "film"
              },
              {
                "a": "water",
                "f": "eau"
              }
            ]
          },
          {
            "id": 52,
            "titreF": "Vocabulaire de base 2",
            "titreA": "Basic vocabulary 2",
            "niveau": 1,
            "info": [
              {
                "a": "friend",
                "f": "ami"
              },
              {
                "a": "food",
                "f": "nourriture"
              },
              {
                "a": "house",
                "f": "maison"
              },
              {
                "a": "family",
                "f": "famille"
              },
              {
                "a": "school",
                "f": "école"
              },
              {
                "a": "book",
                "f": "livre"
              },
              {
                "a": "car",
                "f": "voiture"
              },
              {
                "a": "dog",
                "f": "chien"
              },
              {
                "a": "cat",
                "f": "chat"
              },
              {
                "a": "sun",
                "f": "soleil"
              }
            ]
          },
          {
            "id": 53,
            "titreF": "Vocabulaire de base 3",
            "titreA": "Basic vocabulary 3",
            "niveau": 1,
            "info": [
              {
                "a": "day",
                "f": "jour"
              },
              {
                "a": "night",
                "f": "nuit"
              },
              {
                "a": "morning",
                "f": "matin"
              },
              {
                "a": "evening",
                "f": "soir"
              },
              {
                "a": "happy",
                "f": "heureux"
              },
              {
                "a": "sad",
                "f": "triste"
              },
              {
                "a": "big",
                "f": "grand"
              },
              {
                "a": "small",
                "f": "petit"
              },
              {
                "a": "hot",
                "f": "chaud"
              },
              {
                "a": "cold",
                "f": "froid"
              }
            ]
          },
          {
            "id": 54,
            "titreF": "Vocabulaire de base 4",
            "titreA": "Basic vocabulary 4",
            "niveau": 1,
            "info": [
              {
                "a": "apple",
                "f": "pomme"
              },
              {
                "a": "table",
                "f": "table"
              },
              {
                "a": "chair",
                "f": "chaise"
              },
              {
                "a": "window",
                "f": "fenêtre"
              },
              {
                "a": "door",
                "f": "porte"
              },
              {
                "a": "city",
                "f": "ville"
              },
              {
                "a": "country",
                "f": "pays"
              },
              {
                "a": "street",
                "f": "rue"
              },
              {
                "a": "room",
                "f": "pièce"
              },
              {
                "a": "bed",
                "f": "lit"
              }
            ]
          },
          {
            "id": 55,
            "titreF": "L'aéroport",
            "titreA": "The airport",
            "niveau": 3,
            "info": [
                {
                    "a": "carry-on luggage",
                    "f": "bagage à main"
                  },
              {
                "a": "boarding pass",
                "f": "carte d'embarquement"
              },
              {
                "a": "gate",
                "f": "porte"
              },
              {
                "a": "security check",
                "f": "contrôle de sécurité"
              },
              {
                "a": "flight number",
                "f": "numéro de vol"
              },
              {
                "a": "departure",
                "f": "départ"
              },
              {
                "a": "arrival",
                "f": "arrivée"
              },
              {
                "a": "baggage claim",
                "f": "récupération des bagages"
              },
              {
                "a": "customs",
                "f": "douanes"
              },
              {
                "a": "flight delay",
                "f": "retard de vol"
              }
            ]
          },
          {
            "id": 56,
            "titreF": "Hobbies et loisirs",
            "titreA": "Leisure activities",
            "niveau": 2,
            "info": [
              {
                "a": "reading",
                "f": "lecture"
              },
              {
                "a": "painting",
                "f": "peinture"
              },
              {
                "a": "cooking",
                "f": "cuisine"
              },
              {
                "a": "gardening",
                "f": "jardinage"
              },
              {
                "a": "photography",
                "f": "photographie"
              },
              {
                "a": "hiking",
                "f": "randonnée"
              },
              {
                "a": "knitting",
                "f": "tricot"
              },
              {
                "a": "fishing",
                "f": "pêche"
              },
              {
                "a": "cycling",
                "f": "cyclisme"
              },
              {
                "a": "playing chess",
                "f": "jouer aux échecs"
              }
            ]
          },
          {
            "id": 57,
            "titreF": "Le jardinage",
            "titreA": "The gardening",
            "niveau": 4,
            "info": [
              {
                "a": "shovel",
                "f": "pelle"
              },
              {
                "a": "trowel",
                "f": "truelle"
              },
              {
                "a": "pruning shears",
                "f": "sécateur"
              },
              {
                "a": "watering can",
                "f": "arrosoir"
              },
              {
                "a": "rake",
                "f": "râteau"
              },
              {
                "a": "weed",
                "f": "mauvaise herbe"
              },
              {
                "a": "spade",
                "f": "bêche"
              },
              {
                "a": "lawn mower",
                "f": "tondeuse à gazon"
              },
              {
                "a": "seed",
                "f": "graine"
              },
              {
                "a": "fertilizer",
                "f": "engrais"
              },
              {
                "a": "plant",
                "f": "plante"
              },
              {
                "a": "flower bed",
                "f": "parterre de fleurs"
              },
              {
                "a": "greenhouse",
                "f": "serre"
              },
              {
                "a": "compost bin",
                "f": "bac à compost"
              },
              {
                "a": "soil",
                "f": "terre"
              }
            ]
          },
          {
            "id": 58,
            "titreF": "Vocabulaire de base 5",
            "titreA": "Basic vocabulary 5",
            "niveau": 1,
            "info": [
                {
                    "a": "train",
                    "f": "train"
                },
                {
                    "a": "bike",
                    "f": "vélo"
                },
                {
                    "a": "notebook",
                    "f": "cahier"
                },
                {
                    "a": "ball",
                    "f": "balle"
                },
                {
                    "a": "pencil",
                    "f": "crayon"
                },
                {
                    "a": "pen",
                    "f": "stylo"
                },
                {
                    "a": "clock",
                    "f": "horloge"
                },
                {
                    "a": "watch",
                    "f": "montre"
                },
                {
                    "a": "glasses",
                    "f": "lunettes"
                },
                {
                    "a": "moon",
                    "f": "lune"
                }
               
            ]
        },
        {
            "id": 59,
            "titreF": "Vocabulaire de base 6",
            "titreA": "Basic vocabulary 6",
            "niveau": 1,
            "info": [
              
                {
                    "a": "rain",
                    "f": "pluie"
                },
                {
                    "a": "wall",
                    "f": "mur"
                },
                {
                    "a": "bird",
                    "f": "oiseau"
                },
                {
                    "a": "fence",
                    "f": "clôture"
                },
                {
                    "a": "tree",
                    "f": "arbre"
                },
                {
                    "a": "flower",
                    "f": "fleur"
                },
                {
                    "a": "milk",
                    "f": "lait"
                },
                {
                    "a": "cloud",
                    "f": "nuage"
                },
                {
                    "a": "hat",
                    "f": "chapeau"
                },
                {
                    "a": "shoe",
                    "f": "chaussure"
                }
            ]
        },
        {
            "id": 60,
            "titreF": "Vocabulaire de base 7",
            "titreA": "Basic vocabulary 7",
            "niveau": 1,
            "info": [
              
                {
                    "a": "sky",
                    "f": "ciel"
                },
                {
                    "a": "forest",
                    "f": "forêt"
                },
                {
                    "a": "beach",
                    "f": "plage"
                },
                {
                    "a": "desert",
                    "f": "désert"
                },
                {
                    "a": "river",
                    "f": "rivière"
                },
                {
                    "a": "star",
                    "f": "étoile"
                },
                {
                    "a": "smile",
                    "f": "sourire"
                },
                {
                    "a": "bridge",
                    "f": "pont"
                },
                {
                    "a": "horse",
                    "f": "cheval"
                },
                {
                    "a": "mirror",
                    "f": "miroir"
                }
            ]
        },
        {
            "id": 61,
            "titreF": "Vocabulaire de base 8",
            "titreA": "Basic vocabulary 8",
            "niveau": 1,
            "info": [
                {
                    "a": "teacher",
                    "f": "professeur"
                },
                {
                    "a": "student",
                    "f": "élève"
                },
                {
                    "a": "classroom",
                    "f": "salle de classe"
                },
                {
                    "a": "blackboard",
                    "f": "tableau noir"
                },
                {
                    "a": "homework",
                    "f": "devoirs"
                },
                {
                    "a": "lesson",
                    "f": "leçon"
                },
                
                {
                    "a": "eraser",
                    "f": "gomme"
                },
                {
                    "a": "scissors",
                    "f": "ciseaux"
                },
                {
                    "a": "schoolbag",
                    "f": "cartable"
                },
                {
                    "a": "playground",
                    "f": "cour de récréation"
                }
            ]
        },
        {
            "id": 62,
            "titreF": "Vocabulaire de base 9",
            "titreA": "Basic vocabulary 9",
            "niveau": 1,
            "info": [
                {
                    "a": "monday",
                    "f": "lundi"
                },
                {
                    "a": "tuesday",
                    "f": "mardi"
                },
                {
                    "a": "wednesday",
                    "f": "mercredi"
                },
                {
                    "a": "thursday",
                    "f": "jeudi"
                },
                {
                    "a": "friday",
                    "f": "vendredi"
                },
                {
                    "a": "saturday",
                    "f": "samedi"
                },
                {
                    "a": "sunday",
                    "f": "dimanche"
                },
                {
                    "a": "week",
                    "f": "semaine"
                },
                {
                    "a": "month",
                    "f": "mois"
                },
                {
                    "a": "year",
                    "f": "année"
                },
            ]
        },
        {
            "id": 63,
            "titreF": "Vocabulaire de base 10",
            "titreA": "Basic vocabulary 10",
            "niveau": 1,
            "info": [
               
                {
                    "a": "season",
                    "f": "saison"
                },
              
                {
                    "a": "spring",
                    "f": "printemps"
                },
                {
                    "a": "summer",
                    "f": "été"
                },
                {
                    "a": "autumn",
                    "f": "automne"
                },
                {
                    "a": "winter",
                    "f": "hiver"
                },
                {
                    "a": "midnight",
                    "f": "minuit"
                },
                {
                    "a": "noon",
                    "f": "midi"
                },
              
                {
                    "a": "afternoon",
                    "f": "après-midi"
                },
                {
                    "a": "yesterday",
                    "f": "hier"
                },
                {
                    "a": "tomorrow",
                    "f": "demain"
                },
               
            ]
        }
        

          
          
          
          
          
          
          
        
        



    ]

    



export default dataVocabulaire;
