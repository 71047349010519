import React, { Component } from 'react';
import { Row, Col} from 'antd';
import { withRouter } from 'react-router-dom';
import border from '../../images/border.png';
import Ad from '../commun/AdSense';
import { Link } from 'react-router-dom';


 function Liens() {

    return <div className="margeHaut">
    <h2 className="titre centre">Tous nos exercices</h2>
    <Row className="margeHaut">
    <Col xs={12}><a href="/niveaux"><div className="tuile">tests de niveau </div></a></Col>
    <Col xs={12}><a href="/evaluation"><div className="tuile">les évaluations (evalang)</div></a></Col>
    <Col xs={12}><a href="/blog/"><div className="tuile">les leçons</div></a></Col>
    {/* <Col xs={12}><Link to="/exercice"><div className="tuile">révision anglais</div></Link></Col> */}
     <Col xs={12}><Link to="/verbes"><div className="tuile">verbes irréguliers</div></Link></Col>
    <Col xs={12}><Link to="/test-vocabulaire"><div className="tuile">vocabulaire anglais et français</div></Link></Col>
    <Col xs={12}><Link to="/test-phrases"><div className="tuile">phrases en anglais et français</div></Link></Col>
    <Col xs={12}><Link to="/test-grammaire"><div className="tuile">quiz de grammaire anglaise 🆕</div></Link></Col>
    <Col xs={12}><Link to="/alphabet"><div className="tuile">l'alphabet anglais </div></Link></Col>
    <Col xs={12}><Link to="/test-parler"><div className="tuile">parler en anglais</div></Link></Col>
    <Col xs={12}><Link to="/test-ecoute"><div className="tuile">écouter de l'anglais</div></Link></Col>
    <Col xs={12}><Link to="/divers"><div className="tuile">divers</div></Link></Col>
    </Row>


    




<div className="centre"><img src={border} alt="bordure"></img></div>
</div>

  
}

export default withRouter(Liens);