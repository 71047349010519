let dataListeMots =
 [
        {
            id: 1,
            titre: "Des mots simples 1",
            niveau: 1,
            temps: 180,
            liste: [
                {
                    definition : "Precious metal",
                    mot: "gold",
                    
                },
                {
                    definition: "Great ocean predator",
                    mot: "shark",
                },
                {
                    definition: "This month starts right after December 31",
                    mot: "january",
                    
                },
                {
                    definition : "A speech that is open to the public",
                    mot: "lecture",
                    
                },
                {
                    definition: "labyrinth",
                    mot: "maze",
                },
                {
                    definition: "A tall plant having a main trunk and branches.",
                    mot: "tree",
                    
                }
            ]
        },
        {
            id: 2,
            titre: "Des mots simples 2",
            niveau: 1,
            temps:180,
            liste: [
                {
                    definition : "Source of wool",
                    mot: "sheep",
                    
                },
                {
                    definition: "Make up 21% of the atmosphere",
                    mot: "oxygen",
                },
                {
                    definition: "Food made from flour, water and usually yeast mixed together and baked",
                    mot: "bread",
                    
                },
                {
                    definition : "What falls from a tree in fall",
                    mot: "leaves",
                    
                },
                {
                    definition: "Man’s best friend",
                    mot: "dog",
                },
                {
                    definition: "Humorous anecdote or remark intended to provoke laughter ",
                    mot: "joke",
                    
                }
            ]
        },
        {
            id: 3,
            titre: "Des mots à retrouver",
            niveau: 3,
            temps: 180,
            liste: [
                {
                    definition : "When you go to a pub you can order a fish and …",
                    mot: "chips",
                    
                },
                {
                    definition: "A small lake",
                    mot: "pond",
                },
                {
                    definition: "The slope beside a body of water",
                    mot: "bank",
                    
                },
                {
                    definition : "Striped animal that looks like a horse",
                    mot: "zebra",
                    
                },
                {
                    definition: "A person who freely offers to take part in an enterprise or undertake a task",
                    mot: "volunteer",
                },
                {
                    definition: "a sandwich consisting of beef served on a bun",
                    mot: "hamburger",
                    
                }
            ]
        },
        {
            id: 4,
            titre: "Des mots à retrouver 2",
            niveau: 2,
            temps: 180,
            liste: [
                {
                    definition : "It travels on a track",
                    mot: "train",
                    
                },
                {
                    definition: "A colorless, transparent, odorless, tasteless liquid that forms the seas, lakes, rivers, and rain.",
                    mot: "water",
                },
                {
                    definition: "The planet on which we live ",
                    mot: "earth",
                    
                },
                {
                    definition : "This animal has a very long neck",
                    mot: "giraffe",
                    
                },
                {
                    definition: "The last month of autumn",
                    mot: "november",
                },
                {
                    definition: "Likes to store and eat nuts",
                    mot: "squirrel",
                    
                }
            ]
        },
        {
            id: 5,
            titre: "Des mots à retrouver 3",
            niveau: 2,
            temps: 180,
            liste: [
                {
                    definition : "Red fluid inside the body",
                    mot: "blood",
                    
                },
                {
                    definition: "Containing nothing",
                    mot: "empty",
                },
                {
                    definition: "It comes out of the tap",
                    mot: "water",
                    
                },
                {
                    definition : "It is worn around the waist",
                    mot: "belt",
                    
                },
                {
                    definition: "Permitted to do",
                    mot: "allowed",
                },
                {
                    definition: "It is worn around the wrist",
                    mot: "bracelet",
                    
                }
            ]
        }
    ]


export default dataListeMots;
