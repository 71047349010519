let dataEcoute =
[
        {
            id: 7,
            titre: "To be",
            niveau: 1,
            info: [
                {
                    audio: "audio71.mp3",
                    a: "she's happy.",
                    f: "elle est heureuse."
                },
                {
                    audio: "audio72.mp3",
                    a: "he's sorry.",
                    f: "il est désolé."
                },
                {
                    audio: "audio73.mp3",
                    a: "why is she alone?",
                    f: "pourquoi est-elle seule ?"
                },
                {
                    audio: "audio74.mp3",
                    a: "she's pretty.",
                    f: "elle est jolie."
                },
                {
                    audio: "audio75.mp3",
                    a: "you aren't busy today.",
                    f: "vous n'êtes pas occupé aujourd'hui."
                }
            ]
        },
        {
            id: 8,
            titre: "To have",
            niveau: 1,
            info: [
                {
                    audio: "audio81.mp3",
                    a: "they have no money.",
                    f: "ils n'ont pas d'argent."
                },
                {
                    audio: "audio82.mp3",
                    a: "she has an idea.",
                    f: "elle a une idée."
                },
                {
                    audio: "audio83.mp3",
                    a: "does she have a dog?",
                    f: "a-t-elle un chien ?"
                },
                {
                    audio: "audio84.mp3",
                    a: "he has a cold.",
                    f: "il a un rhume."
                },
                {
                    audio: "audio85.mp3",
                    a: "she doesn't have a baby.",
                    f: "elle n'a pas de bébé."
                }
            ]
        }
        , {
            id: 9,
            titre: "Présent",
            niveau: 1,
            info: [
                {
                    audio: "audio91.mp3",
                    a: "we're leaving next week.",
                    f: "nous partons la semaine prochaine."
                },
                {
                    audio: "audio92.mp3",
                    a: "do you drink beer?",
                    f: "buvez-vous de la bière ?"
                },
                {
                    audio: "audio93.mp3",
                    a: "now what are you waiting for?",
                    f: "qu'attendez-vous maintenant ?"
                },
                {
                    audio: "audio94.mp3",
                    a: "they never work on Sunday.",
                    f: "ils ne travaillent jamais le dimanche."
                },
                {
                    audio: "audio95.mp3",
                    a: "they pray every day.",
                    f: "ils prient tous les jours."
                }
            ]
        }
        , {
            id: 10,
            titre: "Passé",
            niveau: 2,
            info: [
                {
                    audio: "audio101.mp3",
                    a: "they bought a ticket to the show.",
                    f: "ils ont acheté un billet pour le spectacle."
                },
                {
                    audio: "audio102.mp3",
                    a: "I booked a table in a restaurant.",
                    f: "j'ai réservé une table dans un restaurant."
                },
                {
                    audio: "audio103.mp3",
                    a: "I was reading when I received the email.",
                    f: "J'étais en train de lire quand j'ai reçu le courriel."
                },
                {
                    audio: "audio104.mp3",
                    a: "3 million people have already visited the museum.",
                    f: "3 millions de personnes ont déjà visité le musée."
                },
                {
                    audio: "audio105.mp3",
                    a: "a plane crashed in the middle of a neighborhood.",
                    f: "un avion s'est écrasé au milieu d'un quartier."
                }
            ]
        },  {
            id: 11,
            titre: "Futur",
            niveau: 2,
            info: [
                {
                    audio: "audio111.mp3",
                    a: "he won't be a football player.",
                    f: "il ne sera pas footballeur."
                },
                {
                    audio: "audio112.mp3",
                    a: "I won't exercise today.",
                    f: "je ne ferai pas d'exercice aujourd'hui."
                },
                {
                    audio: "audio113.mp3",
                    a: "he won't get angry.",
                    f: "il ne se mettra pas en colère."
                },
                {
                    audio: "audio114.mp3",
                    a: "shall we dance?",
                    f: "voulez-vous danser ?"
                },
                {
                    audio: "audio115.mp3",
                    a: "they won't yell at you.",
                    f: "ils ne vous crieront pas dessus."
                }
            ]
        }, {
            id: 12,
            titre: "Donner son avis",
            niveau: 3,
            info: [
                {
                    audio: "audio121.mp3",
                    a: "I think she's nice.",
                    f: "je la trouve gentille."
                },
                {
                    audio: "audio122.mp3",
                    a: "I believe I can do anything.",
                    f: "je crois que je peux tout faire."
                },
                {
                    audio: "audio123.mp3",
                    a: "she feels that her boss is unfair.",
                    f: "elle estime que son patron est injuste."
                },
                {
                    audio: "audio124.mp3",
                    a: "in my opinion it isn't a good idea.",
                    f: "à mon avis ce n'est pas une bonne idée."
                },
                {
                    audio: "audio125.mp3",
                    a: "as far as I'm concerned there's nothing to talk about.",
                    f: "en ce qui me concerne il n'y a rien à dire."
                }
            ]
        
        },
        {
            id: 13,
            titre: "Proposer des idées",
            niveau: 3,
            info: [
                {
                    audio: "audio131.mp3",
                    a: "we should watch that movie.",
                    f: "nous devrions regarder ce film."
                },
                {
                    audio: "audio132.mp3",
                    a: "she could try again tomorrow.",
                    f: "elle pourrait réessayer demain."
                },
                {
                    audio: "audio133.mp3",
                    a: "how about going to a movie tonight?",
                    f: "et si on allait voir un film ce soir ?"
                },
                {
                    audio: "audio134.mp3",
                    a: "I suggest that you study more.",
                    f: "je vous suggère d'étudier davantage."
                },
                {
                    audio: "audio135.mp3",
                    a: "you need to upgrade your skills.",
                    f: "vous devez améliorer vos compétences."
                }
            ]
        
        }
        ,
        {
            id: 1,
            titre: "Des adjectifs",
            niveau: 3,
            info: [
                {
                    audio: "audio11.mp3",
                    a: "it's a funny book.",
                    f: "c'est un livre drôle."
                },
                {
                    audio: "audio12.mp3",
                    a: "yesterday I read an amazing story in a magazine.",
                    f: "hier j'ai lu une histoire incroyable dans un magazine."
                },
                {
                    audio: "audio13.mp3",
                    a: "Tom has a boring job.",
                    f: "Tom a un travail ennuyeux."
                },
                {
                    audio: "audio14.mp3",
                    a: "it was cold and windy.",
                    f: "il faisait froid et il y avait du vent."
                },
                {
                    audio: "audio15.mp3",
                    a: "the scientist was aware of the dangers.",
                    f: "le scientifique était conscient des dangers."
                }
            ]
        }, {
            id: 2,
            titre: "Les comparaisons",
            niveau: 3,
            info: [
                {
                    audio: "audio21.mp3",
                    a: "you're as tall as your sister.",
                    f: "vous êtes aussi grand que votre soeur."
                },
                {
                    audio: "audio22.mp3",
                    a: "I was as naughty as I am now.",
                    f: "j'étais aussi vilain que je le suis maintenant."
                },
                {
                    audio: "audio23.mp3",
                    a: "I earn twice as much as you.",
                    f: "je gagne deux fois plus que vous."
                },
                {
                    audio: "audio24.mp3",
                    a: "Paris is bigger than Liverpool.",
                    f: "Paris est plus grand que Liverpool."
                },
                {
                    audio: "audio25.mp3",
                    a: "the most attractive women are those who are short.",
                    f: "les femmes les plus séduisantes sont celles qui sont petites."
                }
            ]
        },
        {
            id: 4,
            titre: "La réunion de travail",
            niveau: 3,
            info: [
                {
                    audio: "audio41.mp3",
                    a: "I'd like to welcome everyone.",
                    f: "j'aimerais souhaiter la bienvenue à tous."
                },
                {
                    audio: "audio42.mp3",
                    a: "let's go around the table and introduce ourselves.",
                    f: "faisons un tour de table et présentons-nous."
                },
                {
                    audio: "audio43.mp3",
                    a: "I'd like to have your report by Tuesday.",
                    f: "j'aimerais avoir votre rapport d'ici mardi."
                },
                {
                    audio: "audio44.mp3",
                    a: "I suggest we set up another meeting.",
                    f: "je suggère que nous organisions une autre réunion."
                },
                {
                    audio: "audio45.mp3",
                    a: "do I have to attend the meeting?",
                    f: "dois-je assister à la réunion ?"
                }
            ]
        }
       ,
        {
            id: 5,
            titre: "Au travail",
            niveau: 3,
            info: [
                {
                    audio: "audio51.mp3",
                    a: "we need to look at this in more detail.",
                    f: "nous devons examiner cela plus en détail."
                },
                {
                    audio: "audio52.mp3",
                    a: "let's go over what we've agreed.",
                    f: "revoyons ce dont nous avons convenu."
                },
                {
                    audio: "audio53.mp3",
                    a: "I'm not sure that's relevant.",
                    f: "je ne suis pas sûr que cela soit pertinent."
                },
                {
                    audio: "audio54.mp3",
                    a: "can you send me the invoice?",
                    f: "pouvez-vous m'envoyer la facture ?"
                },
                {
                    audio: "audio55.mp3",
                    a: "how do I join a scheduled meeting in zoom?",
                    f: "comment puis-je participer à une réunion programmée dans zoom ?"
                }
            ]
        }, 
        {
            id: 6,
            titre: "L'entretien d'embauche",
            niveau: 3,
            info: [
                {
                    audio: "audio61.mp3",
                    a: "why were you fired?",
                    f: "pourquoi avez-vous été licencié ?"
                },
                {
                    audio: "audio62.mp3",
                    a: "why are you leaving your current job?",
                    f: "pourquoi quittez-vous votre emploi actuel ?"
                },
                {
                    audio: "audio63.mp3",
                    a: "what are your greatest strengths?",
                    f: "quels sont vos plus grands atouts ?"
                },
                {
                    audio: "audio64.mp3",
                    a: "what do you consider to be your weaknesses?",
                    f: "que considérez-vous comme vos faiblesses ?"
                },
                {
                    audio: "audio65.mp3",
                    a: "where do you see yourself in 5 years?",
                    f: "où vous voyez-vous dans 5 ans ?"
                }
            ]
        },
        {
            id: 3,
            titre: "Le féminisme",
            niveau: 4,
            info: [
                {
                    audio: "audio31.mp3",
                    a: "women must learn to play the game as men do.",
                    f: "les femmes doivent apprendre à jouer le jeu comme les hommes."
                },
                {
                    audio: "audio32.mp3",
                    a: "a woman without a man is like a fish without a bicycle.",
                    f: "une femme sans homme est comme un poisson sans vélo."
                },
                {
                    audio: "audio33.mp3",
                    a: "I hate men who are afraid of women's strength.",
                    f: "je déteste les hommes qui ont peur de la force des femmes."
                },
                {
                    audio: "audio34.mp3",
                    a: "there's a place in hell for women who don't help other women.",
                    f: "il y a une place en enfer pour les femmes qui n'aident pas les autres femmes."
                },
                {
                    audio: "audio35.mp3",
                    a: "nobody knows what happened to her.",
                    f: "personne ne sait ce qui lui est arrivé."
                }
            ]
        },
        {
            id: 15,
            titre: "Le vocabulaire de l'hôtellerie",
            niveau: 2,
            info: [
                {
                    audio: "audio200.mp3",
                    a:  "check-out time is at 11 am.",
                    f: "l'heure de départ est à 11 heures."
                },
                {
                    audio: "audio201.mp3",
                    a: "breakfast is included with your stay.",
                    f: "le petit-déjeuner est inclus dans votre séjour."
                },
                {
                    audio: "audio202.mp3",
                    a: "we have an on-site restaurant.",
                    f: "nous avons un restaurant sur place."
                },
                {
                    audio: "audio203.mp3",
                    a: "I will send up some extra towels to your room.",
                    f: "je vais envoyer des serviettes supplémentaires dans votre chambre."
                },
                {
                    audio: "audio204.mp3",
                    a: "I can arrange a late check-out for you.",
                    f: "Je peux organiser un départ tardif."
                }
            ]
        },
        {
            id: 16,
            titre: "Le vocabulaire de la restauration",
            niveau: 2,
            info: [
                {
                    audio: "audio210.mp3",
                    a:  "can I see the menu?",
                    f: "puis-je voir la carte ?"
                },
                {
                    audio: "audio211.mp3",
                    a: "can we get a table for two?",
                    f: "pouvons-nous avoir une table pour deux ?"
                },
                {
                    audio: "audio212.mp3",
                    a: "I'll have the chef's special.",
                    f: "je prendrai la spécialité du chef."
                },
                {
                    audio: "audio213.mp3",
                    a: "I'd like my steak well-done.",
                    f: "je voudrais mon steak bien cuit."
                },
                {
                    audio: "audio214.mp3",
                    a: "can we get separate checks?",
                    f: "pouvons-nous avoir des additions séparées ?"
                }
            ]
        },
        {
            id: 17,
            titre: "Le vocabulaire de l'environnement",
            niveau: 3,
            info: [
                {
                    audio: "audio220.mp3",
                    a:  "climate change is a global issue.",
                    f: "le changement climatique est un problème mondial."
                },
                {
                    audio: "audio221.mp3",
                    a: "we need to protect our natural resources.",
                    f: "nous devons protéger nos ressources naturelles."
                },
                {
                    audio: "audio222.mp3",
                    a: "renewable energy sources are the future.",
                    f: "les sources d'énergie renouvelable sont l'avenir."
                },
                {
                    audio: "audio223.mp3",
                    a: "we must reduce our carbon footprint.",
                    f: "nous devons réduire notre empreinte carbone."
                },
                {
                    audio: "audio224.mp3",
                    a: "biodiversity is crucial for ecosystems.",
                    f: "la biodiversité est cruciale pour les écosystèmes."
                }
            ]
        },
        {
            id: 18,
            titre: "L'entretien d'embauche II",
            niveau: 3,
            info: [
                {
                    audio: "audio230.mp3",
                    a:  "tell me about yourself.",
                    f: "parlez-moi de vous."
                },
                {
                    audio: "audio231.mp3",
                    a: "what interests you about this position?",
                    f: "qu'est-ce qui vous intéresse dans ce poste ?"
                },
                {
                    audio: "audio232.mp3",
                    a: "what do you know about our company?",
                    f: "que savez-vous sur notre entreprise ?"
                },
                {
                    audio: "audio233.mp3",
                    a: "why should we hire you?",
                    f: "pourquoi devrions-nous vous embaucher ?"
                },
                {
                    audio: "audio234.mp3",
                    a: "can you describe a challenging project you've worked on?",
                    f: "pouvez-vous décrire un projet difficile sur lequel vous avez travaillé ?"
                }
            ]
        },
        {
            id: 19,
            titre: "Les ressources humaines",
            niveau: 3,
            info: [
                {
                    audio: "audio240.mp3",
                    a:  "we're conducting interviews for the open position.",
                    f: "nous menons des entretiens pour le poste vacant."
                },
                {
                    audio: "audio241.mp3",
                    a: "please complete the new employee orientation.",
                    f: "veuillez suivre la formation d'intégration pour les nouveaux employés."
                },
                {
                    audio: "audio242.mp3",
                    a: "performance evaluations help us track progress.",
                    f: "les évaluations de performance nous aident à suivre les progrès."
                },
                {
                    audio: "audio243.mp3",
                    a: "we value teamwork and collaboration.",
                    f: "nous accordons de l'importance au travail d'équipe et à la collaboration."
                },
                {
                    audio: "audio244.mp3",
                    a: "training sessions are available for skill development.",
                    f: "des sessions de formation sont disponibles pour le développement des compétences."
                }
            ]
        }
       
       
       
    ]


export default dataEcoute;
