import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import {readJour} from '../commun/localStorage'
import DataAccess from '../../data/DataAccess';


export default class SousMenuJour extends Component {

    constructor(props) {
        super();
        let donnees = DataAccess.donneesSuivantType(props.nom)
        this.nom = props.nom;  
        this.state = {donnees}    
        this.jour = readJour();
   
    }
   
   
  
    render() {
     
       return (<div><Row gutter={[16,16]}> 
            
                {this.state.donnees.map((v, i) => <Col xs={4} md={3} key={i}><Link className={(i+1) > this.jour ? "disabledJour" : undefined} to={'/' + this.nom + '/' + v.id}><div className={(i+1) <= this.jour ? "cadreJour" : "cadreJour desactiveJour" }><div>{"Jour " + (i+1)}</div>
                {(i+1) === this.jour && <div><small>En Cours</small></div>}</div></Link></Col>)}
            
        </Row></div>)
       
    }
}





