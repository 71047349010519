function verifierStatus(status)
{
    if (status === 401)
    {
        alert("Désolé, vous devez être authentifié pour accèder à cette page.")
        window.location.href = "/";
        return false;
    }
    if (status === 403 || status === 404)
    {
        alert("Désolé, vous n'avez plus accès à cette page.")
        window.location.href = "/";
        return false;
    }
    return true;
}

function construireNiveauVerbesIrreguliers(victoire)
{
    if (victoire < 2)
    {
        return <span>Débutant</span>;
    } else if (victoire < 4)
    {
        return <span>Avancé</span>
    }
    else if (victoire < 6)
    {
        return <span>Confirmé</span>
    }
    else if (victoire < 8)
    {
        return <span>Expert</span>
    }
    else
    {
        return <span>Champion &#127942;</span>
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
    
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

export {verifierStatus, construireNiveauVerbesIrreguliers, shuffleArray};