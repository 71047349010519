let donneesConcours =
    [

        {
            id: 10,
            titre: "Jour 1",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10010, titre: "vocabulaire" },
             { type: 'test-qcm-anglais', id: 10011, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10012, titre: "phrases" }, 
            { type: 'test-conjugaison-anglais', id: 10013, titre: "conjugaison" }
           
            ]
        },
        {
            id: 20,
            titre: "Jour 2",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10020, titre: "vocabulaire" },
            { type: 'test-rapide-anglais', id: 10021, titre: "rapidité" },
            { type: 'test-oral-anglais', id: 10022, titre: "dictée" },  
            { type: 'test-discussion-anglais', id: 10023, titre: "phrases" },
           
            ]
        },
        {
            id: 30,
            titre: "Jour 3",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10030, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10031, titre: "phrases" },
            { type: 'test-mots-anglais', id: 10032, titre: "pyramide" },
            { type: 'test-mot-parle-anglais', id: 10033, titre: "mots" },
           
            ]
        },
        {
            id: 40,
            titre: "Jour 4",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10040, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10041, titre: "phrases" },
            { type: 'test-conjugaison-anglais', id: 10042, titre: "conjugaison" },
            { type: 'phrase-trou', id: 10043, titre: "trous" },
           
            ]
        },
        {
            id: 50,
            titre: "Jour 5",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10050, titre: "vocabulaire" },
            { type: 'test-rapide-anglais', id: 10051, titre: "rapidité" },
            { type: 'test-memoire-anglais', id: 10052, titre: "comprehension" },
            { type: 'test-discussion-anglais', id: 10053, titre: "phrases" },
           
            ]
        },
        {
            id: 60,
            titre: "Jour 6",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10060, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10061, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10062, titre: "phrases" }, 
            { type: 'test-conjugaison-anglais', id: 10063, titre: "conjugaison" }
           
            ]
        },
        {
            id: 70,
            titre: "Jour 7",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10070, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10071, titre: "phrases" },  
            { type: 'test-rapide-anglais', id: 10072, titre: "rapidité" },
            { type: 'test-mots-anglais', id: 10073, titre: "pyramide" },
           
            ]
        },
        {
            id: 80,
            titre: "Jour 8",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10080, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10081, titre: "phrases" },  
            { type: 'phrase-trou', id: 10082, titre: "trous" },
            { type: 'test-rapide-anglais', id: 10083, titre: "rapidité" },
           
            ]
        },
        {
            id: 90,
            titre: "Jour 9",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10090, titre: "vocabulaire" },
            { type: 'test-conjugaison-anglais', id: 10091, titre: "conjugaison" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10092, titre: "phrases" },  
            { type: 'test-mots-anglais', id: 10093, titre: "pyramide" },
           
            ]
        },
        {
            id: 100,
            titre: "Jour 10",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10100, titre: "vocabulaire" },
            { type: 'test-memoire-anglais', id: 10101, titre: "comprehension" },
            { type: 'test-discussion-anglais', id: 10102, titre: "phrases" }, 
            { type: 'test-oral-anglais', id: 10103, titre: "dictée" }, 
           
            ]
        },
        {
            id: 110,
            titre: "Jour 11",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10110, titre: "vocabulaire" },
            { type: 'test-conjugaison-anglais', id: 10111, titre: "conjugaison" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10112, titre: "phrases" },  
            { type: 'test-mots-anglais', id: 10113, titre: "pyramide" },           
            ]
        },
        {
            id: 120,
            titre: "Jour 12",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10120, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10121, titre: "qcm" }, 
            { type: 'test-memoire-anglais', id: 10122, titre: "comprehension" },
            { type: 'test-qcm-anglais', id: 10123, titre: "qcm" },           
            ]
        },
        {
            id: 130,
            titre: "Jour 13",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10130, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10131, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10132, titre: "phrases" },  
            { type: 'phrase-trou', id: 10133, titre: "trous" },      
            ]
        },
        {
            id: 140,
            titre: "Jour 14",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10140, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10141, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10142, titre: "phrases" },  
            { type: 'test-rapide-anglais', id: 10143, titre: "rapidité" },    
            ]
        },
        {
            id: 150,
            titre: "Jour 15",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10150, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10151, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10152, titre: "phrases" },  
            { type: 'test-mots-anglais', id: 10153, titre: "pyramide" },  
            ]
        },
        {
            id: 160,
            titre: "Jour 16",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10160, titre: "vocabulaire" },
            { type: 'test-oral-anglais', id: 10161, titre: "dictée" }, 
            { type: 'test-memoire-anglais', id: 10162, titre: "comprehension" },
            { type: 'test-mot-parle-anglais', id: 10163, titre: "mots" },
            ]
        },
        {
            id: 170,
            titre: "Jour 17",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10170, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10171, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10172, titre: "phrases" },  
            { type: 'test-conjugaison-anglais', id: 10173, titre: "conjugaison" },
            ]
        },
        {
            id: 180,
            titre: "Jour 18",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10180, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10181, titre: "qcm" }, 
            { type: 'test-oral-anglais', id: 10182, titre: "dictée" },  
            { type: 'test-qcm-anglais', id: 10183, titre: "qcm" }, 
            ]
        },
        {
            id: 190,
            titre: "Jour 19",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10190, titre: "vocabulaire" },
            { type: 'test-conjugaison-anglais', id: 10191, titre: "conjugaison" },
            { type: 'test-mots-anglais', id: 10192, titre: "pyramide" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10193, titre: "phrases" }, 
            ]
        },
        {
            id: 200,
            titre: "Jour 20",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10200, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10201, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10202, titre: "phrases" },   
            { type: 'test-qcm-anglais', id: 10203, titre: "qcm" }, 
            ]
        },
        {
            id: 210,
            titre: "Jour 21",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10210, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10211, titre: "qcm" }, 
            { type: 'test-oral-anglais', id: 10212, titre: "dictée" },   
            { type: 'test-rapide-anglais', id: 10213, titre: "rapidité" },
            ]
        },
        {
            id: 220,
            titre: "Jour 22",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10220, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10221, titre: "qcm" }, 
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10222, titre: "phrases" },   
            { type: 'test-qcm-anglais', id: 10223, titre: "qcm" }, 
            ]
        },
        {
            id: 230,
            titre: "Jour 23",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10230, titre: "vocabulaire" },
            { type: 'phrase-trou', id: 10231, titre: "trous" },
            { type: 'test-qcm-anglais', id: 10232, titre: "qcm" },  
            { type: 'test-mot-parle-anglais', id: 10233, titre: "mots" },
            ]
        },
        {
            id: 240,
            titre: "Jour 24",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10240, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10241, titre: "qcm" },  
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10242, titre: "phrases" }, 
            { type: 'test-oral-anglais', id: 10243, titre: "dictée" }, 
            ]
        },
        {
            id: 250,
            titre: "Jour 25",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10250, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10251, titre: "phrases" }, 
            { type: 'test-conjugaison-anglais', id: 10252, titre: "conjugaison" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10253, titre: "phrases" },  
            ]
        },
        {
            id: 260,
            titre: "Jour 26",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10260, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10261, titre: "phrases" }, 
            { type: 'test-qcm-anglais', id: 10262, titre: "qcm" },
            { type: 'test-mots-anglais', id: 10263, titre: "pyramide" },
            ]
        },
        {
            id: 270,
            titre: "Jour 27",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10270, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10271, titre: "phrases" }, 
            { type: 'test-qcm-anglais', id: 10272, titre: "qcm" },
            { type: 'test-oral-anglais', id: 10273, titre: "dictée" },
            ]
        },
        {
            id: 280,
            titre: "Jour 28",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10280, titre: "vocabulaire" },
            { type: 'test-qcm-anglais', id: 10281, titre: "qcm" },
            { type: 'test-rapide-anglais', id: 10282, titre: "rapidité" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10283, titre: "phrases" }, 
            ]
        },
        {
            id: 290,
            titre: "Jour 29",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10290, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10291, titre: "phrases" }, 
            { type: 'phrase-trou', id: 10292, titre: "trous" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10293, titre: "phrases" }, 
            ]
        },
        {
            id: 300,
            titre: "Jour 30",
            liste: [{ type: 'test-vocabulaire-anglais/vocabulaire/francais', id: 10300, titre: "vocabulaire" },
            { type: 'test-vocabulaire-anglais/phrase/francais', id: 10301, titre: "phrases" }, 
            { type: 'test-rapide-anglais', id: 10302, titre: "rapidité" },
            { type: 'test-discussion-anglais', id: 10303, titre: "phrases" },
            ]
        }

   
    ]
export default donneesConcours;