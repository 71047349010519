
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import SousMenuVoca from './SousMenuVoca.js';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import { Link } from 'react-router-dom';
import { readListErrors } from '../commun/localStorage';
import Liens from './Liens';
import HonneurGrammaire from '../commun/HonneurGrammaire.js';
import twitter from '../../images/twitter.png';

export default class GrammaireTest extends Component {

    constructor()
    {
        super();

    }
    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Vous n'avez pas encore de " + mot + " à revoir."}</div>

        }
        else {

            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '...' : mot}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces phrases</Link></div></div>

        }

    }

    reset = () => {
        localStorage.removeItem('test-qcm-anglais');
        document.location.reload();
    }
    render() {
        return <div>
                    <Helmet>
          <title>Exercices et tests de grammaire anglaise</title>
          <meta name="description" content="Des tests et des petits quiz amusants pour travailler la grammaire anglaise."/>
                 </Helmet>
         
            <h1 className="titre centre">Quiz de grammaire anglaise</h1>
   <div className="centre"><img src={border} alt="bordure"></img></div>
            <Row gutter={{ md: 16 }} className="margeHaut">

                <Col md={24}>
                    <h2>Quiz de grammaire anglaise</h2>
                    <p>Dans ces tests, vous devez choisir une proposition parmi plusieurs. Ces exercices reprennent toutes les bases de la grammaire anglaise. D'autres exercices seront ajoutés plus tard.</p>
                    <p>⚠️ L'anglais n'est pas une science exacte, quelquefois plusieurs réponses sont possibles mais nous avons choisi d'en valider une seule, seule qui nous semble la plus pertinente. Si vous n'êtes pas d'accord sur l'une de nos réponses, merci de nous contacter sur <a href="https://twitter.com/evalquiz"><img className="margeGauche" src={twitter} alt="twitter logo"></img> </a> pour en discuter.  </p>
           
                    <SousMenuVoca nom={env.nomQcm} titre="Grammaire anglaise"></SousMenuVoca>
                </Col>
            </Row>
            <p className="margeHaut20">Si vous souhaitez recommencer à 0,  vous pouvez cliquer sur le bouton Reset.</p>
            <div><Button type="primary" onClick={this.reset}>Reset</Button></div>
            <HonneurGrammaire></HonneurGrammaire>
      

            <div className="centre"><img src={border} alt="bordure"></img></div>
  <Liens></Liens>

<Ad></Ad>           
        </div>
    }
}