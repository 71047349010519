const env =
{
    nomConjugaison : 'test-conjugaison-anglais',
    nomEcoute: 'test-oral-anglais',
    nomListe: 'test-mots-anglais',
    nomMemoire : 'test-memoire-anglais',
    nomMotParle: 'test-mot-parle-anglais',
    nomOral: 'test-discussion-anglais',
    nomVocabulaire : 'test-vocabulaire-anglais',
    nomRapide: 'test-rapide-anglais',
    nomQcm: 'test-qcm-anglais',
    nomDefi : 'defi-anglais',
    nomPhraseTrou : 'phrase-trou',
    verbesIrreguliers: 'verbes-irreguliers',

    obtenirNomComplet(langue, type)
    {
        return this.nomVocabulaire + '/' + type + '/' + langue
      
    }

}

export default env;