const dataConjugaison =
 [
        {
            id: 1,
            titre: "Le présent",
            niveau: 2,
            info: [
                {
                    temps: "Present continuous",
                    sujet: "we",
                    verbe: "to become",
                    forme: "affirmative",
                    reponsec: "we're becoming",
                    reponse: "we are becoming"
                },
                {
                    temps: 'Present',
                    sujet: 'he',
                    verbe: 'to know',
                    forme: 'affirmative',
                    reponse: "he knows"
                },
                {
                    temps: "Present continuous",
                    sujet: "we",
                    verbe: "to dismiss",
                    forme: "negative",
                    reponse: "we aren't dismissing"
                },
                {
                    temps: 'Present',
                    sujet: 'they',
                    verbe: 'to play',
                    forme: 'interrogative',
                    reponse: "do they play?"
                }, {
                    temps: "Present",
                    sujet: "she",
                    verbe: "to sink",
                    forme: "interrogative",
                    reponse: "does she sink?"
                }

            ]
        },
        {
            id: 2,
            titre: "Le passé",
            niveau: 3,
            info: [
                {
                    temps: "Preterite",
                    sujet: "we",
                    verbe: "to cancel",
                    forme: "affirmative",
                    reponse: "we cancelled"
                },
                {
                    temps: 'Present perfect',
                    sujet: 'you',
                    verbe: 'to monitor',
                    forme: 'affirmative',
                    reponsec: "you've monitored",
                    reponse: "you have monitored"
                },
                {
                    temps: 'Preterite',
                    sujet: 'we',
                    verbe: 'to get',
                    forme: 'interrogative',
                    reponse: "did we get?"
                },
                {
                    temps: 'Present perfect',
                    sujet: 'he',
                    verbe: 'to take',
                    forme: 'interrogative',
                    reponse: "has he taken?"
                }, {
                    temps: "Preterite",
                    sujet: "we",
                    verbe: "to buy",
                    forme: "negative",
                    reponse: "we didn't buy"
                }

            ]
        },
        {
            id: 3,
            titre: "Le passé continu",
            niveau: 3,
            info: [
                {
                    temps: "Preterite continuous",
                    sujet: "they",
                    verbe: "to organize",
                    forme: "affirmative",
                    reponse: "they were organizing"
                },
                {
                    temps: 'Present perfect continuous',
                    sujet: 'you',
                    verbe: 'to jump',
                    forme: 'affirmative',
                    reponsec: "you've been jumping",
                    reponse: "you have been jumping"
                },
                {
                    temps: 'Preterite continuous',
                    sujet: 'he',
                    verbe: 'to chatter',
                    forme: 'affirmative',
                    reponse: "he was chattering"
                },
                {
                    temps: 'Present perfect continuous',
                    sujet: 'you',
                    verbe: 'to hide',
                    forme: 'negative',
                    reponse: "you haven't been hiding"
                }, {
                    temps: "Preterite continuous",
                    sujet: "he",
                    verbe: "to like",
                    forme: "interrogative",
                    reponse: "was he liking?"
                }

            ]
        },
        {
            id: 4,
            titre: "Le futur",
            niveau: 2,
            info: [
                {
                    temps: "Future",
                    sujet: "he",
                    verbe: "to break",
                    forme: "affirmative",
                    reponsec: "he'll break",
                    reponse : "he will break"
                },
                {
                    temps: 'Future continuous',
                    sujet: 'he',
                    verbe: 'to listen',
                    forme: 'affirmative',
                    reponsec: "he'll be listening",
                    reponse : "he will be listening"

                },
                {
                    temps: 'Future',
                    sujet: 'they',
                    verbe: 'to lend',
                    forme: 'affirmative',
                    reponsec: "they'll lend",
                    reponse: "they will lend"
                },
                {
                    temps: 'Future',
                    sujet: 'you',
                    verbe: 'to belong',
                    forme: 'interrogative',
                    reponse: "will you belong?"
                }, {
                    temps: "Future",
                    sujet: "he",
                    verbe: "to begin",
                    forme: "negative",
                    reponse: "he won't begin"
                },


            ]
        }
        ,
        {
            id: 5,
            titre: "Le conditionnel",
            niveau: 2,
            info: [
                {
                    temps: "Conditional present",
                    sujet: "you",
                    verbe: "to attempt",
                    forme: "affirmative",
                    reponsec: "you'd attempt",
                    reponse: "you would attempt"
                },
                {
                    temps: 'Conditional past',
                    sujet: 'he',
                    verbe: 'to live',
                    forme: 'affirmative',
                    reponsec: "he'd have lived",
                    reponse: "he would have lived"
                },
                {
                    temps: 'Conditional present',
                    sujet: 'you',
                    verbe: 'to dream',
                    forme: 'interrogative',
                    reponse: "would you dream?"
                },
                {
                    temps: 'Conditional present',
                    sujet: 'we',
                    verbe: 'to broadcast',
                    forme: 'interrogative',
                    reponse: "would we broadcast?"
                }, {
                    temps: "Conditional present",
                    sujet: "he",
                    verbe: "to hurt",
                    forme: "negative",
                    reponse: "he wouldn't hurt"
                }

            ]

        } ,
        {
            id: 6,
            titre: "L'impératif",
            niveau: 2,
            info: [
                {
                    temps: "Imperative",
                    sujet: "(you)",
                    verbe: "to work",
                    forme: "affirmative",
                    reponse: "work"
                },
                {
                    temps: 'Imperative',
                    sujet: '(we)',
                    verbe: 'to fix',
                    forme: 'affirmative',
                    reponse: "let's fix"
                },
                {
                    temps: 'Imperative',
                    sujet: '(you)',
                    verbe: 'to bite',
                    forme: 'negative',
                    reponse: "don't bite"
                },
                {
                    temps: 'Imperative',
                    sujet: '(we)',
                    verbe: 'to wax',
                    forme: 'negative',
                    reponse: "let's not wax"
                }, {
                    temps: "Imperative",
                    sujet: "(you)",
                    verbe: "to buy",
                    forme: "affirmative",
                    reponse: "buy"
                }

            ]
        },
        {
            id: 7,
            titre: "Le plus-que-parfait (past perfect)",
            niveau: 3,
            info: [
                {
                    temps: "Past perfect",
                    sujet: "he",
                    verbe: "to allot",
                    forme: "affirmative",
                    reponsec: "he'd allotted",
                    reponse: "he had allotted"
                },
                {
                    temps: 'Past perfect continuous',
                    sujet: 'we',
                    verbe: 'to shine',
                    forme: 'affirmative',
                    reponsec: "we'd been shining",
                    reponse: "we had been shining"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'you',
                    verbe: 'to cheat',
                    forme: 'interrogative',
                    reponse: "had you cheated?"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'you',
                    verbe: 'to enable',
                    forme: 'negative',
                    reponse: "you hadn't enabled"
                }, {
                    temps: "Past perfect",
                    sujet: "they",
                    verbe: "to mix",
                    forme: "interrogative",
                    reponse: "had they mixed?"
                }

            ]
        },
        {
            id: 8,
            titre: "Conjugaison verbes irréguliers 1",
            niveau: 3,
            info: [
                {
                    temps: "Preterite",
                    sujet: "they",
                    verbe: "to say",
                    forme: "affirmative",
                    reponse: "they said"
                },
                {
                    temps: 'Present Perfect',
                    sujet: 'you',
                    verbe: 'to hide',
                    forme: 'affirmative',
                    reponsec: "you've hidden",
                    reponse: "you have hidden"
                },
                {
                    temps: 'Present Perfect',
                    sujet: 'he',
                    verbe: 'to bleed',
                    forme: 'negative',
                    reponse: "he hasn't bled"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'I',
                    verbe: 'to grow',
                    forme: 'interrogative',
                    reponse: "had I grown?"
                }, {
                    temps: "Preterite",
                    sujet: "he",
                    verbe: "to mistake",
                    forme: "affirmative",
                    reponse: "he mistook"
                }

            ]
        },
        {
            id: 9,
            titre: "Conjugaison verbes irréguliers 2",
            niveau: 3,
            info: [
                {
                    temps: "Preterite",
                    sujet: "they",
                    verbe: "to leave",
                    forme: "affirmative",
                    reponse: "they left"
                },
                {
                    temps: 'Present Perfect',
                    sujet: 'he',
                    verbe: 'to fling',
                    forme: 'affirmative',
                    reponsec: "he's flung",
                    reponse: "he has flung"
                },
                {
                    temps: 'Present Perfect',
                    sujet: 'you',
                    verbe: 'to lead',
                    forme: 'negative',
                    reponse: "you haven't led"
                },
                {
                    temps: 'Past perfect',
                    sujet: 'he',
                    verbe: 'to pay',
                    forme: 'interrogative',
                    reponse: "had he paid?"
                }, {
                    temps: "Preterite",
                    sujet: "we",
                    verbe: "to eat",
                    forme: "negative",
                    reponse: "we didn't eat"
                }

            ]
        }
    ]



export default dataConjugaison;