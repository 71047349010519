import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Card } from 'antd';
import { readGames } from '../../components/commun/localStorage';
import DataAccess from '../../data/DataAccess';


export default class SousMenu extends Component {

    constructor(props) {
        super();
        let donnees = DataAccess.donneesSuivantType(props.nom)
        this.nom = props.nom;
        this.dejaFait = readGames(this.nom);    
        this.state = {donnees}    
   
    }
    ajouterTag(id)
    {
        if (this.dejaFait.length !== 0)
        {
            let jeu = this.dejaFait.find(x => x.no === id);
            if (jeu !== undefined) {
                let msg = "DÉJA FAIT";
                let color = 'orange';
                if (jeu.action === 'refaire')
                {
                    msg = "A REFAIRE";
                    color='cyan';
                }
                return <Tag color={color} className="tag">{msg}</Tag>;
            }
        }
    }
   
  
    render() {
     
       return (<div> <Card className={this.props.className}  title={<span style={{ whiteSpace: 'normal'}}>{this.props.titre}</span>}>
            
                {this.state.donnees.map((v, i) => <React.Fragment key={i}>{this.props.supprimer !== v.id && <div> <span><Link to={'/' + this.nom + '/' + v.id}>{v.titre}</Link>
                    {v.hasOwnProperty('niveau') && v.niveau !== undefined && <span className="petiteLettre">{" - " + DataAccess.transformeNiveau(v.niveau) + ' -'}</span>}
    <span className="margeGauche">{this.ajouterTag(v.id)}</span></span></div>}</React.Fragment>)}
            
        </Card></div>) 
       
    }
}

SousMenu.defaultProps = {
    supprimer: 'rien',
};



