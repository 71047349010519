
import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col} from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';

import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import Classement from '../commun/Classement.js';
import { Link } from 'react-router-dom';
import Liens from './Liens';

export default class EcouteTest extends Component {

  constructor()
  {
    super();

  }

    render() {
        return <div>
             <Helmet>
          <title>Tests d'écoute en anglais</title>
          <meta name="description" content="Des tests et des petits jeux pour améliorer sa capacité d'écoute et de compréhension orale de l'anglais."/>
                 </Helmet>
           
      <h1 className="titre centre">Comprendre l'anglais parlé</h1>
   


 <Row  gutter={{md:16}} className="margeHaut20">

<Col md={12}>
  <h2>Écouter des mots anglais</h2>
  <p>Vous devez comprendre ce qui est prononcé en anglais et l'écrire. Attention le temps est limité ! Vous n'avez pas le temps de réécouter plusieurs fois ce qui a été prononcé. Il faut respecter le formalisme pour les dates, les nombres et les heures.</p>

</Col>
<Col md={12}>
  <SousMenu nom={env.nomRapide}  titre="Comprendre les mots"></SousMenu>
</Col>
</Row>

<Row  gutter={{md:16}} className="margeHaut20">

<Col md={12}>
  <h2>Les textes à trous</h2>
  <p>Dans ce test vous écoutez un petit texte en anglais puis ensuite vous devez remplir les mots manquants dans le texte.</p>
 <p>La traduction n'est pas demandée. Un test idéal pour appréhender des phrases un peu plus longues.</p>

</Col>


<Col md={12}>
  <SousMenu nom={env.nomPhraseTrou}  titre="Compléter les mots manquants"></SousMenu>
</Col>
</Row>


      <Row  gutter={{md:16}} className="margeHaut20">

<Col md={12}>
  <h2>Anglais oral vers écrit</h2>
  <p>La fameuse dictée est de retour ! Dans ce test vous écoutez une phrase en anglais que vous devez simplement écrire en anglais.
  </p>

</Col>


<Col md={12}>
  <SousMenu nom={env.nomEcoute}  titre="Oral anglais et traduction"></SousMenu>
</Col>
</Row>
<div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
        </div>
    }
}