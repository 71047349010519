let dataPhraseTrou =
 [
        {
            id: 1,
            titre : "Davy Crockett",
        texte :   "Davy Crockett is a legendary personality for the United States of America. This is the first hero of @this country. Davy proved himself to be an excellent scout, @hunter and politician. Davy refused the opportunity to build a brilliant career in Congress and went to Texas. He became the @hero of the Alamo. The courage and strong @spirit of this amazing officer changed the history of the country. Davy Crockett @died , defending the fortress in Alamo.", 
        audio: "anglaisTrou1.mp3"   
    },
    {
        id: 2,
        titre : "Zorro",
    texte :   "Zorro is a popular superhero from Spanish California. He was a @foxy and clever revenger, who fought for justice. He wore a mask and a hat, was dressed in @black clothes, and was armed with a sword and a @whip . Zorro traveled by a black horse and wherever he was, he left a mark by three hits of the sword. Of course, his friends were honest poor natives, and his enemies were dishonest rich @men .",
    audio: "anglaisTrou2.mp3"   
},
{
    id: 3,
    titre : "20000 Leagues Under the Sea",
texte :   "20000 Leagues Under the Sea is a classic science fiction @novel by Jules Verne. It was in 1867 when first rumors about dangerous and horrible monster that lives somewhere in @sea depths appeared. According to witnesses, this creature was enormous and could sink any @ship . Many people claimed that it was just a very big @whale , but this beast was twice bigger than the biggest known whale.",
audio: "anglaisTrou3.mp3"   
},
{
    id: 4,
    titre : "FightMediocrity",
texte :   "FightMediocrity is a YouTube @channel that works with some of the best authors to create short, powerful @book summaries. The fact that summaries can be accessed in @audio format means you can truly learn quickly, anywhere at anytime. Summaries often include embedded videos, @pictures , and helpful notes from the author.",
audio: "anglaisTrou4.mp3"   
},
{
    id: 5,
    titre : "Exercising",
texte :   "He exercised every day. He @lifted weights. He ran on the @track . He rode his bicycle everywhere. It made him feel confident. People complimented his appearance. 'Look at those @muscles ' people said to him. He wasn't always in shape. He @used to be fat. He knew he had to change. He looked in the mirror one day. He promised himself to lose weight. He stopped eating @unhealthy food.",
audio: "anglaisTrou5.mp3"   
},
{
    id: 6,
    titre : "Pottery is For Girls",
texte :   "Sharon Carter wanted to take a pottery class. She didn't want to go @alone . She invited her husband to go with her. 'I don't want to do that!' said Mr. Carter. 'Pottery is for girls!' This made Sharon very @angry . She could not believe he said that. 'That's a sexist thing to say!' @yelled Sharon. She @argued with him for hours. Mr. Carter was tired of fighting. He agreed to go just to shut Sharon up. Their first class went very well. Mr. Carter was really good at making @pots . The instructors loved him. 'You are very talented!' they all said. Mr. Carter went to pottery class every week after that. He @loved it!",
audio: "anglaisTrou7.mp3"   
},




    
    ]

export default dataPhraseTrou;
