function addGame(name, value) {
    let tab = [];
    if (value != null && name != null) {
        const storage = localStorage.getItem(name);
        if (storage !== null) {
 
            tab = JSON.parse(storage);
            tab =  tab.filter(x => x.no !== value) 
        }
      
        tab.push({no: value, action: 'fait'});
        localStorage.setItem(name, JSON.stringify(tab));
    }
}

function replayGame(name, value) {
    if (value != null && name != null) {
        const storage = localStorage.getItem(name);
        if (storage !== null) {      
            let tab = JSON.parse(storage);
            let jeu = tab.find(x => x.no === value);
            if (jeu !== undefined) {
                jeu.action = 'refaire';
                localStorage.setItem(name, JSON.stringify(tab));
            }
        }
    }
}

function readGames(name) {
    if (localStorage.getItem(name) !== null) {
        return JSON.parse(localStorage.getItem(name));
    }
    return [];
}





function addFirstName(value)
{   
    localStorage.setItem("prenom",value);   
}

function readFirstName()
{
    const storage= localStorage.getItem('prenom');
    if (storage !== null)
    {
        return localStorage.getItem("prenom");
    }
    return null;
}


function addGameContest(contest, level)
{
    let tab = [];
    const attempt = 1;
    const storage = localStorage.getItem('contestAnglais');
    if (storage == null)
    {
        tab.push({contest, level, attempt});
    }
    else{
        tab = JSON.parse(storage);
        let newValue = tab.find(x => x.contest === contest);
        if (newValue === undefined)
        {
            tab.push({contest, level, attempt});
        }
        else
        {
            newValue.level = level;
            if (level === 0) newValue.attempt = 1;
        }
    }
    localStorage.setItem('contestAnglais',JSON.stringify(tab));
}

function addAttemptGameContest(contest)
{
    let tab = [];
    const storage = localStorage.getItem('contestAnglais');
    if (storage != null)
    {
        tab = JSON.parse(storage);
        let newValue = tab.find(x => x.contest === contest);
        if (newValue !== undefined)
        {
            newValue.attempt++; 
        }
        localStorage.setItem('contestAnglais',JSON.stringify(tab));
    }
}

function readGameContest(contest)
{
    const storage = localStorage.getItem('contestAnglais');
    if (storage !== null)
    {
        let tab = JSON.parse(storage);
        let tabContest = tab.find(x => x.contest === contest);
        if (tabContest !== undefined) return tabContest.level;

    }
    return -1;
}


function readAttemptContest(contest)
{
    const storage = localStorage.getItem('contestAnglais');
    if (storage !== null)
    {
        let tab = JSON.parse(storage);
        let tabContest = tab.find(x => x.contest === contest);
        if (tabContest !== undefined) return tabContest.attempt;

    }
    return -1;
}




function readInfoContest(contest)
{
    const storage = localStorage.getItem('contestAnglais');
    if (storage !== null)
    {
        let tab = JSON.parse(storage);
        let tabContest = tab.find(x => x.contest === contest);
        if (tabContest !== undefined) return tabContest;

    }
    return -1;
}


function readListErrors(name)
{
    const storage = localStorage.getItem(name + '/erreurs');
    if (storage !== null)
    {
        let object = JSON.parse(storage);
       return object;

    }
    return {info:[]};
}

function saveErrorList(name, nouveauxElements)
{
        localStorage.setItem(name + '/erreurs',JSON.stringify(nouveauxElements));   
}


function readJour()
{
    const storage= localStorage.getItem('jour');
    if (storage !== null)
    {
        return parseInt(localStorage.getItem("jour"));
    }
    else
    {
        saveJour(1);
        return 1;
    }
}


function saveJour(value)
{   
    localStorage.setItem("jour",value);   
}

function readVocaValide(type, langue)
{
    const storage = localStorage.getItem(type+langue);
    if (storage !== null)
    {
        let object = JSON.parse(storage);
       return object;

    }
    return [];
}

function addVocaValide(type,langue,id, etat)
{
    let tab = [];
    const storage = localStorage.getItem(type+langue);
    if (storage != null)
    {
        tab = JSON.parse(storage);
        let newValue = tab.find(x => x.id === id);
    
        if (newValue === undefined)
        {
            tab.push({id, etat});
        }
        else
        {
            newValue.etat = etat;
        }
        localStorage.setItem(type+langue,JSON.stringify(tab));
    }
    else{
        localStorage.setItem(type+langue,JSON.stringify([{id, etat}]));
    }
}



export {readVocaValide,addVocaValide,  addGame, readGames, replayGame, addFirstName, readFirstName, addGameContest, readGameContest, addAttemptGameContest, readAttemptContest, readInfoContest, readListErrors, saveErrorList, readJour, saveJour };

