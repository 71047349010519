let dataMotParle =
 [
        {
            id: 1,
            titre: "Mots simples à prononcer",
            niveau: 1,
            info: [
                {
                    audio: "motParle11.mp3",
                    texteA: "easy",
                    texteF: "facile"
                    
                },
                {
                    audio: "motParle12.mp3",
                    texteA: "gorgeous",
                    texteF: "magnifique, sublime"

                },
                {
                    audio: "motParle13.mp3",
                    texteA: "fancy",
                    texteF: "recherché, raffiné"
                },
                {
                    audio: "motParle14.mp3",
                    texteA: "average",
                    texteF: "moyenne"
                },
                {
                    audio: "motParle15.mp3",
                    texteA: "seldom",
                    texteF: "rarement"
                }
            ]
        },
        {
            id: 2,
            titre: "Mots difficiles à prononcer",
            niveau: 4,
            info: [
                {
                    audio: "motParle21.mp3",
                    texteA: "jewellery",
                    texteF: "bijoux"
                    
                },
                {
                    audio: "motParle22.mp3",
                    texteA: "genuine",
                    texteF: "véritable, authentique"

                },
                {
                    audio: "motParle24.mp3",
                    texteA: "handkerchief",
                    texteF: "mouchoir"
                },
                {
                    audio: "motParle25.mp3",
                    texteA: "towel",
                    texteF: "serviette"
                    
                }
            ]
        },
        {
            id: 3,
            titre: "Mots niveau intermédiaire",
            niveau: 4,
            info: [
                {
                    audio: "motParle23.mp3",
                    texteA: "height",
                    texteF: "hauteur"
                },
                {
                    audio: "motParle45.mp3",
                    texteA: "width",
                    texteF: "largeur"

                },
                {
                    audio: "motParle46.mp3",
                    texteA: "length",
                    texteF: "longueur"
                },
                {
                    audio: "motParle47.mp3",
                    texteA: "triangle",
                    texteF: "triangle"
                    
                },
                {
                    audio: "motParle48.mp3",
                    texteA: "square",
                    texteF: "carré"
                    
                }
            ]
        }
    ]


export default dataMotParle;